import InfoIcon from '@mui/icons-material/Info';
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AircraftADCompliancesCollection } from "../../keys";
import { CustomButtonFill } from "../../components/buttonfill";
import { Checkbox, FormControlLabel } from '@mui/material';
import { useLoadingOverlay } from '../../loading';
import { AddMxLog } from '../DashboardComponents/mxLogbook';
import AddAdIcon from '../../assets/icons/AddAdIcon.svg';

export const AdList = ({ aircraftId, tt, tachTime, tsmoh, limit, onCloseMx }: { aircraftId: string, tt: number, tsmoh: number, tachTime: number, limit?: number, onCloseMx: () => void }) => {
    const [ads, setAds] = useState<any[]>([])
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfimComplete, setShowConfimComplte] = useState(false);
    const [selected, setSelected] = useState<any[]>([]);
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllAds()
    }, [])

    const getAllAds = async () => {
        const db = getFirestore()
        const data: any[] = [];

        const q = query(collection(db, AircraftADCompliancesCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.data().status !== "Completed") {
                data.push({ ...doc.data(), id: doc.id });
            }
        });
        setAds(data);
    }

    return <>
        <div className="">
            <div className="flex mb-3">
                <span className="text-[14px] font-bold">AD Compliances</span>
                <div className="mt-[-3px] ml-3">
                    {/* <Tooltip title="Add AD" arrow> */}
                    <div className="w-[25px] cursor-pointer" onClick={() => { setShowAddModal(true) }} ><img src={AddAdIcon} /></div>
                    {/* </Tooltip> */}
                </div>
            </div>

            {selected.length > 0 && <div className="fixed mt-[100px] ml-[15vw] shadow-lg">
                <CustomButtonFill text={`C/W AD(s) - (${selected.length})`} onClick={() => { setShowConfimComplte(true) }} />
            </div>}

            {ads.map((value, i) => {
                return <div key={i} className="flex items-center border-2 border-orange-500 p-[2px] rounded-md mb-1">
                    <InfoIcon className="text-orange-500 mr-4" />
                    <p className="text-gray-700 text-[12px]">
                        <a href={value.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                            <b>{value.documentNumber}</b>
                        </a>
                    </p>
                    <p className="flex-1 text-gray-700 text-[12px] ml-2">{value.adfrawdSubject}</p>
                    <p className="text-gray-700 text-[12px] ml-2 mr-2"><b>{value.effectiveDate?.split("T")[0]}</b></p>
                    <div className="cursor-pointer ">
                        <FormControlLabel
                            control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                                if (e.target.checked) {
                                    await setSelected([...selected, value])
                                } else {
                                    await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                                }
                            }} name="fixSquawk" />}
                            label=""
                        />
                    </div>
                    {/* <div className="cursor-pointer" onClick={(e: any) => {
                        // setSelected(value)
                        // setShowConfimComplte(true)
                    }}>
                        <Tooltip title="Fix AD" arrow>
                            <BuildIcon className='p-1' />
                        </Tooltip>
                    </div> */}
                </div>
            })
            }
            {
                ads.length == 0 && <p className="text-[13px] text-[green]">No overdue AD</p>
            }

            {/* {
                showAddModal && <AddSquawk aircraftId={aircraftId} onClose={() => {
                    setShowAddModal(false)
                    getAllSquawks()
                }} />
            } */}

            {
                showConfimComplete && <AddMxLog onClose={() => {
                    onCloseMx()
                    setSelected([])
                    setShowConfimComplte(false)
                    getAllAds()
                }} aircraftId={aircraftId}
                    descriptionPreFill={selected.map(item => item.adfrawdSubject).join(", ") + "- C/W"}
                    squawk={selected} />
            }
        </div>
    </>
}
