import { Card, Modal } from "@mui/material"
import { SquawkList } from "./squawkList"
import CloseIcon from '@mui/icons-material/Close';

export const SquawksModal = ({ aircraftId, onClose }: { aircraftId: string, onClose: () => void }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
        
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[700px] ' sx={style}>
            <div className='flex  flex-row justify-between '>
                <h1 className="text-3xl mb-5">Add New SQUAWK</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <div className="max-h-[70vh] overflow-scroll p-2">
            <SquawkList
                aircraftId={aircraftId}
                onCloseMx={() => {
                }} setSquawkSeverity={() => {}} />
            </div>
            
                </Card></Modal>

}