export const UsersCollection: string = "Users";
export const AircraftCollection: string = "Aircrafts";
export const PilotLogbookCollection: string = "PilotLogbook";
export const PilotLogbookBase: string = "PilotLogbookBase";
export const MxLogbookCollection: string = "MxLogbookCollection";
export const MxLogbookTemplateCollection: string = "MxLogbookTemplateCollection";
export const CounterCollection: string = "Counter";
export const SquawkCollection: string = "Squawk";
export const AircraftADCompliancesCollection: string = "AircraftADCompliances";
export const ADCompliancesCollection: string = "ADCompliances";

export const userKey: string = 'user';
export const userIdKey: string = 'userId';
export const userNameKey: string = 'userName';
export const pilotShowHide: string = 'pilotShowHide';
export const aircraftShowHide: string = 'aircraftShowHide';