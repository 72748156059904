export const make = [
    "3-Ring Incorporated",
    "328 Support Services GmbH",
    "AC",
    "AC Spark Plug",
    "ACE Aeronautics LLC",
    "Acro Aeronautical Services Ltd",
    "ACS Products Company",
    "ADS Supply Company",
    "Aer-Pegaso S.p.A.",
    "Aermacchi S.p.A.",
    "Aero Advantage",
    "Aero Resources Inc.",
    "AERO Sp. z o.o.",
    "Aero Spacelines Incorporated",
    "Aero Spacelines, Incorporated",
    "Aero Union Corporation",
    "Aeroclubul Romaniei",
    "Aeromot-Industria Mecanico Metalurgica Ltda.",
    "Aeronaut Instruction Research",
    "Aeronautica Macchi S.p.A.",
    "Aeronautica Macchi S.p.A. & Aerfer-Industrie Aerospaziali Meridionali S.p.A.",
    "Aeronautical Accessories, Inc.",
    "Aerosonic Corporation",
    "Aerospace Lighting Corporation",
    "Aerospace Welding Minneapolis Inc",
    "Aerospatiale (Societe Nationale Industrielle Aerospatiale)",
    "Aerostar Aircraft Corporation",
    "Aerostar International",
    "Aerotechnic Vertiebs-u. Service GmbH ",
    "Aerotek II, Inc.",
    "Agusta S.p.A.",
    "Air & Space America, Inc.",
    "Air Associates",
    "Air Cruisers Company",
    "Air Space Design and Manufacturing LLC",
    "Air Spares International Inc",
    "Air Tractor Inc.",
    "Air Tractor, Inc.",
    "Air Trading Corporation",
    "Airborne Manufacturing, Inc.",
    "Airbus",
    "Airbus Canada Limited Partnership",
    "Airbus Defense and Space S.A.",
    "Airbus Helicopters",
    "Airbus Helicopters Deutschland GmbH",
    "Airbus Helicopters Deutschland GmbH (AHD)",
    "Airbus SAS",
    "Aircraft Belt & Trim Corporation",
    "Aircraft Belts, Inc.",
    "Aircraft Dusting & Spraying",
    "Aircraft Industries a.s.",
    "Aircraft Parts & Development Corp.",
    "Aircraft Radio Corporation",
    "Aircraft Tank Service, Inc.",
    "Airesearch",
    "Airlift International, Inc.",
    "Airotech",
    "Airtell International, Inc.",
    "Alexander Schleicher GmbH & Co.",
    "Alexander Schleicher GmbH & Co. Segelflugzeugbau",
    "Alexandria Aircraft LLC",
    "Alexandria Aircraft, LLC",
    "All Aircraft",
    "Allied Ag Cat Productions Inc.",
    "Allied Ag Cat Productions, Inc.",
    "Allied Signal Aerospace Company",
    "Allied Signal Commercial Avionics Systems",
    "AlliedSignal",
    "ALLIEDSIGNAL AVIONICS INC.",
    "Alliedsignal Avionics, Inc.",
    "AlliedSignal Inc",
    "Alliedsignal Inc",
    "Allliedsignal Aerospace",
    "Alpha Aviation Concept Limited",
    "Am-Safe, Inc.",
    "Ameri-King Corporation",
    "American Champion Aircraft Corp.",
    "American Navion Society, LTD.",
    "AMI Industries Inc.",
    "Andrea Radio Corporation",
    "Anjou Aeronautique",
    "APEX Aircraft",
    "Aquila - Aviation by Excellence AG",
    "Arrow Falcon Exporters Inc.",
    "Arrow Falcon Exporters, Inc.",
    "Artex Aircraft Supplies, Inc.",
    "ASI Aviation",
    "Aspen Avionics, Inc.",
    "Aspen Avionics,Inc.",
    "AST Inc.",
    "AST, Inc",
    "Atlantic Coast Seaplanes LLC",
    "ATR - GIE Avions de Transport R gional",
    "ATR - GIE Avions de Transport Régional",
    "ATR – GIE Avions de Transport Régional",
    "Attack Logistics LLC",
    "Augustair Inc.",
    "Augustair, Inc.",
    "Austro Engine GmbH",
    "Autair Ltd.",
    "Auto Crat Manufacturing Company",
    "AutoGyro Certification Limited",
    "Auxiliary Power International Corporation",
    "Avian Balloon",
    "Aviat Aircraft Inc",
    "Aviatech Inc.",
    "Aviation Communication & Surveillance Systems",
    "Avidyne Corporation",
    "Avidyne Corporation ",
    "Aviointeriors S.p.A",
    "Aviointeriors S.p.A.",
    "AVOX Systems",
    "AVOX Systems Inc.",
    "B-N Group Ltd.",
    "B/E Aerospace",
    "B/E Aerospace Fischer GmbH",
    "B/E Aerospace Fischer GmbH (B/E Aerospace Fischer)",
    "BAE Systems (Operations) Limited",
    "BAL NY KUB -EK spol. s r.o.",
    "Ballonbau Worner GmbH",
    "Ballonfabrik Augsburg",
    "Balloon Club of America",
    "Balony Kubicek spol s.r.o.",
    "Barry Aviation, LLC",
    "Batteries",
    "Becker Flugfunkwerk GmbH.",
    "Beech",
    "Beech Aircraft Corporation",
    "Beechcraft",
    "Bell Helicopter Textron",
    "Bell Helicopter Textron Canada Limited",
    "Bell Helicopter Textron Inc.",
    "Bell Helicopter Textron, A Division of Textron Canada",
    "Bell Helicopter Textron, Inc.",
    "Bell Textron Canada Limited",
    "Bell Textron Inc.",
    "Bell Textron, Inc.",
    "Bellanca Aircraft Corporation",
    "Bendix",
    "Bendix Avionics",
    "Bendix Electrical Components Division",
    "Bendix Energy Controls Division",
    "Bendix/King",
    "BendixKing/Honeywell International ",
    "BFGoodrich",
    "BHI H60 Helicopters, LLC",
    "Billings Flying Service Inc.",
    "Billings Flying Service, Inc",
    "Billings Flying Service, Inc.",
    "Blanik Aircraft CZ s.r.o.",
    "BLANIK LIMITED",
    "Boeing Airplane Company, Wichita Division",
    "Boeing Defense & Space Group",
    "Bombardier",
    "Bombardier Aerospace Inc",
    "Bombardier Inc.",
    "Bombardier-Rotax GmbH",
    "Brackett Aero Filters Inc.",
    "Bradley Rankin",
    "Brantly International Inc.",
    "Brantly International, Inc.",
    "Breeze Eastern Aerospace ",
    "Briggs & Stratton Corporation",
    "Britax Sell Gmbh & Co.",
    "British Aerospace (Commercial Aircraft) Ltd",
    "British Aerospace Regional Aircraft",
    "British Aerospace, Aircraft Group",
    "Britten-Norman Aircraft Ltd.",
    "Brown-Line Corporation",
    "BRP--POWERTRAIN GMBH & CO KG",
    "BRP-Rotax GmbH & Co KG",
    "Bruce Industries, Inc.",
    "Burkhart Grob Luft-Und",
    "C-O-Two",
    "California Department of Forestry",
    "Cameron Balloons Ltd (Thunder and Colt)",
    "Cameron Balloons Ltd.",
    "Cameron Balloons Ltd. (Sky Balloons)",
    "Cameron Balloons US",
    "Carson Helicopters, Inc.",
    "CEAPR",
    "Centerpointe Aerospace Inc.",
    "Centrair",
    "Central Air Service, Inc.",
    "Cessna Aircraft Company",
    "CFE Company",
    "CFM International S.A.",
    "CFM International, S.A.",
    "Chadwick, Inc.",
    "Champion Spark Plug Company",
    "Chromalloy",
    "Cirrus Design Corporation",
    "Cleveland Aircraft Products",
    "Collins",
    "Collins Radio",
    "Columbia Helicopters Inc.",
    "Columbia Helicopters, Inc.",
    "Commander Aircraft Corporation",
    "Commonwealth Aircraft, Inc.",
    "Consolidated Air Crane, Inc.",
    "Consolidated Vultee Aircraft Corporation, Stinson Division",
    "Construcciones Aeronauticas, S.A.",
    "Continental",
    "Continental Aerospace Technologies GmbH",
    "Continental Aerospace Technologies Inc.",
    "Continental Aerospace Technologies Inc. (Continental)",
    "Continental Aerospace Technologies, Inc.",
    "Continental Copters, Inc.",
    "Continental Motors",
    "Continental Motors Inc",
    "Costruzioni Aeronautiche Tecnam S.P.A.",
    "Costruzioni Aeronautiche Tecnam srl",
    "Cougar Aircraft Corporation",
    "Coulson Aviation (USA) Inc.",
    "Croman Corporation",
    "CTRM Aviation Sdn. Bhd.",
    "Cub Crafters Inc.",
    "Curtiss-Wright Corporation",
    "Curtiss-Wright/Marquette, Inc.",
    "D & R Nevada LLC",
    "Daher Aerospace",
    "DAHER AEROSPACE",
    "Daher Aircraft Design, LLC",
    "DaimlerChrysler Aerospace AG",
    "Dassault Aviation",
    "Davis Aircraft Products, Inc.",
    "Day, Gene C.",
    "de Havilland Aircraft Co., Ltd., The",
    "De Havilland Aircraft of Canada Limited",
    "Dee Howard Company, The",
    "DeHavilland Support Limited",
    "Delta Enterprise",
    "Detroit Diesel Allison",
    "Deutsche Aircraft GmbH",
    "DG Aviation GmbH",
    "DG Flugzeugbau GmbH",
    "Diamond Aircraft Industries GmbH",
    "Diamond Aircraft Industries Inc",
    "Discovery Aviation Inc.",
    "Don Luscombe Aviation History Foundation, Inc.",
    "Dornier Luftfahrt GmbH",
    "Dornier-Werke GmbH",
    "Douglas Aircraft Co., Inc.",
    "Dowty Propellers",
    "Dowty Rotol",
    "Dynac Aerospace Corporation",
    "E.I. DUPONT DE NEMOURS & COMPANY",
    "EADS CASA",
    "EADS-PZL Warszawa-Okecie S.A.",
    "Eagle Balloons Corp.",
    "Eclipse Aerospace, Inc.",
    "Edison",
    "Edison, Thomas A., Inc.",
    "EDO Corporation",
    "Eiriavion Oy",
    "Eisemann",
    "Embraer - Empresa Brasileira de Aeronautica S.A.",
    "Embraer S.A.",
    "Emerald Enterprises Ltd.",
    "Engine Alliance",
    "Engine Components, Inc.",
    "Enstrom Helicopter Corporation, The",
    "Entwicklungsgemeinschaft Sport- und Segelflug",
    "EON Corporation",
    "Epic Aircraft LLC",
    "Erickson 214 Holdings LLC",
    "Erickson Air-Crane",
    "Erickson Air-Crane Incorporated DBA Erickson Air-Crane",
    "Erickson Incorporated DBA Erickson Air-Crane",
    "Essex PB&R Corporation",
    "Eureka Williams Company",
    "Eurocopter Deutschland GmbH",
    "Eurocopter France",
    "EVEKTOR, spol. s r.o.",
    "Evergreen Air Center",
    "Extra Flugzeugproduktions- und Vertriebs- GmbH",
    "Facet Aerospace",
    "Fairchild Camera & Instrument Corporation",
    "Fairchild Engine Division, Fairchild Engine and Airplane Corp.",
    "Fairchild Hiller Corporation",
    "Fairchild Industries Products",
    "Fairchild Industries, Inc.",
    "Fairchild Personal Planes Division, Fairchild Engine and Airplane Corp.",
    "Fantasy Sky Promotions Inc.",
    "Federal Skis",
    "Federico Helicopters, Inc.",
    "FH1100 Manufacturing Corporation",
    "Fiberglas-Technik Rudolf Lindner GmbH & Co. KG",
    "Filotechnica Salmoiraghi",
    "Fire Fighting Enterprises Limited",
    "Firestone",
    "First Technology Fire and Safety Ltd",
    "Flight Equipment and Engineering Corporation",
    "Flight Trails Helicopter",
    "FLS Aerospace (Lovaux) Ltd.",
    "Flug- und Fahrzeugwerke Ag",
    "Flugzeugwerke Altenrheim AG (FFA)",
    "Flying Tiger Line, Inc.",
    "Fokker Services",
    "Fokker Services B.V.",
    "Found Aircraft Canada, Inc.",
    "Found Brothers Aviation Limited",
    "Frakes Aviation",
    "Franklin Engine Company",
    "Fred Garcia",
    "Freedman Aircraft Engineering Corp.",
    "FreeFlight Systems",
    "Frost Engineering",
    "FS 2000 Corp.",
    "FS 2001 Corp.",
    "FS 2002 Corp.",
    "FS 2003 Corp.",
    "Fuji Heavy Industries Ltd.",
    "Fuji Heavy Industries, Ltd.",
    "Funk Aircraft Company",
    "GA 8 Airvan (Pty) Ltd",
    "GA8 Airvan (Pty) Ltd",
    "GA200 (Pty) Ltd",
    "Garmin",
    "Garmin AT",
    "GARMIN International",
    "Garmin International",
    "GARMIN International Inc.",
    "Garrett",
    "Garrett Airresearch",
    "Garrett Auxiliary Power Division",
    "Garrett Turbine Engine Company",
    "Garwin, Inc.",
    "GE Aviation Czech s.r.o.",
    "GE Honda Aero Engines",
    "General Atomics AeroTec Systems GmbH",
    "General Avia Costruzioni Aeronautiche",
    "General Electric Aircraft Engines",
    "General Electric Company",
    "General Machine - Diecron, Inc.",
    "Gerdes Products Company",
    "Glacier Helicopter, Inc.",
    "Gladden Products Corp.",
    "Glaser-Dirks Flugzeugbau GmbH",
    "Glasflugel",
    "Global Helicopter Technology Inc.",
    "Global Helicopter Technology, Inc.",
    "Godfrey",
    "Gomolzig Flugzeug- und Maschinenbau GmbH",
    "Goodrich",
    "Goodyear",
    "Goodyear Aircraft Corporation",
    "Goodyear Aviation Tires",
    "Goodyear Tire and Rubber Company",
    "Gores, William",
    "GQ Parachutes Ltd.",
    "GQ Security Parachutes",
    "Graviner",
    "Great Lakes Aircraft Company, LLC (John Duncan)",
    "GROB Aircraft AG",
    "GROB Aircraft SE",
    "Grob-Werke",
    "GROB-WERKE GMBH & CO KG",
    "Grumman American Aviation Corporation",
    "Gulfstream Aerospace Corporation",
    "Gulfstream Aerospace LP",
    "Gulfstream American Corporation",
    "HAFEI Aviation Industry Co. LTD (HAIC)",
    "Hagglund Helicopters LLC",
    "Hagglund Helicopters, LLC",
    "Hamburger Flugzeugbau G.m.b.H.",
    "Hamill Manufacturing Company",
    "Hamilton Standard",
    "Hamilton Standard Division",
    "Hamilton Sundstrand",
    "Hamilton Sundstrand Corporation",
    "Hamilton Sundstrand Power Systems",
    "Hartzell Engine Technologies",
    "Hartzell Propeller, Inc.",
    "Hawker Beechcraft Corporation",
    "Hawker Textron Aviation Inc.",
    "Hawkins & Powers Aviation Inc.",
    "Hawkins & Powers Aviation, Inc.",
    "HB Aircraft Industries AG",
    "Head Balloons, Inc.",
    "Heath Tecna",
    "Heavy Lift Helicopters Inc",
    "Heavylift Helicopters Inc.",
    "Heli-Crane Corporation",
    "Helicopter Transport Services, Inc.",
    "Helicopteres Guimbal",
    "Helio Aircraft Corporation",
    "Helio Aircraft LLC",
    "Helio Alaska Inc.",
    "Herb M. Graves",
    "High Performance Helicopters Corp.",
    "Hiller Aviation",
    "HOAC-Austria",
    "Hoffmann GmbH & Co. KG",
    "Holmes, Douglas W.",
    "Honda Aircraft Company LLC",
    "Honeywell ASCa Inc",
    "Honeywell International Inc.",
    "Honeywell, Inc.",
    "Hot Air Balloons",
    "HPH s.r.o.",
    "HTL Advanced Technology",
    "Hélicoptères Guimbal",
    "Inc.",
    "Indiana Mills & Manufacturing",
    "Induction Air Filters",
    "Industrie Aeronautiche e Meccaniche",
    "Iniziative Industriali Italiane S.p.A.",
    "Interceptor Aircraft Corporation",
    "Interceptor Aircraft Inc",
    "International Aero Engines AG (IAE)",
    "International Aero Engines LLC",
    "International Air Response",
    "International Air Response (IAR)",
    "International Helicopters Inc.",
    "International Helicopters, Inc.",
    "International Inflatables Company",
    "Intertechnique",
    "Intreprinderea De Constructii Aeronautice",
    "Invest In Opportunities, Inc.",
    "IPECO",
    "Ipeco Holdings Limited",
    "Isenberg, Wilbur M.",
    "Israel Aircraft Industries Ltd.",
    "Israel Aircraft Industries, Ltd.",
    "Jet Electronics Technology, Inc.",
    "JGS Properties LLC",
    "JGS Properties, LLC",
    "JJASPP Engineering Services LLC",
    "JR Aerosports Ltd.",
    "JR Aerosports, LTD",
    "K & L Soaring LLC",
    "Kaiser Fleetwings, Inc.",
    "Kaman Aerospace Corporation",
    "Kawasaki Heavy Industries, Limited",
    "Kearns, Edward Scott",
    "Kelly Aerospace Energy Systems, LLC",
    "Kelly Aerospace Power Systems",
    "Kelowna Flightcraft R & D Ltd.",
    "Kidde Aerospace",
    "Kidde Aerospace & Defense",
    "Kidde Graviner",
    "King Radio Corporation",
    "Koito Industries Ltd",
    "Kollsman",
    "KWAD Company",
    "L Hotellier",
    "L'Hotellier, Louis",
    "L. B. Smith Aircraft Corporation",
    "Lavia Argentina S.A. (Laviasa)",
    "Lear, Inc.",
    "Learjet Inc.",
    "Legend Aviation & Marine LLC",
    "Leigh Systems",
    "Leonardo S.p.a.",
    "LeSEA",
    "Lester Branchflower",
    "Liberty Aerospace Incorporated",
    "Lifesavings Systems Corp",
    "Lindstrand Balloons Ltd.",
    "Lindstrand Balloons USA Inc",
    "Lindstrand Hot Air Balloons Ltd",
    "Linstrandt",
    "LITEF GmbH",
    "Litton Systems",
    "LLC",
    "Lockheed Aircraft Corporation",
    "Lockheed Aircraft Service Company",
    "Lockheed Martin Aeronautics Company",
    "Lockheed Martin Corporation",
    "Lockheed-Georgia Company",
    "Lockheed-Georgia Corporation",
    "Lucas Air Equipment",
    "Luscombe Aircraft Corporation",
    "Lycoming Engines",
    "M7 Aerospace LLC",
    "Magnaghi Aeronautica S.p.A.",
    "Mareco",
    "Marrs Joe G.",
    "MarS A.S.",
    "Martin-Marietta Corporation",
    "Marvel Schebler",
    "Marvel-Schebler Products Division",
    "Maryland Air Industries, Inc.",
    "Maule Aerospace Technology Inc.",
    "Maule Aerospace Technology, Inc.",
    "McCauley",
    "McCauley Propeller Systems",
    "McClish, Thomas H.",
    "McCreary, Gifton",
    "McDonnell Douglas Corporation",
    "McGrath Michael D.",
    "McGrath, Michael D.",
    "MD Helicopters Inc.",
    "MD Helicopters, Inc.",
    "Mechanical Products",
    "Meggitt (Troy),Inc",
    "Meggitt Safety Systems Inc",
    "Menasco Manufacturing Co.",
    "Merlyn Products Inc",
    "MHI RJ Aviation ULC",
    "MICCO Aircraft Company",
    "Michelin Aircraft Tire Corporation",
    "Microdot",
    "Microturbo",
    "Microturbo SA",
    "Microturbo Saphir",
    "Midwest Aerospace TC LLC",
    "Minden Air Corp",
    "Mitchell Industries, Inc.",
    "Mitsubishi Heavy Industries Ltd.",
    "Mitsubishi Heavy Industries, Ltd.",
    "Mooney Aircraft Corporation",
    "Mooney Aviation Company Inc",
    "Mooney International Corporation",
    "Mooney Mite Aircraft Corporation",
    "MT-Propeller Entwicklung GmbH",
    "Narco",
    "Nardi S.A.",
    "National Aeronca Association",
    "National Ballooning",
    "Naval Aircraft Factory",
    "NavWorx,Inc",
    "Neptune Aviation Service, Inc.",
    "Nomad TC Pty Ltd",
    "Noorduyn Aviation Ltd.",
    "Nordskog Industries, Inc.",
    "Northrop Grumman LITEF GmbH LCR-100",
    "Northwest Rotorcraft LLC",
    "Northwest Rotorcraft, LLC",
    "NZSkydive Limited",
    "O2 Corporation",
    "OAS Parts LLC",
    "Odom, William P.",
    "Omega Aircraft Corporation",
    "Ontic Engineering and Manufacturing, Inc.",
    "Orlando Helicopter Airways, Inc.",
    "Overland Aviation",
    "Overseas Aircraft Support  Inc.",
    "Overseas Aircraft Support Inc",
    "Overseas Aircraft Support Inc.",
    "P. V. Shields",
    "Pacific Aerospace Limited",
    "Pacific Aerospace Ltd",
    "Pacific Aviation, Inc.",
    "Pacific Inflatables Company",
    "Pacific International Foods Inc.",
    "Pacific Propeller, Inc.",
    "Pacific Scientific Company",
    "Pan Avion Company",
    "Para-Flite, Inc.",
    "Parker Hannifin Corporation",
    "Pathfinder Corporation",
    "Piaggio & C.",
    "Piaggio Aero Industries S.p.A.",
    "Piaggio Aviation S.p.A.",
    "Pickering Aviation, Inc.",
    "Pilatus Aircraft Limited",
    "Pilatus Aircraft Ltd.",
    "Pioneer Parachute Company",
    "Piper Aircraft Inc.",
    "PJ Helicopters Inc",
    "Polskie Zaklady Lotnieze Spolka zo.o",
    "Porsche K.-G., Dr. Ing.h.c.F.",
    "Pratt & Whitney",
    "Pratt & Whitney Canada",
    "Pratt & Whitney Canada Corp.",
    "Pratt & Whitney Canada, Inc.",
    "Pratt & Whitney Division",
    "Pratt, Read & Co., Inc.",
    "Precise Flight Inc.",
    "Precision Airmotive Corporation",
    "Pressed Steel Tank Company",
    "Prestolite Company",
    "Przedsiebiorstwo Doswiadczalno-Produkcyjne",
    "PTC Aerospace",
    "PTC Seating Products",
    "Pure Air, Inc.",
    "Puritan-Bennett Aero Systems",
    "Pyrotector, Inc.",
    "PZL Mielec",
    "PZL-Swidnik S.A.",
    "Quartz Mountain Aerospace, Inc.",
    "Quest Aircraft Design, LLC",
    "R. Hunt Propeller",
    "Rajay",
    "Rajay/Rotomaster",
    "Rankin, Joe",
    "Ratier-Forest, Division de Figeac",
    "Razorback Fabrics, Inc.",
    "RECARO Aircraft Seating GmbH & Co. KG",
    "RECARO Aircraft Seats GmbH & Co.",
    "Red Tail Flying Services LLC",
    "Regal Air, Inc.",
    "Reims Aviation S.A.",
    "REVO Incorporated",
    "Revo Incorporated",
    "Revo, Incorporated",
    "Reynolds Aviation",
    "Richards Heavylift Helo Inc.",
    "Richards Heavylift Helo, Inc.",
    "Rigging Innovations, Inc.",
    "Risley Lester W.",
    "Robertson Cliff c/o Gadbois Business Management",
    "Robinson Air Crane Inc.",
    "Robinson Air Crane, Inc.",
    "Robinson Helicopter Company",
    "Rockwell Collins",
    "Rockwell Collins Inc.",
    "Rockwell Collins, Inc.",
    "Rockwell International",
    "Rogers, Burl A.",
    "Rolladen-Schneider Flugzeugbau GmbH",
    "Rolls-Royce Corporation",
    "Rolls-Royce Deutschland Ltd & Co KG",
    "Rolls-Royce Limited",
    "Rolls-Royce Limited, Bristol Engines Division",
    "Rolls-Royce plc",
    "Rosemount Aerospace, Inc.",
    "Rosemount, Inc.",
    "Rotorcraft Development Corporation",
    "Rowland Aviation Services LLC",
    "Royal Inventum Company",
    "RUAG Aerospace Services GmbH",
    "Rupert Parachute Company",
    "Russell Manufacturing Company",
    "Rust Robert E.",
    "Rust, Robert E.",
    "Ryan Aeronautical Company",
    "S.E.L.A. Laboratoire Abadie",
    "S.M. & T. Aircraft",
    "Saab AB",
    "Saab AB Support and Services",
    "Saab AB, Saab Aeronautics",
    "Saab AB, Saab Aerosystems",
    "Saab AB, Support and Services",
    "Sabreliner Corporation",
    "Safran Aerosystems",
    "Safran Helicopter Engines S.A.",
    "San Joaquin Helicopters",
    "Sandia Attitude Indicator",
    "Sargent Industries, Pico Division",
    "SCHEIBE-AIRCRAFT-GMBH",
    "Scheibe-Flugzeugbau GmbH",
    "Schempp-Hirth Flugzeugbau",
    "Schempp-Hirth Flugzeugbau GmbH",
    "Schweizer Aircraft Corporation",
    "Schweizer RSG LLC",
    "Schwien Engineering Corporation",
    "Scott Aviation",
    "Scotts-Bell 47 Inc",
    "Seabird Aviation Australia Pty. Ltd.",
    "Secur Aiglon",
    "Sensenich Propeller Manufacturing Company, Inc.",
    "Shakespeare Products Company",
    "Short Brothers & Harland Ltd.",
    "Short Brothers PLC",
    "Siam Hiller Holdings Inc.",
    "Siam Hiller Holdings, Inc.",
    "SICLI",
    "SICMA Aero Seat",
    "Sicma Aero Seat",
    "Siemens S.A.S",
    "Sierra Engineering Company",
    "Sierra Hotel Aero Inc.",
    "Sierra Hotel Aero, Inc.",
    "Sikorsky Aircraft",
    "Sikorsky Aircraft Corporation",
    "Siller Helicopters",
    "SIXTYHAWK TC, LLC",
    "Sky Balloons Ltd.",
    "Sky Enterprises Inc.",
    "Sky Enterprises, Inc.",
    "SKYDANCE BLACKHAWK OPERATIONS LLC",
    "Skypower",
    "Skyship Services",
    "Skyways International Trading and Transport Co.",
    "Slick Electro, Inc.",
    "Slingsby Aviation Ltd.",
    "Slingsby Sailplanes Ltd.",
    "SME Aero, Inc.",
    "Smith Helicopters",
    "Snow Aviation International Inc.",
    "Snow Aviation International, Inc.",
    "SOCATA",
    "SOCATA - Groupe Aerospatiale",
    "Societe C.A.R.M.A.M.",
    "Societe de Motorisation Aeronautiques",
    "Soloy Conversions, Ltd.",
    "Southern Helicopter Inc.",
    "Southern Helicopter, Inc.",
    "Southwest Florida Aviation International",
    "Spartan Aircraft Company",
    "Spencer Aircraft Industries",
    "Sperry Corporation",
    "Sperry Flight Systems",
    "Spinks Industries M. H. Spinks Sr.",
    "Sprague Engineering",
    "SST FLUGTECHNIK GmbH",
    "Starbird Inc.",
    "Stemme AG",
    "Stemme GmbH & Co. KG",
    "Steward-Davis Incorporated",
    "STOL Aviation LLC",
    "Stolp-Adams Company",
    "Strong Enterprises",
    "Sud Aviation",
    "Sundance Balloons (US)",
    "Sundstrand",
    "Sundstrand Aerospace",
    "Superior Air Parts Inc.",
    "Superior Air Parts, Inc.",
    "Superior Air Parts,Inc.",
    "Superior Aircraft Company",
    "Superior Flow Company",
    "Surplus",
    "Survitec Group Limited",
    "Swift Museum Foundation Inc.",
    "Swift Museum Foundation, Inc.",
    "Switlik Parachute Company",
    "Symphony Aircraft Industries Inc",
    "Szybowcowy Zaklad Doswiadczalny",
    "T.B.M. Inc.",
    "T.B.M., Inc.",
    "Tamarack Helicopters Inc.",
    "Tamarack Helicopters, Inc.",
    "Tandem Rotor LLC",
    "Tandem Rotor, LLC",
    "Tarver Propellers, LLC",
    "Taylorcraft 2000 LLC",
    "Taylorcraft 2000, LLC",
    "Taylorcraft, Inc.",
    "Technify Motors GmbH",
    "TECO, Inc.",
    "Teledyne Continental Motors",
    "Tempo Design Corporation",
    "Terra Corporation",
    "Teryjon Aviation, Inc.",
    "Texas Instruments",
    "Textron Aviation Inc.",
    "Textron Lycoming",
    "Textron Lycoming AVCO Corporation",
    "Textron Lycoming Subsidiary of Textron Inc.",
    "Textron Lycoming, AVCO Corporation",
    "Thales Avionics (Sextant Avionique)",
    "Thales AVS France SAS",
    "Thales AVS France SAS (Thales)",
    "The Boeing Company",
    "The de Havilland Aircraft Co. Ltd.",
    "The Enstrom Helicopter Corporation",
    "The King's Engineering Fellowship",
    "Thielert Aircraft Engines GmbH",
    "Thompson Aircraft Sales",
    "Thompson Products, Inc.",
    "Thrush Aircraft LLC.",
    "Thrush Aircraft, Inc.",
    "Timberline Helicopters, Inc.",
    "Timm Aircraft Corporation",
    "Titeflex Corporation",
    "Topcub Aircraft Inc.",
    "Tost",
    "Tracor Aerospace, Inc.",
    "Transaire Spraying Co.",
    "Transcontinental and Western Air Inc.",
    "Transcontinental and Western Air, Inc.",
    "Transport Category Airplanes",
    "Trig Avionics Limited",
    "Trimble",
    "Triton Aerospace LLC",
    "True Flight Holdings LLC",
    "TRW Hartzell Propeller",
    "Turbomeca S.A.",
    "Twin Commander Aircraft LLC",
    "Ultramagic, S.A.",
    "Umlaut Engineering GmbH",
    "Unical Air Inc.",
    "Unical Aviation Inc.",
    "Unical Aviation, Inc.",
    "Uninsured Relative Workshop",
    "Uninsured United Parachute Technologies",
    "United Aeronautical Corporation",
    "United Aircraft of Canada",
    "United Data Control, Inc.",
    "United Instruments, Inc.",
    "Univair Aircraft Corporation",
    "Universal Oil Products, Inc.",
    "UPS Aviation Technologies, Inc.",
    "Ursula Hanle",
    "US Helicopter Inc.",
    "US Helicopter, Inc.",
    "USDA Forest Service",
    "Valentin GmbH & Co.",
    "VFW-Vereinigte Flugtechnische Werke, G.m.b.H.",
    "Vickers-Armstrongs (Aircraft Limited)",
    "Vickers-Slingsby",
    "Viking Air Limited",
    "Volpar, Inc.",
    "Vulcanair S.p.A.",
    "W.Z.D. Enterprises Inc.",
    "Waara William",
    "Waco Aircraft Company, The",
    "WACO Classic Aircraft Corporation",
    "Walter Kidde",
    "Warner Engine Company, LLC",
    "Weatherly Aircraft Company",
    "Weber Aircraft",
    "West Coast Fabrications",
    "Western International Aviation Inc.",
    "Western International Aviation, Inc.",
    "Westland Helicopters Limited",
    "Weston-Garwin Carruth",
    "Whelen Engineering Company, Inc.",
    "Williams Harry E.",
    "Williams International Company L.L.C.",
    "Williams International Company, L.L.C.",
    "Wood Electric",
    "Woodward Governor Company",
    "Wright Aeronautical Division, Curtiss-Wright Corporation",
    "Wright Kevin",
    "WSH LLC",
    "WSK PZL Mielec and OBR SK Mielec",
    "Wytwornia Sprzetu Komunikacyjnego",
    "XtremeAir GmbH",
    "Yaborã Indústria Aeronáutica S.A.",
    "Zaklad Szybowcowy Jezow",
    "Zenair Ltd.",
    "Zlin Aircraft a.s.",
    "ZLT Zeppelin Luftschifftechnik GmbH & Co KG",
    "Zodiac Aero Evacuation Systems",
    "Zodiac Aerotechnics",
    "Zodiac Seats California LLC",
    "Zodiac Seats France"
]

export const model = [
    "(c) R. 352/6",
    "(c) R. 381",
    "(c) R. 389",
    "(c) R. 390",
    "(c) R.209/4-40-4.5",
    "(c) R.245/4-40-4.5",
    "(c) R.259/4-40-4.5",
    "(c) R.284/3-20-3/",
    "(c) R.321",
    "(c) R.324",
    "(c) R.333",
    "(c) R.334",
    "(c) R.354",
    "(c) R.375",
    "(c) R130/4-20-4/",
    "(c) R141/4-20-4/",
    "(c) R147/4-20-4/",
    "(c) R148/4-20-4/",
    "(c) R175/4-30-4/",
    "(c) R179/4-20-4/",
    "(c) R184/4-30-4/",
    "(c) R186/4-30-4/",
    "(c) R187/4-30",
    "(c) R193/4-30-4/",
    "(c) R201/4-30-4/",
    "(c) R212/4-30-4/",
    "(c) R251/4-30-4/",
    "(c) R257/4-30-4/",
    "(c) R410/6",
    "(c)R408/6-123-F/17",
    "(Navy) HOS-1",
    "(RECARO) Model 3510A and 3510D passenger seats",
    "-Models not listed here can be found under Textron Aviation Inc.",
    "1A90",
    "1A100",
    "1A101",
    "1A101/HCM",
    "1A102",
    "1A102/OCM",
    "1A103/TCM",
    "1A105",
    "1A106",
    "1A110-GF",
    "1A110-GM",
    "1A135",
    "1A162",
    "1A170",
    "1A175",
    "1A180/SEM",
    "1A200",
    "1A230/AFC",
    "1B90",
    "1B175",
    "1B200",
    "1B235/BFA",
    "1B235/DFC",
    "1B235/EFC",
    "1C90",
    "1C160/CTM",
    "1C160/DTM",
    "1C160/EGM",
    "1C160/FGM",
    "1C160/VCM",
    "1C172/AGM",
    "1C172/BTM",
    "1C172/EM",
    "1C172/MDM",
    "1C172/MFA",
    "1C172/MGM",
    "1C172/MTM",
    "1C172/SBTM",
    "1C172/TM",
    "1C200",
    "1C235/LFA",
    "1D200",
    "1P235/AFA",
    "1P235/PFA",
    "2A4-45-B2",
    "2A4-49-B2",
    "2A31C21",
    "2A34C",
    "2A36C1",
    "2A36C18",
    "2A36C23",
    "2A36C29",
    "2A36C43",
    "2A36C82",
    "2A37C",
    "2AF31C10",
    "2AF34C55",
    "2AF36C26",
    "2AF36C38",
    "2AF36C39",
    "2AF36C68",
    "2AF36C89",
    "2B36C7",
    "2C36C9",
    "2D34C",
    "2D34CT69",
    "2D36C14",
    "2D36C28",
    "2DF36C30",
    "2DF36C35",
    "2E34C57",
    "2T-1A",
    "2T-1A-1",
    "2T-1A-2",
    "3A32C76",
    "3A32C406",
    "3A32C418",
    "3A34C422",
    "3A34C423",
    "3A36C434",
    "3AF32C72",
    "3AF32C75",
    "3AF32C87",
    "3AF32C93",
    "3AF32C504",
    "3AF32C505",
    "3AF32C506",
    "3AF32C507",
    "3AF32C508",
    "3AF32C509",
    "3AF32C511",
    "3AF32C512",
    "3AF32C515",
    "3AF32C521",
    "3AF32C522",
    "3AF32C523",
    "3AF32C524",
    "3AF32C528",
    "3AF34C74",
    "3AF34C86",
    "3AF34C92",
    "3AF34C502",
    "3AF34C503",
    "3AF36C514",
    "3AF36C525",
    "3AF37C510",
    "3AF37C516",
    "3FF32C501",
    "3FF34C529",
    "3GFR34C601",
    "3GFR34C602",
    "3GFR34C701",
    "3GFR34C702",
    "3GFR34C703",
    "3GFR34C704",
    "3N",
    "3NM",
    "3TM",
    "4A-235-B",
    "4A-235-B2",
    "4A-235-B3",
    "4A-235-B4",
    "4A-235-B6",
    "4A-235-B31",
    "4A4-75-A2",
    "4A4-75-A3",
    "4A4-75-B2",
    "4A4-75-B3",
    "4A4-85-A2",
    "4A4-85-A3",
    "4A4-85-B2",
    "4A4-85-B3",
    "4A4-95-A2",
    "4A4-95-A3",
    "4A4-95-B2",
    "4A4-95-B3",
    "4A4-100-B2",
    "4A4-100-B3",
    "4AC-150",
    "4AC-176 Series",
    "4AC-199B2",
    "4AC-199B3",
    "4AC-199D2",
    "4AC-199D3",
    "4AC-199E2",
    "4AC-199E3",
    "4AC-199E4 (O-200-1)",
    "4AC3-199H3 (O-200-5)",
    "4HFR34C",
    "5JFR36C1003",
    "6-260-A",
    "6-285-A",
    "6-285-B",
    "6-285-BA",
    "6-285-C",
    "6-285-CA",
    "6-320-B",
    "6-440-C2 (L-440-1)",
    "6-440-C3",
    "6-440-C4",
    "6-440-C5 (Military L-440-2, 3,5,7)",
    "6/5500/F-1",
    "6/5500/F-2",
    "6A-335-A",
    "6A-335-B",
    "6A-335-B1A",
    "6A-335-D",
    "6A-350-C1",
    "6A-350-C1A",
    "6A-350-C1L",
    "6A-350-C1R",
    "6A-350-C2",
    "6A-350-C2A",
    "6A-350-D",
    "6A-350-D1",
    "6A-350-D1A",
    "6A-350-D1B",
    "6A4-150-B3",
    "6A4-150-B4",
    "6A4-150-B31",
    "6A4-165-B3",
    "6A4-165-B4",
    "6A4-165-B6",
    "6A4-200-C6",
    "6A8-215-B7F",
    "6A8-215-B8F",
    "6A8-215-B9F",
    "6AC-264",
    "6AC-298",
    "6ACT-298",
    "6V-350-A",
    "6V-350-B",
    "6V4-178-B3",
    "6V4-178-B31",
    "6V4-178-B32",
    "6V4-178-B33",
    "6V4-200-C32",
    "6V4-200-C33",
    "7AC",
    "7ACA",
    "7BCM (Army L-16A)",
    "7CCM (Army L-16B)",
    "7DC",
    "7EC",
    "7ECA",
    "7FC",
    "7GC",
    "7GCA",
    "7GCAA",
    "7GCB",
    "7GCBA",
    "7GCBC",
    "7HC",
    "7JC",
    "7KC",
    "7KCAB",
    "7W (Army UC-71)",
    "8",
    "8A",
    "8B",
    "8C",
    "8D",
    "8E",
    "8F",
    "8GCBC",
    "8KCAB",
    "10",
    "11A",
    "11AC",
    "11BC",
    "11CC",
    "11E",
    "12-A (Army UC-40, UC-40A, Navy JO-1, JO-2)",
    "12-B",
    "14-9",
    "14-9L",
    "14-12F-3",
    "14-13",
    "14-13-2",
    "14-13-3",
    "14-13-3W",
    "14-19",
    "14-19-2",
    "14-19-3",
    "14-19-3A",
    "14-H",
    "14-H2",
    "14-N",
    "14-N2",
    "14-N3",
    "14RF-9",
    "14RF-10",
    "14RF-19",
    "14RF-20",
    "14RF-21",
    "14RF-22",
    "14RF-23",
    "14RF-24",
    "14RF-35",
    "14RF-36",
    "14RF-37",
    "14SF- 5",
    "14SF- 6",
    "14SF- 7",
    "14SF- 8",
    "14SF-11",
    "14SF-11E",
    "14SF-12",
    "14SF-15",
    "14SF-16",
    "14SF-17",
    "14SF-18",
    "14SF-19",
    "14SF-20",
    "14SF-21",
    "14SF-22",
    "14SF-23",
    "14SF-24",
    "14SFL11",
    "15A",
    "15AC",
    "17-30",
    "17-30A",
    "17-31",
    "17-31A",
    "17-31ATC",
    "17-31TC",
    "18",
    "18A",
    "18D",
    "18S (Army C-45C)",
    "19",
    "19A",
    "20",
    "22",
    "22 C7",
    "22 C7A",
    "22 C7AM",
    "22 C7AS",
    "22 C7B",
    "22 C7D",
    "22 C7E",
    "22 C7F",
    "22 C7G",
    "22D",
    "22M",
    "23",
    "23D",
    "23E",
    "23LF",
    "24",
    "24 C8",
    "24 C8A",
    "24 C8B",
    "24 C8C",
    "24 C8CS",
    "24 C8D",
    "24 C8DS",
    "24 C8E",
    "24 C8ES",
    "24 C8F (Army UC-61J)",
    "24 C8FS",
    "24A",
    "24B",
    "24B-A",
    "24C",
    "24D",
    "24D-A",
    "24E",
    "24F",
    "24F-A",
    "24G (Army UC-61H)",
    "24GS",
    "24H",
    "24HS",
    "24J (Army UC-61B)",
    "24JS",
    "24K (Army UC-61E)",
    "24KS",
    "24PF-303",
    "24PF-304",
    "24PF-305",
    "24PF-306",
    "24PF-309",
    "24PF-310",
    "24R9 (Army UC-61C)",
    "24R9S",
    "24R40 (Army UC-86)",
    "24R40S",
    "24R46",
    "24R46A (Army UC-61K)",
    "24R46S",
    "24W-9 (Army UC-61F)",
    "24W-9S",
    "24W-40 (Army UC-61G)",
    "24W-40S",
    "24W-41 (Army UC-61)",
    "24W-41A (Army UC-61A)",
    "24W-41AS",
    "24W-41S",
    "24W-46",
    "24W-46S",
    "25",
    "25A",
    "25B",
    "25C",
    "25D",
    "25F",
    "28",
    "29",
    "30",
    "30-AL",
    "30A",
    "31",
    "31A",
    "33D",
    "33E",
    "34D",
    "34E",
    "35",
    "35 Model or Series (all)",
    "35-33",
    "35-A33",
    "35-B33",
    "35-C33",
    "35-C33A",
    "35A (C-21A)",
    "35R",
    "36",
    "36 Model or Series (all)",
    "36A",
    "42A",
    "42B",
    "43D",
    "43E",
    "43H",
    "44",
    "44A",
    "44B",
    "45",
    "45 (Military YT-34)",
    "47",
    "47B",
    "47B3",
    "47D",
    "47D1",
    "47E",
    "47G",
    "47G-2",
    "47G-2A",
    "47G-2A-1",
    "47G-3",
    "47G-3B",
    "47G-3B-1",
    "47G-3B-2",
    "47G-3B-2A",
    "47G-4",
    "47G-4A",
    "47G-5",
    "47G-5A",
    "47H-1",
    "47J",
    "47J-2",
    "47J-2A",
    "47K",
    "49-46",
    "50",
    "50 Model or Series (all)",
    "54H",
    "55",
    "55B",
    "55C",
    "56A",
    "56TC",
    "56X",
    "58",
    "58 Model or Series (all)",
    "58A",
    "58P",
    "58PA",
    "58TC",
    "58TCA",
    "60",
    "60 Model or Series (all)",
    "60A",
    "60X",
    "65",
    "65-24",
    "65-80",
    "65-88",
    "65-90",
    "65-A80",
    "65-A80-8800",
    "65-A90",
    "65-A90-1",
    "65-A90-2",
    "65-A90-3",
    "65-A90-4",
    "65-B80",
    "65-CA (Army L-3F)",
    "65-LA",
    "69A",
    "69B",
    "69CK",
    "69X",
    "70",
    "70CM6",
    "70CM7",
    "72CC",
    "72CK",
    "74CK",
    "74DC",
    "74DM6",
    "74DM7",
    "74DR",
    "75 (Army PT-13)",
    "76",
    "76AK-2",
    "76AM6-2",
    "76E Series",
    "77",
    "77-24",
    "77A",
    "77B",
    "78C",
    "80-A",
    "80-A1",
    "80BM8",
    "88xx Series",
    "89xx Series",
    "90-24",
    "90A",
    "90B",
    "90C",
    "90xx Series",
    "91B7",
    "91B8",
    "91C0",
    "91C2",
    "91C4",
    "91C5",
    "91C9",
    "91xx Series",
    "92xx Series",
    "93xx Series",
    "95",
    "95 Model or Series (all)",
    "95-55",
    "95-A55",
    "95-B55",
    "95-B55A",
    "95-B55B",
    "95-C55",
    "95-C55A",
    "95xx Series",
    "96xx Series",
    "99",
    "99A",
    "99A (FACH)",
    "100",
    "101",
    "101A",
    "101AP",
    "101P",
    "105-24",
    "105A",
    "105B",
    "107-II",
    "108",
    "108-1",
    "108-2",
    "108-3",
    "108-5",
    "110C",
    "110P",
    "111",
    "112",
    "112B",
    "112TC",
    "112TCA",
    "114",
    "114A",
    "114B",
    "114TC",
    "120",
    "120-24",
    "120A",
    "140",
    "140-24",
    "140A",
    "149-46",
    "150",
    "150A",
    "150B",
    "150C",
    "150D",
    "150E",
    "150F",
    "150G",
    "150H",
    "150J",
    "150K",
    "150L",
    "150M",
    "152",
    "160-24",
    "160A",
    "170",
    "170A",
    "170B",
    "172",
    "172A",
    "172B",
    "172C",
    "172D",
    "172E",
    "172F (USAF T-41A)",
    "172G",
    "172H (USAF T-41A)",
    "172I",
    "172K",
    "172L",
    "172M",
    "172N",
    "172P",
    "172Q",
    "172R",
    "172RG",
    "172S",
    "175",
    "175A",
    "175B",
    "175C",
    "177",
    "177A",
    "177B",
    "177RG",
    "180",
    "180-24",
    "180A",
    "180B",
    "180C",
    "180D",
    "180E",
    "180F",
    "180G",
    "180H",
    "180J",
    "180K",
    "182",
    "182A",
    "182B",
    "182C",
    "182D",
    "182E",
    "182F",
    "182G",
    "182H",
    "182J",
    "182K",
    "182L",
    "182M",
    "182N",
    "182P",
    "182Q",
    "182R",
    "182S",
    "182T",
    "185",
    "185A",
    "185B",
    "185C",
    "185D",
    "185E",
    "188",
    "188A",
    "188B",
    "188C",
    "190",
    "190F",
    "195",
    "195A",
    "195B",
    "200",
    "200-24",
    "200A",
    "200B",
    "200C",
    "200CT",
    "200D",
    "200T",
    "201",
    "201A",
    "201B",
    "201C",
    "202",
    "202A",
    "203-B",
    "204B",
    "205A",
    "205A-1",
    "205B",
    "206",
    "206A",
    "206A-1",
    "206A-1 (OH-58A)",
    "206B",
    "206B-1",
    "206H",
    "206L",
    "206L-1",
    "206L-3",
    "206L-4",
    "207",
    "207A",
    "208",
    "208A",
    "208B",
    "209/AH-1G",
    "210",
    "210-5 (205)",
    "210-5A (205A)",
    "210A",
    "210B",
    "210C",
    "210D",
    "210E",
    "210F",
    "210G",
    "210H",
    "210J",
    "210K",
    "210L",
    "210M",
    "210N",
    "210R",
    "210S",
    "212",
    "214",
    "214B",
    "214B-1",
    "214ST",
    "220",
    "220-24",
    "222",
    "222B",
    "222U",
    "230",
    "232",
    "234",
    "240 Series",
    "240A",
    "242",
    "247-D (Army C-73)",
    "247F-1",
    "247F-1E",
    "247F-3",
    "250-B15A",
    "250-B15E",
    "250-B15G",
    "250-B17",
    "250-B17B",
    "250-B17C",
    "250-B17D",
    "250-B17E",
    "250-B17F",
    "250-B17F/1",
    "250-B17F/2",
    "250-C10 (T63-A-5)",
    "250-C10B (T63-A-5A)",
    "250-C10D (T63-A-700)",
    "250-C18",
    "250-C18A",
    "250-C18B",
    "250-C18C",
    "250-C19",
    "250-C20",
    "250-C20B",
    "250-C20C (T63-A-720)",
    "250-C20F",
    "250-C20J",
    "250-C20R",
    "250-C20R/1",
    "250-C20R/2",
    "250-C20R/4",
    "250-C20S",
    "250-C20W",
    "250-C28",
    "250-C28B",
    "250-C28C",
    "250-C30",
    "250-C30G",
    "250-C30G/2",
    "250-C30M",
    "250-C30P",
    "250-C30R (T703-AD-700)",
    "250-C30R/1 (T703-AD-700B)",
    "250-C30R/3",
    "250-C30R/3M",
    "250-C30S",
    "250-C30U",
    "250-C40B",
    "250-C47B",
    "250-C47M",
    "250-C300/A1",
    "250-C300/B1",
    "260A",
    "269A",
    "269A-1",
    "269B",
    "269C",
    "269C-1",
    "269D",
    "278",
    "280",
    "280C",
    "280F",
    "280FX",
    "282-44A-05 (C-130B)",
    "300",
    "300-50A-01 (USAF C-141A)",
    "300-W Pacemaker",
    "300LW",
    "305",
    "305A",
    "310",
    "310A",
    "310B",
    "310C",
    "310D",
    "310E",
    "310F",
    "310G",
    "310H",
    "310I",
    "310J",
    "310J-1",
    "310K",
    "310L",
    "310N",
    "310P",
    "310Q",
    "310R",
    "314",
    "320",
    "320-1",
    "320A",
    "320B",
    "320C",
    "320D",
    "320E",
    "320F",
    "321",
    "328-100",
    "328-300",
    "335",
    "336",
    "337",
    "337A",
    "337B",
    "337C",
    "337D",
    "337E",
    "337F",
    "337G",
    "337H",
    "340",
    "340A",
    "340A (SAAB SF340A)",
    "360",
    "369",
    "369A",
    "369D",
    "369E",
    "369F",
    "369FF",
    "369H",
    "369HE",
    "369HM",
    "369HS",
    "377",
    "377MG",
    "377SGT",
    "382",
    "382B",
    "382E",
    "382F",
    "382G",
    "382J",
    "390",
    "400",
    "400A",
    "400T",
    "401",
    "401A",
    "401B",
    "402",
    "402-2",
    "402A",
    "402B",
    "402C",
    "404",
    "406",
    "407",
    "408",
    "411",
    "411A",
    "412",
    "412CF",
    "412EP",
    "414",
    "414A",
    "415-C",
    "415-CD",
    "415-D",
    "415-E",
    "415-G",
    "421",
    "421A",
    "421B",
    "421C",
    "425",
    "427",
    "429",
    "430",
    "440",
    "441",
    "480",
    "480B",
    "500",
    "500-A",
    "500-B",
    "500-S",
    "500-U",
    "500N",
    "501",
    "501-D13",
    "501-D13A",
    "501-D13D",
    "501-D13E",
    "501-D13H",
    "501-D22",
    "501-D22A",
    "501-D22C",
    "501-D22G",
    "505",
    "510",
    "520",
    "525",
    "525A",
    "525B",
    "525C",
    "537-Series Cabin Attendant Seats",
    "550",
    "551",
    "552",
    "560",
    "560-A",
    "560-E",
    "560-F",
    "560XL",
    "568F-1",
    "568F-5",
    "568F-7",
    "568F-11",
    "600 S-2D",
    "600-S-2C",
    "600N",
    "620",
    "620A",
    "620B",
    "620B-TG",
    "620TP",
    "649-79",
    "649A-79",
    "650",
    "680",
    "680-E",
    "680-F",
    "680-F(P)",
    "680-FL",
    "680-FL(P)",
    "680-T",
    "680-V",
    "680-W",
    "680A",
    "681",
    "685",
    "690",
    "690A",
    "690B",
    "690C",
    "690D",
    "695",
    "695A",
    "695B",
    "700",
    "707-100 Long Body",
    "707-100B Long Body",
    "707-100B Short Body",
    "707-200",
    "707-300 Series",
    "707-300B Series",
    "707-300C Series",
    "707-400 Series",
    "717-200",
    "718C9GB1",
    "720",
    "720 Series",
    "720B Series",
    "727 Series",
    "727-100 Series",
    "727-100C Series",
    "727-200 Series",
    "727-200F Series",
    "727C Series",
    "737-8",
    "737-9",
    "737-100 Series",
    "737-200 Series",
    "737-200C Series",
    "737-300 Series",
    "737-400 Series",
    "737-500 Series",
    "737-600 Series",
    "737-700 Series",
    "737-700C Series",
    "737-800 Series",
    "737-900 Series",
    "737-900ER Series",
    "737-8200",
    "747-8 Series",
    "747-8F Series",
    "747-100 Series",
    "747-100B Series",
    "747-100B SUD Series",
    "747-200B Series",
    "747-200C Series",
    "747-200F Series",
    "747-300 Series",
    "747-400 Series",
    "747-400D Series",
    "747-400F Series",
    "747SP Series",
    "747SR Series",
    "749-79",
    "749-79 (C-121A, VC-121B)",
    "749A-79",
    "750",
    "750XL",
    "752",
    "752-12",
    "755-12",
    "757-200 Series",
    "757-200CB Series",
    "757-200PF Series",
    "757-300 Series",
    "767-2C Series",
    "767-200 Series",
    "767-300 Series",
    "767-300F Series",
    "767-400ER Series",
    "777-200 Series",
    "777-200LR Series",
    "777-300 Series",
    "777-300ER Series",
    "777F Series",
    "787-8",
    "787-9",
    "787-10",
    "813",
    "851AK pitot probes",
    "858",
    "912 F2",
    "912 F3",
    "912 F4",
    "912 iSc2 Sport",
    "912 iSc3 Sport",
    "912 S2",
    "912 S3",
    "912 S4",
    "914 F2",
    "914 F3",
    "914 F4",
    "921, 930, 937, 940, 944, 945, 977, 978, 979, 8240, 8253, 8259, and 8472",
    "1049-54",
    "1049B-55 (Navy R7V-1)",
    "1049C-55",
    "1049D-55",
    "1049E-55",
    "1049F-55 (USAF C-121C)",
    "1049G-82",
    "1049H-82",
    "1100",
    "1121",
    "1121A",
    "1121B",
    "1123",
    "1124",
    "1124A",
    "1125 Westwind Astra",
    "1329-23A",
    "1329-23D",
    "1329-23E",
    "1329-25",
    "1649A-98",
    "1900",
    "1900C",
    "1900C (C-12J)",
    "1900D",
    "2000",
    "2150",
    "2150A",
    "2180",
    "3000",
    "4000",
    "4157",
    "4170",
    "4184",
    "4500-300",
    "4500-300 Series II",
    "5934 series altimeters",
    "9140",
    "9166",
    "9173",
    "9174",
    "9184",
    "9188",
    "9196",
    "9301",
    "9501",
    "A",
    "A-1",
    "A-1A",
    "A-1B",
    "A-1C-180",
    "A-1C-200",
    "A-2",
    "A-3",
    "A-4",
    "A-5",
    "A-5T",
    "A-6",
    "A-7",
    "A-7T",
    "A-9",
    "A-9A",
    "A-9B",
    "A-26B (Army)",
    "A-26C (Army)",
    "A-50-1",
    "A-50-2",
    "A-50-3",
    "A-50-4",
    "A-50-4J",
    "A-50-5",
    "A-50-5J",
    "A-50-6",
    "A-50-6J",
    "A-50-7",
    "A-50-7J",
    "A-50-8",
    "A-50-8J",
    "A-50-9",
    "A-50-9J",
    "A-60S",
    "A-65-1",
    "A-65-3",
    "A-65-6",
    "A-65-6J",
    "A-65-7",
    "A-65-8",
    "A-65-8F",
    "A-65-8FJ",
    "A-65-8J",
    "A-65-9",
    "A-65-9F",
    "A-65-9FJ",
    "A-65-9J",
    "A-65-12",
    "A-65-12F",
    "A-65-12FJ",
    "A-65-12J",
    "A-65-14",
    "A-65-14F",
    "A-65-14FJ",
    "A-65-14J",
    "A-80-5J",
    "A-80-8",
    "A-80-8J",
    "A-80-9",
    "A-80-9J",
    "A-105",
    "A-120",
    "A-140",
    "A-160",
    "A-180",
    "A-210",
    "A-250",
    "A-275",
    "A-300",
    "A-314",
    "A-315",
    "A-400",
    "A-B",
    "A2-A",
    "A18A",
    "A18D",
    "A23",
    "A23-19",
    "A23-24",
    "A23A",
    "A24",
    "A24R",
    "A35",
    "A36",
    "A36TC",
    "A45 (Military T-34A, B-45)",
    "A45 (Military T-34A; B-45)",
    "A50S",
    "A55",
    "A55S",
    "A56TC",
    "A60",
    "A65",
    "A65-8200",
    "A75 (Army PT-13A, -13B, -13C)",
    "A75 (Army PT-13A; -13B; -13C)",
    "A75-3",
    "A75-6",
    "A75-6J",
    "A75-8",
    "A75-8F",
    "A75-8FJ",
    "A75-8J",
    "A75-9",
    "A75-9J",
    "A75J1 (Army PT-18)",
    "A75L3",
    "A75L300",
    "A75N1 (Army PT-17, -17A, Navy N2S-1, -4)",
    "A75N1 (Army PT-17; -17A; Navy N2S-1; -4)",
    "A99",
    "A99A",
    "A100",
    "A100-1",
    "A100-1 (U-21J)",
    "A100-2",
    "A100A",
    "A100C",
    "A109",
    "A109A",
    "A109A II",
    "A109C",
    "A109E",
    "A109K2",
    "A109S",
    "A119",
    "A150K",
    "A150L",
    "A150M",
    "A152",
    "A185E",
    "A185F",
    "A188",
    "A188A",
    "A188B",
    "A200",
    "A200 (C-12A)",
    "A200 (C-12C)",
    "A200C",
    "A200C (UC-12B)",
    "A200CT",
    "A200CT (C-12D)",
    "A200CT (C-12F)",
    "A200CT (FWC-12D)",
    "A200CT (RC-12D)",
    "A200CT (RC-12G)",
    "A200CT (RC-12H)",
    "A200CT (RC-12K)",
    "A200CT (RC-12P)",
    "A200CT (RC-12Q)",
    "A300 B2-1A",
    "A300 B2-1C",
    "A300 B2-203",
    "A300 B2K-3C",
    "A300 B4-2C",
    "A300 B4-103",
    "A300 B4-203",
    "A300 B4-601",
    "A300 B4-603",
    "A300 B4-605R",
    "A300 B4-620",
    "A300 B4-622",
    "A300 B4-622R",
    "A300 C4-605R Variant F",
    "A300 F4-605R",
    "A300 F4-622R",
    "A310-203",
    "A310-204",
    "A310-221",
    "A310-222",
    "A310-304",
    "A310-322",
    "A310-324",
    "A310-325",
    "A318-111",
    "A318-112",
    "A318-121",
    "A318-122",
    "A319-111",
    "A319-112",
    "A319-113",
    "A319-114",
    "A319-115",
    "A319-131",
    "A319-132",
    "A319-133",
    "A319-151N",
    "A319-153N",
    "A319-171N",
    "A320-111",
    "A320-211",
    "A320-212",
    "A320-214",
    "A320-216",
    "A320-231",
    "A320-232",
    "A320-233",
    "A320-251N",
    "A320-252N",
    "A320-253N",
    "A320-271N",
    "A320-272N",
    "A320-273N",
    "A321-111",
    "A321-112",
    "A321-131",
    "A321-211",
    "A321-212",
    "A321-213",
    "A321-231",
    "A321-232",
    "A321-251N",
    "A321-251NX",
    "A321-252N",
    "A321-252NX",
    "A321-253N",
    "A321-253NX",
    "A321-271N",
    "A321-271NX",
    "A321-272N",
    "A321-272NX",
    "A330-201",
    "A330-202",
    "A330-203",
    "A330-223",
    "A330-223F",
    "A330-243",
    "A330-243F",
    "A330-301",
    "A330-302",
    "A330-303",
    "A330-321",
    "A330-322",
    "A330-323",
    "A330-341",
    "A330-342",
    "A330-343",
    "A330-841",
    "A330-941",
    "A340-211",
    "A340-212",
    "A340-213",
    "A340-311",
    "A340-312",
    "A340-313",
    "A340-541",
    "A340-642",
    "A350-941",
    "A350-1041",
    "A380-841",
    "A380-842",
    "A380-861",
    "A500",
    "A6441FN-606",
    "A1754001-23",
    "A1754001-25",
    "AA-1",
    "AA-1A",
    "AA-1B",
    "AA-1C",
    "AA-5",
    "AA-5A",
    "AA-5B",
    "AAF 41D5926",
    "AB139",
    "AB412",
    "AB412 EP",
    "Accessory Gear boxes",
    "Accumulator Assemblies",
    "Actuating Valves",
    "Actuator nut assembly",
    "Adaptive Flight Display Units",
    "ADS600-B P/N 200-0012",
    "ADS600-B P/N 200-0013",
    "ADS600-EXP P/N 200-8013",
    "AE 1107C",
    "AE 2100A",
    "AE 2100C",
    "AE 2100D2",
    "AE 2100D2A",
    "AE 2100D3",
    "AE 2100J",
    "AE 2100P",
    "AE 3007A",
    "AE 3007A1",
    "AE 3007A1/1",
    "AE 3007A1/2",
    "AE 3007A1/3",
    "AE 3007A1E",
    "AE 3007A1P",
    "AE 3007A2",
    "AE 3007A3",
    "AE 3007C",
    "AE 3007C1",
    "AE 3007C2",
    "AE-1",
    "AEIO-320-D1B",
    "AEIO-320-D2B",
    "AEIO-320-E1A",
    "AEIO-320-E1B",
    "AEIO-320-E2A",
    "AEIO-320-E2B",
    "AEIO-360-A1A",
    "AEIO-360-A1B",
    "AEIO-360-A1B6",
    "AEIO-360-A1C",
    "AEIO-360-A1D",
    "AEIO-360-A1E",
    "AEIO-360-A1E6",
    "AEIO-360-A2A",
    "AEIO-360-A2B",
    "AEIO-360-A2C",
    "AEIO-360-B1B",
    "AEIO-360-B1D",
    "AEIO-360-B1F",
    "AEIO-360-B1F6",
    "AEIO-360-B1G6",
    "AEIO-360-B1H",
    "AEIO-360-B2F",
    "AEIO-360-B2F6",
    "AEIO-360-B4A",
    "AEIO-360-H1A",
    "AEIO-360-H1B",
    "AEIO-390- A1A6",
    "AEIO-390-A1B6",
    "AEIO-390-A3A6",
    "AEIO-390-A3B6",
    "AEIO-540-D4A5",
    "AEIO-540-D4B5",
    "AEIO-540-D4C5",
    "AEIO-540-D4D5",
    "AEIO-540-L1B5",
    "AEIO-540-L1B5D",
    "AEIO-540-L1D5",
    "AEIO-580-B1A",
    "Aero Commander 100",
    "Aero Commander 100-180",
    "Aero Commander 100A",
    "Aerospace Corp Wheel Assemblies",
    "AG-5B",
    "AH-1S",
    "AIO-320-A1A",
    "AIO-320-A1B",
    "AIO-320-A2A",
    "AIO-320-A2B",
    "AIO-320-B1B",
    "AIO-320-C1B",
    "AIO-360-A1A",
    "AIO-360-A1B",
    "AIO-360-A2A",
    "AIO-360-A2B",
    "AIO-360-B1B",
    "Air Conditioners",
    "Air Data Computers",
    "Air data pressure transducers",
    "Aircraft Seats",
    "Aircraft Starter",
    "Aircraft Tires",
    "Airspeed Indicators",
    "AK-450-( ) series emergency locator transmitters",
    "AK-451-( ) series emergency locator transmitters",
    "AL 60",
    "AL 60-B",
    "AL 60-C5",
    "AL 60-F5",
    "ALF502L",
    "ALF502L-2",
    "ALF502L-2A",
    "ALF502L-2C",
    "ALF502L-3",
    "ALF502R-3",
    "ALF502R-3A",
    "ALF502R-4",
    "ALF502R-5",
    "ALF502R-6",
    "All hot air balloons with BAL NY KUB -EK spol. s r.o. Model Kub -ek burners",
    "Alternator Cooling Fans",
    "Alternators",
    "Altimeters",
    "AM-3",
    "AMT-100 (Ximango)",
    "AMT-200 (Super Ximango)",
    "AMT-200S (Super Ximango)",
    "AMT-300 (Turbo Ximango Shark)",
    "Angle of attack sensors",
    "AP68 TP Series 300 Spartacus",
    "AP68TP 600 Viator",
    "AQUILA AT01",
    "Arava 101",
    "Arava 101B",
    "Area Navigation Systems",
    "Argosy AW.650 Series 101",
    "ARKY",
    "Army AT-11",
    "Army B-17F",
    "Army B-17G",
    "Army B-34",
    "Army L-13 Series",
    "Army TG-6 Conversions",
    "Army UC-64",
    "Army UC-64A",
    "Army UC-64AS",
    "Army UC-64B",
    "Army YR-6A",
    "Arriel 1",
    "Arriel 1A",
    "Arriel 1A1",
    "Arriel 1A2",
    "Arriel 1B",
    "Arriel 1B2",
    "Arriel 1C",
    "Arriel 1C1",
    "Arriel 1C2",
    "Arriel 1D",
    "Arriel 1D1",
    "Arriel 1E",
    "Arriel 1E2",
    "Arriel 1K1",
    "Arriel 1S",
    "Arriel 1S1",
    "Arriel 2B",
    "Arriel 2B1",
    "Arriel 2C",
    "Arriel 2C1",
    "Arriel 2C2",
    "Arriel 2D",
    "Arriel 2E",
    "Arriel 2S1",
    "Arriel 2S2",
    "Arrius 1A",
    "Arrius 2B1",
    "Arrius 2B1A",
    "Arrius 2B2",
    "Arrius 2F",
    "Arrius 2K1",
    "ARRIUS 2R",
    "Artouste IIB1",
    "Artouste IIC",
    "Artouste IIC1",
    "Artouste IIC2",
    "Artouste IIC5",
    "Artouste IIC6",
    "Artouste IIIB",
    "Artouste IIIB1",
    "Artouste IIID",
    "AS 12",
    "AS 202/15 BRAVO",
    "AS 202/18A BRAVO",
    "AS 202/18A4 BRAVO",
    "AS-365N2",
    "AS-365N3",
    "AS-K 13",
    "AS332C",
    "AS332C1",
    "AS332L",
    "AS332L1",
    "AS332L2",
    "AS350A",
    "AS350B",
    "AS350B1",
    "AS350B2",
    "AS350B3",
    "AS350BA",
    "AS350C",
    "AS350D",
    "AS350D1",
    "AS355E",
    "AS355F",
    "AS355F1",
    "AS355F2",
    "AS355N",
    "AS355NP",
    "AS907-1-1A",
    "AS907-2-1A",
    "AS907-2-1G",
    "AS907-3-1E",
    "ASH 25M",
    "ASH 26E",
    "ASK 21",
    "Astazou II A",
    "Astazou XIV B",
    "Astazou XIV H",
    "Astra SPX",
    "ASW -15",
    "ASW 12",
    "ASW 17",
    "ASW 27",
    "ASW-15B",
    "ASW-19",
    "ASW-19B",
    "AT-3R100",
    "AT-6 (SNJ-2)",
    "AT-6A (SNJ-3)",
    "AT-6B",
    "AT-6C (SNJ-4)",
    "AT-6D (SNJ-5)",
    "AT-6F (SNJ-6)",
    "AT-250",
    "AT-300",
    "AT-301",
    "AT-302",
    "AT-400",
    "AT-400A",
    "AT-401",
    "AT-401A",
    "AT-401B",
    "AT-402",
    "AT-402A",
    "AT-402B",
    "AT-501",
    "AT-502",
    "AT-502A",
    "AT-502B",
    "AT-503",
    "AT-503A",
    "AT-504",
    "AT-602",
    "AT-802",
    "AT-802A",
    "ATF3-6",
    "ATF3-6A",
    "ATL-88/90-1B",
    "ATP",
    "ATR42-200",
    "ATR42-300",
    "ATR42-320",
    "ATR42-500",
    "ATR72-101",
    "ATR72-102",
    "ATR72-201",
    "ATR72-202",
    "ATR72-211",
    "ATR72-212",
    "ATR72-212A",
    "Attendant seats NG",
    "Attitude and Heading Reference System",
    "Attitude and Heading Reference System (AHRS)",
    "Attitude Heading Reference System",
    "Attitude Indicator",
    "Auto Pilot Systems Instruments",
    "Automatic Pilot Instruments",
    "Automatic Pilots",
    "Autopilot Servo Actuators",
    "Autopilot System",
    "Autopilot Systems",
    "Auxiliar power units",
    "Auxiliary Fuel System",
    "Auxiliary Power Unit",
    "Auxiliary Power Unit (APU)",
    "Auxiliary Power Unit model GTCP36-150(R)",
    "Auxiliary Power Unit model GTCP36-150(RR)",
    "Auxiliary Power Units",
    "Auxiliary Power Units (APU)",
    "Auxiliary power units (APUs)",
    "Auxiliary Power Units (APUs)",
    "Aviation Tires",
    "Avro 146-RJ70A",
    "Avro 146-RJ85A",
    "Avro 146-RJ100A",
    "AW109SP",
    "AW119 MKII",
    "AW139",
    "AW169",
    "AW189",
    "AX-6",
    "AX5-42 (S.1)",
    "AX5-42 BOLT",
    "AX6-56 (S.1)",
    "AX6-56 BOLT",
    "AX6-56A",
    "AX6-56Z",
    "AX7-65 (S.1)",
    "AX7-65 BOLT",
    "AX7-65Z",
    "AX7-77",
    "AX7-77 (S.1)",
    "AX7-77 BOLT",
    "AX7-77A",
    "AX7-77b",
    "AX7-77Z",
    "AX8-88",
    "AX8-88b",
    "AX8-90 (S.1)",
    "AX8-90 (S.2)",
    "AX8-105",
    "AX8-105 (S.1)",
    "AX8-105 (S.2)",
    "AX9-118",
    "AX9-120 (S.1)",
    "AX9-120 (S.2)",
    "AX9-140 (S.2)",
    "AX10-160 (S.1)",
    "AX10-160(S.2)",
    "AX10-180 (S.1)",
    "AX10-180(S.2)",
    "AX10-210(S.2)",
    "AX11-225(S.2)",
    "AX11-250(S.2)",
    "AXP340 Mode S transponders",
    "B",
    "B-1",
    "B-1A",
    "B-2",
    "B-2A",
    "B-2B",
    "B-Series Combustion Heaters",
    "B.206 Series 1",
    "B.206 Series 2",
    "B2A31C63",
    "B2A34C",
    "B2A36C31",
    "B2A37C",
    "B2D34C",
    "B2D34CT69",
    "B2D37C",
    "B3D32C407",
    "B3D32C412",
    "B3D32C414",
    "B3D32C417",
    "B3D32C419",
    "B3D34C405",
    "B3D34C413",
    "B3D36C416",
    "B3D36C424",
    "B3D36C427",
    "B3D36C428",
    "B3D36C429",
    "B3D36C431",
    "B3D36C432",
    "B3D36C433",
    "B3D36C442",
    "B3DF36C526",
    "B3DF36C527",
    "B4-PC11",
    "B4-PC11A",
    "B4-PC11AF",
    "B5JFR36C1101",
    "B5JFR36C1103",
    "B17B",
    "B17L",
    "B17R (Army UC-43H)",
    "B18S (Army F-2)",
    "B19",
    "B23",
    "B24R",
    "B35",
    "B36TC",
    "B50",
    "B60",
    "B75 (Navy N2S-5)",
    "B75L (Army UC-92)",
    "B75N1 (Navy N2S-3)",
    "B85C",
    "B90",
    "B95",
    "B95A",
    "B99",
    "B100",
    "B200",
    "B200 (propeller)",
    "B200C",
    "B200C (C-12F)",
    "B200C (C-12R)",
    "B200C (UC-12F)",
    "B200C (UC-12M)",
    "B200CGT",
    "B200CT",
    "B200GT",
    "B200T",
    "B300",
    "B300C",
    "B300C (C-12W)",
    "B300C (MC-12W)",
    "B300C (UC-12W)",
    "BAC 1-11 200 Series",
    "BAC 1-11 400 Series",
    "BAe 146-100A",
    "BAe 146-200A",
    "BAe 146-300A",
    "BAe.125 Series 800A",
    "BAe.125 Series 800A (C-29A)",
    "BAe.125 Series 800A (U-125)",
    "BAe.125 Series 800B",
    "BAe.125 Series 1000A",
    "BAe.125 Series 1000B",
    "BAG",
    "Ballasts",
    "BARCARDI MIXER-100",
    "Battery Packs",
    "Battery-90",
    "BB17GP",
    "BB17XR",
    "BB20",
    "BB20E",
    "BB20GP",
    "BB20XR",
    "BB22",
    "BB22E",
    "BB22N",
    "BB22Z",
    "BB26",
    "BB26E",
    "BB26N",
    "BB26Z",
    "BB30N",
    "BB30Z",
    "BB34Z",
    "BB37N",
    "BB37Z",
    "BB40Z",
    "BB42Z",
    "BB45N",
    "BB45Z",
    "BB51Z",
    "BB60N",
    "BB60Z",
    "BB70Z",
    "BB78Z",
    "BB85Z",
    "BB92Z",
    "BB100Z",
    "BB120P",
    "BB130P",
    "BC",
    "BC-1A",
    "BC-65",
    "BC12-65",
    "BC12-D",
    "BC12-D1",
    "BC12D-4-85",
    "BC12D-85",
    "BCS",
    "BCS-65",
    "BCS12-65",
    "BCS12-D",
    "BCS12-D1",
    "BCS12D-4-85",
    "BCS12D-85",
    "BD-100-1A10",
    "BD-100-1A10 (Challenger 300)",
    "BD-500-1A10",
    "BD-500-1A11",
    "BD-700-1A10",
    "BD-700-1A11",
    "BD-700-2A12",
    "Beagle B.121 Series 1",
    "Beagle B.121 Series 2",
    "Beagle B.121 Series 3",
    "BEAR",
    "Bee Dee M-4",
    "Bergfalke-II/55",
    "Bergfalke-III",
    "BF",
    "BF-60",
    "BF-65",
    "BF12-65",
    "BFS",
    "BFS-60",
    "BFS-65",
    "BFS12-65",
    "BH.125 Series 400A",
    "BH.125 Series 600A",
    "BHC-92W",
    "BHC-92ZF-1",
    "BHC-92ZF-3",
    "BHC-92ZF-8",
    "BHC-A2V",
    "BHC-A2XF-1",
    "BHC-A2XF-3",
    "BHC-A2XF-G",
    "BHC-B3W",
    "BHC-C2Y",
    "BHC-G2YF-1",
    "BHC-I2YF-1",
    "BHC-I2YF-4",
    "BHC-J2YF-1",
    "BHC-J2YF-2",
    "BHC-L2YF-1",
    "BHC-L2YF-2",
    "BHC-L2YF-4",
    "BHI H60 (UH-60A)",
    "BL",
    "BL-65",
    "BL12-65",
    "Blanik L-13",
    "Blanik L-13 AC",
    "BLS",
    "BLS-65",
    "BLS12-65",
    "BN-2",
    "BN-2A",
    "BN-2A-2",
    "BN-2A-3",
    "BN-2A-6",
    "BN-2A-8",
    "BN-2A-9",
    "BN-2A-20",
    "BN-2A-21",
    "BN-2A-26",
    "BN-2A-27",
    "BN-2B-20",
    "BN-2B-21",
    "BN-2B-26",
    "BN-2B-27",
    "BN-2T",
    "BN-2T-4R",
    "BN2A MK. III",
    "BN2A MK. III-2",
    "BN2A MK. III-3",
    "BN2T-4S",
    "BO-105A",
    "BO-105C",
    "BO-105LS A-1",
    "BO-105LS A-3",
    "BO-105S",
    "BO-209-150 FF",
    "BO-209-150 FV",
    "BO-209-150 RV",
    "BO-209-160 FV",
    "BO-209-160 RV",
    "Bolkow Jr.",
    "Bottle-105",
    "BR700-710A1-10",
    "BR700-710A2-20",
    "BR700-710C4-11",
    "BR700-710D5-21",
    "BR700-715A1-30",
    "BR700-715B1-30",
    "BR700-715C1-30",
    "BR700-725A1-12",
    "BRAIN",
    "BRUT",
    "BS-12D1",
    "BT-13",
    "BT-13A (Army SNV-1)",
    "BT-13B (Navy SNV-2)",
    "BT-15",
    "BUNY",
    "Burger-65",
    "Burners",
    "C-1",
    "C-2FB1",
    "C-2FB2",
    "C-2FM3",
    "C-2FM4",
    "C-2FM5",
    "C-2FM6-1",
    "C-7",
    "C-45G",
    "C-45H",
    "C-46/CW20-T",
    "C-46A",
    "C-46D",
    "C-46E",
    "C-46F",
    "C-46R",
    "C-60",
    "C-80",
    "C-82A",
    "C-82A Jet Packet",
    "C-87A (Army)",
    "C-100",
    "C-115-1",
    "C-115-2",
    "C-119C",
    "C-119F/R4Q2",
    "C-119G",
    "C-119G-3E",
    "C-119L",
    "C-125-1",
    "C-125-2",
    "C-130A",
    "C-130B",
    "C-130H",
    "C-212-CB",
    "C-212-CC",
    "C-212-CD",
    "C-212-CE",
    "C-212-CF",
    "C-212-DE",
    "C-212-DF",
    "C-295",
    "C2A34C",
    "C2A34C2",
    "C2A36C32",
    "C2D37C",
    "C3D36C415",
    "C3FC3",
    "C3FR4",
    "C5JFR36C1102",
    "C5JFR36C1104",
    "C17B (Army UC-43G)",
    "C17L (Army UC-43J)",
    "C17R (Army UC-43E)",
    "C18S",
    "C23",
    "C24R",
    "C35",
    "C50",
    "C54-DC",
    "C54A-DC",
    "C54B-DC",
    "C54D-DC",
    "C54E-DC",
    "C54G-DC",
    "C75-8",
    "C75-8F",
    "C75-8FH",
    "C75-8FHJ",
    "C75-8FJ",
    "C75-8J",
    "C75-12",
    "C75-12B",
    "C75-12BF",
    "C75-12BFH",
    "C75-12F",
    "C75-12FH",
    "C75-12FHJ",
    "C75-12FJ",
    "C75-12J",
    "C75-15",
    "C75-15F",
    "C85-8",
    "C85-8F",
    "C85-8FHJ",
    "C85-8FJ",
    "C85-8J",
    "C85-12",
    "C85-12F",
    "C85-12FH",
    "C85-12FHJ",
    "C85-12FJ",
    "C85-12J",
    "C85-14",
    "C85-14F",
    "C85-15",
    "C85-15F",
    "C90",
    "C90-8F",
    "C90-8FJ",
    "C90-12F",
    "C90-12FH",
    "C90-12FJ",
    "C90-12FP",
    "C90-14F",
    "C90-14FH",
    "C90-14FJ",
    "C90-16F",
    "C90A",
    "C90GT",
    "C90GTi",
    "C99",
    "C145-2",
    "C145-2H",
    "C145-2HP",
    "C351-2000 series passenger oxygen masks",
    "C632S",
    "C634D",
    "C634S",
    "Cabin Attendant Seats",
    "Cabin Superchargers",
    "Cabin Supercharges",
    "CABRI G2",
    "CAKE",
    "Calidus",
    "Calif A21",
    "Calif A21S",
    "CAN",
    "CAN-56",
    "CAN-90",
    "Can-90",
    "Canopies",
    "CAP 10 B",
    "Caravelle SE 210 Model I",
    "Caravelle SE 210 Model III",
    "Caravelle SE 210 Model VIR",
    "Carburetor Air Inlet Filter",
    "Carburetors",
    "Cargo fire extinguisher halon bottles",
    "Cavalon",
    "CC19-180",
    "CELL",
    "CF6-6D",
    "CF6-6D1",
    "CF6-6D1A",
    "CF6-6K",
    "CF6-6K2",
    "CF6-45A",
    "CF6-45A2",
    "CF6-50A",
    "CF6-50C",
    "CF6-50C1",
    "CF6-50C2",
    "CF6-50C2B",
    "CF6-50C2D",
    "CF6-50CA",
    "CF6-50E",
    "CF6-50E1",
    "CF6-50E2",
    "CF6-50E2B",
    "CF6-80A",
    "CF6-80A1",
    "CF6-80A2",
    "CF6-80A3",
    "CF6-80C2A1",
    "CF6-80C2A2",
    "CF6-80C2A3",
    "CF6-80C2A5",
    "CF6-80C2A5F",
    "CF6-80C2A8",
    "CF6-80C2B1",
    "CF6-80C2B1F",
    "CF6-80C2B1F1",
    "CF6-80C2B1F2",
    "CF6-80C2B2",
    "CF6-80C2B2F",
    "CF6-80C2B3F",
    "CF6-80C2B4",
    "CF6-80C2B4F",
    "CF6-80C2B5F",
    "CF6-80C2B6",
    "CF6-80C2B6F",
    "CF6-80C2B6FA",
    "CF6-80C2B7F",
    "CF6-80C2B8F",
    "CF6-80C2D1F",
    "CF6-80C2K1F",
    "CF6-80C2L1F",
    "CF6-80E1A1",
    "CF6-80E1A2",
    "CF6-80E1A3",
    "CF6-80E1A4",
    "CF6-80E1A4/B",
    "CF34-1A",
    "CF34-3A",
    "CF34-3A1",
    "CF34-3A2",
    "CF34-3B",
    "CF34-3B1",
    "CF34-8C1",
    "CF34-8C5",
    "CF34-8C5A1",
    "CF34-8C5A2",
    "CF34-8C5A3",
    "CF34-8C5B1",
    "CF34-8E2",
    "CF34-8E2A1",
    "CF34-8E5",
    "CF34-8E5A1",
    "CF34-8E5A2",
    "CF34-8E6",
    "CF34-8E6A1",
    "CF34-10A16",
    "CF34-10E2A1",
    "CF34-10E5",
    "CF34-10E5A1",
    "CF34-10E6",
    "CF34-10E6A1",
    "CF34-10E7",
    "CF34-10E7-B",
    "CF700-2C",
    "CF700-2D",
    "CF700-2D-2",
    "CFE738-1-1B",
    "CFM56-2",
    "CFM56-2A",
    "CFM56-2B",
    "CFM56-3",
    "CFM56-3B",
    "CFM56-3C",
    "CFM56-5",
    "CFM56-5-A1/F",
    "CFM56-5A3",
    "CFM56-5A4",
    "CFM56-5A4/F",
    "CFM56-5A5",
    "CFM56-5A5/F",
    "CFM56-5B1",
    "CFM56-5B1/2P",
    "CFM56-5B1/3",
    "CFM56-5B1/P",
    "CFM56-5B2",
    "CFM56-5B2/2P",
    "CFM56-5B2/3",
    "CFM56-5B2/P",
    "CFM56-5B3/2P",
    "CFM56-5B3/2P1",
    "CFM56-5B3/3",
    "CFM56-5B3/3B1",
    "CFM56-5B3/P",
    "CFM56-5B3/P1",
    "CFM56-5B4",
    "CFM56-5B4/2P",
    "CFM56-5B4/2P1",
    "CFM56-5B4/3",
    "CFM56-5B4/3B1",
    "CFM56-5B4/P",
    "CFM56-5B4/P1",
    "CFM56-5B5",
    "CFM56-5B5/3",
    "CFM56-5B5/P",
    "CFM56-5B6",
    "CFM56-5B6/2P",
    "CFM56-5B6/3",
    "CFM56-5B6/P",
    "CFM56-5B7",
    "CFM56-5B7/3",
    "CFM56-5B7/P",
    "CFM56-5B8/3",
    "CFM56-5B8/P",
    "CFM56-5B9/2P",
    "CFM56-5B9/3",
    "CFM56-5B9/P",
    "CFM56-5C2",
    "CFM56-5C2/4",
    "CFM56-5C2/F",
    "CFM56-5C2/F4",
    "CFM56-5C2/G",
    "CFM56-5C2/G4",
    "CFM56-5C2/P",
    "CFM56-5C3/F",
    "CFM56-5C3/F4",
    "CFM56-5C3/G",
    "CFM56-5C3/G4",
    "CFM56-5C3/P",
    "CFM56-5C4",
    "CFM56-5C4/1",
    "CFM56-5C4/1P",
    "CFM56-5C4/P",
    "CFM56-7B18",
    "CFM56-7B20",
    "CFM56-7B20/2",
    "CFM56-7B20/3",
    "CFM56-7B20E",
    "CFM56-7B22",
    "CFM56-7B22/2",
    "CFM56-7B22/3",
    "CFM56-7B22/3B1",
    "CFM56-7B22/B1",
    "CFM56-7B22/B2",
    "CFM56-7B22E",
    "CFM56-7B22E/B1",
    "CFM56-7B24",
    "CFM56-7B24/2",
    "CFM56-7B24/3",
    "CFM56-7B24/3B1",
    "CFM56-7B24/B1",
    "CFM56-7B24E",
    "CFM56-7B24E/B1",
    "CFM56-7B26",
    "CFM56-7B26/2",
    "CFM56-7B26/3",
    "CFM56-7B26/3B1",
    "CFM56-7B26/3B2",
    "CFM56-7B26/3B2F",
    "CFM56-7B26/3F",
    "CFM56-7B26/B1",
    "CFM56-7B26/B2",
    "CFM56-7B26E",
    "CFM56-7B26E/B1",
    "CFM56-7B26E/B2",
    "CFM56-7B26E/B2F",
    "CFM56-7B26E/F",
    "CFM56-7B27",
    "CFM56-7B27/2",
    "CFM56-7B27/3",
    "CFM56-7B27/3B1",
    "CFM56-7B27/3B1F",
    "CFM56-7B27/3B3",
    "CFM56-7B27/3F",
    "CFM56-7B27/B1",
    "CFM56-7B27/B3",
    "CFM56-7B27A",
    "CFM56-7B27A/3",
    "CFM56-7B27AE",
    "CFM56-7B27E",
    "CFM56-7B27E/B1",
    "CFM56-7B27E/B1F",
    "CFM56-7B27E/B3",
    "CFM56-7B27E/F",
    "CH",
    "CH-3E",
    "CH-19E",
    "CH-34A",
    "CH-34C",
    "CH-47D",
    "CH-54A",
    "CH-54B",
    "CH-300 Pacemaker",
    "CH-400 Skyrocket",
    "CH34A",
    "CH34C",
    "CH2000",
    "Challenger",
    "Champagne-90",
    "CHC-C2Y",
    "Chemical oxygen generator",
    "Chemical Oxygen Generators",
    "CIGPAK-80",
    "Circuit Breakers",
    "Cirrus",
    "CJ610-1",
    "CJ610-4",
    "CJ610-5",
    "CJ610-6",
    "CJ610-8",
    "CJ610-8A",
    "CJ610-9",
    "CJ805-3",
    "CJ805-3A",
    "CJ805-3B",
    "CJ805-23",
    "CJ805-23B",
    "CJ805-23C",
    "CL-44D4",
    "CL-44J",
    "CL-215-1A10",
    "CL-215-6B11 (CL-215T Variant)",
    "CL-215-6B11 (CL-415 Variant)",
    "CL-600-1A11 (CL-600)",
    "CL-600-2A12 (CL-601)",
    "CL-600-2B16 (604 Variant)",
    "CL-600-2B16 (CL-601-3A)",
    "CL-600-2B16 (CL-601-3R)",
    "CL-600-2B16 (CL-604)",
    "CL-600-2B19 (Challenger 850)",
    "CL-600-2B19 (Regional Jet Series 100)",
    "CL-600-2B19 (Regional Jet Series 440)",
    "CL-600-2C10 (Regional Jet Series 700)",
    "CL-600-2C10 (Regional Jet Series 701)",
    "CL-600-2C10 (Regional Jet Series 702)",
    "CL-600-2C11 (Regional Jet Series 550)",
    "CL-600-2D15 (Regional Jet Series 705)",
    "CL-600-2D24 (Regional Jet Series 900)",
    "CL-600-2E25 (Regional Jet Series 1000)",
    "Club Libelle 205",
    "CN-235",
    "CN-235-100",
    "CN-235-200",
    "CN-235-300",
    "Cockpit Voice Recorder",
    "Cockpit Voice Recorders",
    "Colonial C-1",
    "Colonial C-2",
    "Colt 56A",
    "Colt 69A",
    "Colt 77A",
    "Colt 90A",
    "Colt 105A",
    "Colt 120A",
    "Colt 160A",
    "Colt 180A",
    "Colt 210A",
    "Colt 240A",
    "Colt 260A",
    "Colt 300A",
    "Colt 315A",
    "Common Seats 170/260 H160",
    "Communication (COM) Unit",
    "Compressed Gas Cylinders",
    "Computer",
    "Cone and Seal Assemblies",
    "CONE-105",
    "Conversion Kit",
    "COOK",
    "Coupling Kit",
    "CP-55",
    "CP-65",
    "CR 289/3-110-F/1",
    "CR 289/3-110-F/11",
    "Crew Seats",
    "CS-2FM4",
    "CS-2FM5",
    "CS-2FM6",
    "CS-3FM4",
    "CS-3FR5",
    "CT7-2A",
    "CT7-2D",
    "CT7-2D1",
    "CT7-2E1",
    "CT7-2F1",
    "CT7-5A2",
    "CT7-5A3",
    "CT7-6",
    "CT7-6A",
    "CT7-7A",
    "CT7-7A1",
    "CT7-8",
    "CT7-8A",
    "CT7-8A1",
    "CT7-8E",
    "CT7-8F5",
    "CT7-9B",
    "CT7-9B1",
    "CT7-9B2",
    "CT7-9C",
    "CT7-9C3",
    "CT7-9D",
    "CT7-9D2",
    "CT58-100-2",
    "CT58-110-1",
    "CT58-110-2",
    "CT58-140-1",
    "CT58-140-2",
    "CT64-820-4",
    "CTS",
    "Cub E-2",
    "Cub F-2",
    "Cuervo-110",
    "Cyclone 702C9GC1",
    "Cyclone 702C9GC2",
    "Cyclone 702C9GC3",
    "Cyclone 702C9GC4",
    "Cyclone 702C9GC5",
    "Cyclone 702C9GC6",
    "Cyclone 704C9GC1",
    "Cyclone 704C9GC2",
    "Cyclone 704C9GC3",
    "Cyclone 704C9GC4",
    "Cyclone 704C9GC5",
    "Cyclone 728C9GC1",
    "Cyclone 728C9GC2",
    "Cyclone 728C9GC3",
    "Cyclone 728C9GC4",
    "Cyclone 728C9GC5",
    "Cyclone 728C9GC6",
    "Cyclone 736C9HD1",
    "Cyclone 736C9HD2",
    "Cyclone 736C9HD3",
    "Cyclone 736C9HD4",
    "Cyclone 737C9HD1",
    "Cyclone 737C9HD2",
    "Cyclone 737C9HD3",
    "Cyclone 737C9HD4",
    "Cyclone 740C9HD1",
    "Cyclone 740C9HD2",
    "Cyclone 977C9HD1",
    "Cyclone 977C9HD2",
    "Cyclone 977C9HD3",
    "Cyclone R-1820F-2A",
    "Cyclone R-1820F-3A",
    "Cyclone R-1820F-56",
    "Cyclone R-1820G-2",
    "Cyclone R-1820G-3",
    "Cyclone R-1820G-3B",
    "Cyclone R-1820G-5",
    "Cyclone R-1820G-5E",
    "Cylinder Assemblies",
    "D-1",
    "D-16",
    "D-16A",
    "D-1093",
    "D-Lok Hook Assembly",
    "D.H. 104 Dove Series 1A",
    "D.H. 104 Dove Series 2A",
    "D.H. 104 Dove Series 5A",
    "D.H. 104 Dove Series 5BA",
    "D.H. 104 Dove Series 6A",
    "D.H. 104 Dove Series 6BA",
    "D.H. 104 Dove Series 7A",
    "D.H. 104 Dove Series 7AXC",
    "D.H. 104 Dove Series 8A",
    "D.H. 104 Dove Series 8AXC",
    "D.H.82A Tiger Moth",
    "D.H.83 Fox Moth",
    "D.H.114 Heron Series 2A",
    "D.H.114 Heron Series 2DA",
    "D.H.114 Heron Series 2X",
    "D2A34C",
    "D2A36C31",
    "D2A36C33",
    "D2A36C45",
    "D2A37C",
    "D2AF34C",
    "D2AF36C48",
    "D3A32C77",
    "D3A32C79",
    "D3A32C88",
    "D3A32C90",
    "D3A32C408",
    "D3A32C409",
    "D3A32C411",
    "D3A32C425",
    "D3A32C426",
    "D3A34C401",
    "D3A34C402",
    "D3A34C403",
    "D3A34C404",
    "D3A34C420",
    "D3A34C443",
    "D3A34C444",
    "D3A34C447",
    "D3A36C410",
    "D3A36C430",
    "D3A36C435",
    "D3A36C436",
    "D3A36C445",
    "D3AF32C35",
    "D3AF32C80",
    "D3AF32C87",
    "D17A (Army UC-43F)",
    "D17R (Army UC-43A)",
    "D17S",
    "D18C",
    "D18C-T",
    "D18S",
    "D35",
    "D45 (Military T-34B)",
    "D50",
    "D50A",
    "D50B",
    "D50C",
    "D50E",
    "D50E-5990",
    "D55",
    "D55A",
    "D75N1 (Army PT-27)",
    "D95A",
    "DA 40",
    "DA 40 NG",
    "DA 40F",
    "DA 42",
    "DA 42 M-NG",
    "DA 42 NG",
    "DA 62",
    "DA20-A1",
    "DA20-C1",
    "DAISY",
    "Dart 506",
    "Dart 510",
    "Dart 511",
    "Dart 511-7E",
    "Dart 514",
    "Dart 514-7",
    "Dart 525",
    "Dart 525F",
    "Dart 526",
    "Dart 527",
    "Dart 528-7E",
    "Dart 528D-7E",
    "Dart 529-7E",
    "Dart 529-7H",
    "Dart 529-8E",
    "Dart 529-8H",
    "Dart 529-8X",
    "Dart 529-8Y",
    "Dart 529-8Z",
    "Dart 529D-7E",
    "Dart 529D-7H",
    "Dart 529D-8E",
    "Dart 529D-8H",
    "Dart 529D-8X",
    "Dart 529D-8Y",
    "Dart 529D-8Z",
    "Dart 530",
    "Dart 531",
    "Dart 532-2L",
    "Dart 532-7",
    "Dart 532-7L",
    "Dart 532-7N",
    "Dart 532-7P",
    "Dart 532-7R",
    "Dart 535-2",
    "Dart 535-7R",
    "Dart 542-4",
    "Dart 542-4K",
    "Dart 542-10",
    "Dart 542-10J",
    "Dart 542-10K",
    "Dart 551-7",
    "Dart 551-7R",
    "Dart 552-2",
    "Dart 552-7",
    "Dart 552-7R",
    "Dart T.51",
    "Dart T.51/17",
    "Dart T.51/17R",
    "DC-4",
    "DC-6",
    "DC-6A",
    "DC-6B",
    "DC-7",
    "DC-7B",
    "DC-7C",
    "DC-8-11",
    "DC-8-12",
    "DC-8-21",
    "DC-8-31",
    "DC-8-32",
    "DC-8-33",
    "DC-8-41",
    "DC-8-42",
    "DC-8-43",
    "DC-8-51",
    "DC-8-52",
    "DC-8-53",
    "DC-8-55",
    "DC-8-61",
    "DC-8-61F",
    "DC-8-62",
    "DC-8-62F",
    "DC-8-63",
    "DC-8-63F",
    "DC-8-71",
    "DC-8-71F",
    "DC-8-72",
    "DC-8-72F",
    "DC-8-73",
    "DC-8-73F",
    "DC-8F-54",
    "DC-8F-55",
    "DC-9-11",
    "DC-9-12",
    "DC-9-13",
    "DC-9-14",
    "DC-9-15",
    "DC-9-15F",
    "DC-9-21",
    "DC-9-31",
    "DC-9-32",
    "DC-9-32 (VC-9C)",
    "DC-9-32F",
    "DC-9-32F (C-9A)",
    "DC-9-32F (C-9B)",
    "DC-9-33F",
    "DC-9-34",
    "DC-9-34F",
    "DC-9-41",
    "DC-9-51",
    "DC-9-81 (MD-81)",
    "DC-9-82 (MD-82)",
    "DC-9-83 (MD-83)",
    "DC-9-87 (MD-87)",
    "DC-10 Series (all)",
    "DC-10-10",
    "DC-10-10F",
    "DC-10-15",
    "DC-10-30",
    "DC-10-30F (KC-10A KDC-10)",
    "DC-10-30F (KC-10A, KDC-10)",
    "DC-10-30F (KC-10A; KDC-10)",
    "DC-10-40",
    "DC-10-40F",
    "DC-65",
    "DC3-G102",
    "DC3-G102A",
    "DC3-G103A",
    "DC3-G202A",
    "DC3A-S1C3G",
    "DC3A-S1CG",
    "DC3A-S4C4G",
    "DC3A-SC3G",
    "DC3A-SCG",
    "DC3C-R-1830-90C",
    "DC3C-S1C3G",
    "DC3C-S4C4G",
    "DC3C-SC3G",
    "DC3D-R-1830-90C",
    "DCO-65",
    "DG-100",
    "DG-200",
    "DG-300",
    "DG-400",
    "DG-500 Elan Orion",
    "DG-500 Elan Trainer",
    "DG-500/20 Elan",
    "DG-500/22 Elan",
    "DG-500M",
    "DG-500MB",
    "DG-800A",
    "DG-800B",
    "DG-808C",
    "DG-1000M",
    "DG-1000S",
    "DG-1000T",
    "DH 82A",
    "DH.125 Series 1A",
    "DH.125 Series 1A-522",
    "DH.125 Series 1A/R-522",
    "DH.125 Series 1A/S-522",
    "DH.125 Series 3A",
    "DH.125 Series 3A/R",
    "DH.125 Series 3A/RA",
    "DH.125 Series 400A",
    "DH.C1 Chipmunk 21",
    "DH.C1 Chipmunk 22",
    "DH.C1 Chipmunk 22A",
    "DHC-1 Chipmunk Mk 21",
    "DHC-1 Chipmunk Mk 22",
    "DHC-1 Chipmunk Mk 22A",
    "DHC-2 Mk.I",
    "DHC-2 Mk.II",
    "DHC-2 Mk.III",
    "DHC-3",
    "DHC-4",
    "DHC-4A",
    "DHC-6-1",
    "DHC-6-100",
    "DHC-6-200",
    "DHC-6-300",
    "DHC-6-400",
    "DHC-7-1",
    "DHC-7-100",
    "DHC-7-101",
    "DHC-7-102",
    "DHC-7-103",
    "DHC-8-101",
    "DHC-8-102",
    "DHC-8-103",
    "DHC-8-106",
    "DHC-8-201",
    "DHC-8-202",
    "DHC-8-301",
    "DHC-8-311",
    "DHC-8-315",
    "DHC-8-400",
    "DHC-8-401",
    "DHC-8-402",
    "DHC-C2Y",
    "DHC-E2Y",
    "Diamant 16.5",
    "Diaphragm fuel pumps",
    "Diaphragm Type Fuel Pumps",
    "Digital Flight Data Recorder",
    "Discharge Cartridges",
    "Discus 2cT",
    "Discus a",
    "Discus b",
    "Discus-2a",
    "Discus-2b",
    "Discus-2c",
    "Distance Measuring Equipment",
    "Do 27 Q-6",
    "Do 28 A-1",
    "Do 28 B-1",
    "Do 28 D",
    "Do 28 D-1",
    "Donald-97",
    "Dornier 228-100",
    "Dornier 228-101",
    "Dornier 228-200",
    "Dornier 228-201",
    "Dornier 228-202",
    "Dornier 228-212",
    "Double Row Cyclone 739C18BA3",
    "Double Row Cyclone 745C18BA3",
    "Double Row Cyclone 745C18BA4",
    "Double Row Cyclone 749C18BD1",
    "Double Row Cyclone 749C18BD3",
    "Double Row Cyclone 956C18CA1",
    "Double Row Cyclone 975C18CB1",
    "Double Row Turbo Cyclone 972TC18DA1 (R-3350-34, -91)",
    "Double Row Turbo Cyclone 972TC18DA2",
    "Double Row Turbo Cyclone 972TC18DA3",
    "Double Row Turbo Cyclone 972TC18DA4",
    "Double Row Turbo Cyclone 981TC18EA1",
    "Double Row Turbo Cyclone 988TC18EA1",
    "Double Row Turbo Cyclone 988TC18EA2",
    "Double Row Turbo Cyclone 988TC18EA3",
    "Double Row Turbo Cyclone 988TC18EA4",
    "Double Row Turbo Cyclone 988TC18EA5",
    "Double Row Turbo Cyclone 988TC18EA6",
    "Double Wasp CA3",
    "Double Wasp CA5",
    "Double Wasp CA15",
    "Double Wasp CA17",
    "Double Wasp CA18",
    "Double Wasp CA19",
    "Double Wasp CB3",
    "Double Wasp CB4",
    "Double Wasp CB16",
    "Double Wasp CB17",
    "Double Wasp R2800-97",
    "Drinks Can",
    "Dry Air Pumps",
    "Duo Discus T",
    "Duo-Discus",
    "DV 20 Katana",
    "E Pacemaker",
    "E2A34C",
    "E2A37C",
    "E4",
    "E4P",
    "E17B (Army UC-43D)",
    "E17L",
    "E18S",
    "E18S-9700",
    "E33",
    "E33A",
    "E33C",
    "E35",
    "E50",
    "E55",
    "E55A",
    "E75 (Army PT-13D, Navy N2S-5, PT-13D/N2S-5)",
    "E75 (Army PT-13D; Navy N2S-5; PT-13D/N2S-5)",
    "E75N1 (Army PT-13D, Navy N2S-5, PT-13D/N2S-5)",
    "E75N1 (Army PT-13D; Navy N2S-5; PT-13D/N2S-5)",
    "E90",
    "E95",
    "E165-2",
    "E165-3",
    "E165-4",
    "E185-1",
    "E185-2",
    "E185-3",
    "E185-5",
    "E185-8",
    "E185-9",
    "E185-10",
    "E185-11",
    "E225-2",
    "E225-4",
    "E225-8",
    "E225-9",
    "E310H",
    "E310J",
    "E1000",
    "EA 300/LC",
    "EA 400-500",
    "EA-300",
    "EA-300/200",
    "EA-300L",
    "EA-300S",
    "EA-400",
    "EA500",
    "EAGLE DW-1",
    "Eagle-110",
    "EC 155B",
    "EC-130Q",
    "EC120B",
    "EC130B4",
    "EC130T2",
    "EC135P1",
    "EC135P2",
    "EC135P2+",
    "EC135P3",
    "EC135T1",
    "EC135T2",
    "EC135T2+",
    "EC135T2+/EC635T2+",
    "EC135T3",
    "EC155B1",
    "EC225LP",
    "EC635T2+",
    "EFD500 Multi-Function Display",
    "EFD1000 Emergency Backup Display",
    "EFD1000 Multi-Function Display",
    "EFD1000 Primary Flight Display",
    "EH-60A",
    "EHC-A3MVF",
    "EHC-A3VF",
    "EHC-C3Y",
    "EHC-G3YF-1",
    "EHC-G3YF-2",
    "EHC-L3Y",
    "EHC-L3YF-1",
    "Electra 10-A (Army UC-36A, Navy R-20, and XR-20-1)",
    "Electra 10-B (Navy R-30-1)",
    "Electra 10-E",
    "Electric Hoists",
    "Electronic Flight Instrument Systems",
    "EMB-110P1",
    "EMB-110P2",
    "EMB-120",
    "EMB-120ER",
    "EMB-120FC",
    "EMB-120QC",
    "EMB-120RT",
    "EMB-135",
    "EMB-135BJ",
    "EMB-135BJ (Legacy 600)",
    "EMB-135BJ (Legacy 650)",
    "EMB-135ER",
    "EMB-135KE",
    "EMB-135KL",
    "EMB-135LR",
    "EMB-145",
    "EMB-145EP",
    "EMB-145ER",
    "EMB-145LR",
    "EMB-145MP",
    "EMB-145MR",
    "EMB-145XR",
    "EMB-500",
    "EMB-505",
    "EMB-545",
    "EMB-550",
    "Emergency Descent Devices",
    "Emergency Evacuation Slide/Raft System",
    "Emergency Evacuation Slide/Rafts",
    "Emergency Evacuation Slides",
    "Emergency Locator Transmitter",
    "Emergency Locator Transmitter Batteries",
    "Emergency Locator Transmitters",
    "Emergency Locator Transmitters (ELTs)",
    "Emergency Power Supply",
    "Engine Driven Pumps",
    "Engine Gage Units",
    "ERJ 170-100 LR",
    "ERJ 170-100 SE",
    "ERJ 170-100 STD",
    "ERJ 170-100 SU",
    "ERJ 170-200 LL",
    "ERJ 170-200 LR",
    "ERJ 170-200 STD",
    "ERJ 170-200 SU",
    "ERJ 190-100 ECJ",
    "ERJ 190-100 IGW",
    "ERJ 190-100 LR",
    "ERJ 190-100 STD",
    "ERJ 190-200 IGW",
    "ERJ 190-200 LR",
    "ERJ 190-200 STD",
    "ERJ 190-300",
    "ERJ 190-400",
    "ET-90",
    "Evacuation Slides",
    "Evacuation systems",
    "Evolution Flight Display (EFD) EFD1000",
    "F Skyrocket",
    "F-1",
    "F-1A",
    "F-5",
    "F-18",
    "F-27",
    "F-27A",
    "F-27B",
    "F-27F",
    "F-27G",
    "F-27J",
    "F-27M",
    "F-28",
    "F-28A",
    "F-28C",
    "F-28C-2",
    "F-28C-2R",
    "F-28F",
    "F-28F-R",
    "F-35 R4TS",
    "F-401",
    "F.28 Mark 0070",
    "F.28 Mark 0100",
    "F.28 Mark 1000",
    "F.28 Mark 2000",
    "F.28 Mark 3000",
    "F.28 Mark 4000",
    "F.260",
    "F.260B",
    "F.260C",
    "F.260D",
    "F.260E",
    "F.260F",
    "F2A34C",
    "F12A-3",
    "F12A-5",
    "F17D (UC-43C)",
    "F19",
    "F21",
    "F21A",
    "F21B",
    "F22",
    "F22A",
    "F22B",
    "F22C",
    "F22R",
    "F27 Mark 050",
    "F27 Mark 100",
    "F27 Mark 200",
    "F27 Mark 300",
    "F27 Mark 400",
    "F27 Mark 500",
    "F27 Mark 600",
    "F27 Mark 700",
    "F28 Mark 0070",
    "F28 Mark 0100",
    "F28 Mark 1000",
    "F28 Mark 2000",
    "F28 Mark 3000",
    "F28 Mark 4000",
    "F33",
    "F33A",
    "F33C",
    "F35",
    "F50",
    "F90",
    "F117-PW-100",
    "F150F",
    "F150G",
    "F150H",
    "F150J",
    "F150K",
    "F150L",
    "F150M",
    "F152",
    "F172D",
    "F172E",
    "F172F",
    "F172G",
    "F172H",
    "F172K",
    "F172L",
    "F172M",
    "F172N",
    "F172P",
    "F177RG",
    "F182P",
    "F182Q",
    "F200",
    "F200-H",
    "F220",
    "F337E",
    "F337F",
    "F337G",
    "F337H",
    "F406",
    "FA-200",
    "FA-200-160",
    "FA-200-180",
    "FA-200-180AO",
    "FA-III",
    "FA150K",
    "FA150L",
    "FA150M",
    "FA152",
    "Falcon 7X",
    "Falcon 10",
    "Falcon 900EX",
    "FALCON 2000",
    "FALCON 2000EX",
    "Falcon II",
    "Fan Jet Falcon",
    "Fan Jet Falcon Series C",
    "Fan Jet Falcon Series D",
    "Fan Jet Falcon Series E",
    "Fan Jet Falcon Series F",
    "Fan Jet Falcon Series G",
    "Fantasy Eight",
    "Fantasy Eight-90",
    "Fantasy Nine-122",
    "Fantasy Seven",
    "Fantasy Seven-65",
    "Fantasy Six",
    "Fast Kat I (U.S. Army OH-13S)",
    "FBA Centennial 100",
    "FBA-2C",
    "FBA-2C1",
    "FBA-2C2",
    "FBA-2C3",
    "FBA-2C4",
    "FDSA-6500",
    "Female ACME threaded hose connectors",
    "FH 76-1",
    "FH-227",
    "FH-227B",
    "FH-227C",
    "FH-227D",
    "FH-227E",
    "FH-1100",
    "FH.146",
    "Fiberglass Fabric",
    "Fire Detectors",
    "Fire Extinguisher",
    "Fire extinguisher discharge outlets",
    "Fire Extinguishers",
    "Fire Extinguishing System",
    "Firefly 5",
    "Firefly 6",
    "Firefly 6B",
    "Firefly 6B-15",
    "Firefly 7",
    "Firefly 7-15",
    "Firefly 7B",
    "Firefly 7B-15",
    "Firefly 8",
    "Firefly 8 Cube",
    "Firefly 8-24",
    "Firefly 8B",
    "Firefly 8B Sphere",
    "Firefly 8B-15",
    "Firefly 9",
    "Firefly 9B-15",
    "Firefly 10",
    "Firefly 11",
    "Firefly 11B",
    "Firefly 12B",
    "Firefly B7",
    "Firefly Boot",
    "Firefly Bottle",
    "Firefly C7",
    "Firefly C7B",
    "Firefly C8",
    "Firefly JC-9B",
    "Firefly TV-180",
    "Fixed-Pitch Metal Propellers",
    "FJ44-1A",
    "FJ44-2A",
    "FJ44-2C",
    "FJ44-3A",
    "FJ44-3A-24",
    "Flame-90",
    "Flight Data Recorders",
    "Flight Director and Horizontal Situation Indicators",
    "Flight Director Systems",
    "Flight management computer navigation modules (FMC2 NAVM)",
    "Flight Management Computers",
    "Flight Management System",
    "Flight Management Systems",
    "Flightcrew oxygen mask regulators",
    "flightcrew oxygen mask regulators",
    "Floatation Devices",
    "Floats",
    "Fluorescent Lighting",
    "Fluorescent lighting systems",
    "Flyweights",
    "FN-333",
    "FP172D",
    "FR172E",
    "FR172F",
    "FR172G",
    "FR172H",
    "FR172J",
    "FR172K",
    "FR182",
    "FRA150L",
    "FRA150M",
    "FSO-470-A",
    "FT337E",
    "FT337F",
    "FT337GP",
    "FT337HP",
    "FU24-954",
    "FU24A-954",
    "Fuel Cylinders",
    "Fuel Flow Transducers",
    "Fuel Flow Transmitter",
    "Fuel hoses",
    "Fuel Injection Systems",
    "Fuel Injection Units",
    "Fuel Injectors",
    "Fuel Pumps",
    "Funk C",
    "Fusible plugs installed on emergency evacuation equipment",
    "G 103 C Twin III SL",
    "G-21",
    "G-21A (Army OA-9, Navy JRF-1, -2, -3, -4, -5, and -6B)",
    "G-21C",
    "G-21D",
    "G-21E",
    "G-21G",
    "G-44",
    "G-44A",
    "G-73",
    "G-159",
    "G-164",
    "G-164A",
    "G-164B",
    "G-164B (with 73 inch wing gap)",
    "G-164B-15T",
    "G-164B-20T",
    "G-164B-34T",
    "G-164C",
    "G-164D",
    "G-164D (with 73 inch wing gap)",
    "G-1159",
    "G-1159A",
    "G-1159B",
    "G-IV",
    "G17S",
    "G18S",
    "G33",
    "G35",
    "G36",
    "G50",
    "G58",
    "G102 ASTIR CS",
    "G102 CLUB ASTIR III",
    "G102 CLUB ASTIR IIIb",
    "G102 Standard Astir III",
    "G103 Twin Astir",
    "G103 Twin II",
    "G103A Twin II Acro",
    "G103C Twin III Acro",
    "G115",
    "G115A",
    "G115B",
    "G115C",
    "G115C2",
    "G115D",
    "G115D2",
    "G115EG",
    "G120A",
    "GA-7",
    "GA8",
    "GA8-TC320",
    "GA200",
    "GA200C",
    "GAC-K Airship (Navy ZNPK)",
    "Galaxy",
    "Galaxy 6",
    "Galaxy 7B",
    "Galaxy 8B",
    "Galaxy 9B",
    "Galaxy 10",
    "Galaxy 11",
    "Galaxy 11B",
    "Galaxy 77",
    "Galaxy-7",
    "Galaxy-8",
    "Galaxy-9",
    "Galley Water Heaters",
    "GBN-41-1000",
    "GC-1A",
    "GC-1B",
    "GE Passport 20-17BB1A",
    "GE Passport 20-18BB1A",
    "GE Passport 20-19BB1A",
    "GE90-76B",
    "GE90-77B",
    "GE90-85B",
    "GE90-90B",
    "GE90-92B",
    "GE90-94B",
    "GE90-110B1",
    "GE90-113B",
    "GE90-115B",
    "GEM Mk. 530",
    "GEnx-1B54",
    "GEnx-1B54/P1",
    "GEnx-1B54/P2",
    "GEnx-1B58",
    "GEnx-1B58/P1",
    "GEnx-1B58/P2",
    "GEnx-1B64",
    "GEnx-1B64/P1",
    "GEnx-1B64/P2",
    "GEnx-1B67",
    "GEnx-1B67/P1",
    "GEnx-1B67/P2",
    "GEnx-1B70",
    "GEnx-1B70/72/P1",
    "GEnx-1B70/72/P2",
    "GEnx-1B70/75/P1",
    "GEnx-1B70/75/P2",
    "GEnx-1B70/P1",
    "GEnx-1B70/P2",
    "GEnx-1B70C/P1",
    "GEnx-1B70C/P2",
    "GEnx-1B74/75/P1",
    "GEnx-1B74/75/P2",
    "GEnx-1B75/P1",
    "GEnx-1B75/P2",
    "GEnx-1B76/P2",
    "Genx-1B76A/P2",
    "GEnx-1B78/P2",
    "GEnx-2B67",
    "GEnx-2B67/P",
    "GEnx-2B67B",
    "GEOSPHERE",
    "GFC 500",
    "GIO-470-A",
    "Gipsy Queen 30 MK.2",
    "Gipsy Queen 70 MK2",
    "Gipsy Queen 70 MK3",
    "Gipsy Queen 70-4",
    "GIV-X",
    "Glasflugel 304 C",
    "Glasflugel 304 CZ",
    "Glasflugel 304 CZ-17",
    "Glider Tow Couplings",
    "Global Positioning System/Satellite Based Augmentation System (GPS/SBAS) receivers",
    "Global positioning systems (GPS)",
    "GMC1102-02",
    "GMN-00962 GTS 825",
    "GO-145-C1",
    "GO-145-C2",
    "GO-145-C3",
    "GO-300-A",
    "GO-300-B",
    "GO-300-C",
    "GO-300-D",
    "GO-300-E",
    "GO-300-F",
    "GO-435",
    "GO-435-C2",
    "GO-435-C2 (O-435-17)",
    "GO-435-C2A",
    "GO-435-C2A2",
    "GO-435-C2B",
    "GO-435-C2B1",
    "GO-435-C2B2",
    "GO-435-C2C",
    "GO-435-C2D",
    "GO-435-C2E",
    "GO-435-D1",
    "GO-480",
    "GO-480-A1A",
    "GO-480-B",
    "GO-480-B1",
    "GO-480-B1A6",
    "GO-480-B1B",
    "GO-480-B1C",
    "GO-480-B1D",
    "GO-480-C1B6",
    "GO-480-C1D6",
    "GO-480-C2C6",
    "GO-480-C2D6",
    "GO-480-C2E6",
    "GO-480-C3A6",
    "GO-480-D1A",
    "GO-480-E1A6",
    "GO-480-F1A6",
    "GO-480-F2A6",
    "GO-480-F2D6",
    "GO-480-F3A6",
    "GO-480-F3B6",
    "GO-480-F4A6",
    "GO-480-F4B6",
    "GO-480-F6",
    "GO-480-G1A6",
    "GO-480-G1B6",
    "GO-480-G1D6",
    "GO-480-G1H6",
    "GO-480-G1J6",
    "GO-480-G2D6",
    "GO-480-G2F6",
    "Governors",
    "GP7270",
    "GP7272",
    "GP7277",
    "GPS",
    "GPS Navigation Units",
    "GPS-4000S",
    "GROB G 109",
    "GROB G 109B",
    "GROB G115",
    "GROB G115A",
    "GROB G115B",
    "GROB G115C",
    "GROB G115C2",
    "GROB G115D",
    "GROB G115D2",
    "GROB G115EG",
    "Ground Proximity Warning System",
    "GSO-435-B",
    "GSO-435-B2",
    "GSO-480-A1A6",
    "GSO-480-A1C6",
    "GSO-480-A2A6",
    "GSO-480-B1A6",
    "GSO-480-B1B3",
    "GSO-480-B1B6",
    "GSO-480-B1B6 (O-480-1)",
    "GSO-480-B1C6",
    "GSO-480-B1E6",
    "GSO-480-B1F6",
    "GSO-480-B1G6",
    "GSO-480-B1J6",
    "GSO-480-B2C6",
    "GSO-480-B2D6",
    "GSO-480-B2G6",
    "GSO-480-B2H6",
    "GSO-580",
    "GSO-580-B",
    "GSO-580-C",
    "GSO-580-D",
    "GTS 855",
    "GTS 8000",
    "GTSIO-520-C",
    "GTSIO-520-D",
    "GTSIO-520-E",
    "GTSIO-520-F",
    "GTSIO-520-H",
    "GTSIO-520-K",
    "GTSIO-520-L",
    "GTSIO-520-M",
    "GTSIO-520-N",
    "GTSIO-520-S",
    "GTX 33",
    "GTX 33D",
    "GTX 330",
    "GTX 330D",
    "Gulfstream 100",
    "Gulfstream 200",
    "Gulfstream G150",
    "Gulfstream G280",
    "GV",
    "GV-SP",
    "GVI",
    "GVII-G500",
    "GVII-G600",
    "H 301 Libelle",
    "H 301B Libelle",
    "H-19A",
    "H-19D",
    "H-19G",
    "H-21B",
    "H-31",
    "H-34A",
    "H-36 DIMONA",
    "H-42",
    "H-56",
    "H-65",
    "H-77",
    "H-250",
    "H-295 (USAF U10D)",
    "H-391 (USAF UL-24)",
    "H-391B",
    "H-395 (USAF L-28A or U-10B)",
    "H-395A",
    "H-700",
    "H-800",
    "H.P. 137 Jetstream Mk. 1",
    "H18",
    "H21A",
    "H35",
    "H50",
    "H75-100",
    "H75-200",
    "H80",
    "H80-100",
    "H80-200",
    "H85-100",
    "H85-200",
    "H90",
    "H101 Salto",
    "HA-12UF-1",
    "HA-12UF-3",
    "HA-12UO-1",
    "HA-12UO-2",
    "HA-420",
    "HA-A2MV20-1",
    "HA-A2V20-1",
    "HA-B3P",
    "HA-B3Z30-1",
    "Halon 1211",
    "hand-operated fire extinguishers 56411-001 (35H)",
    "hand-operated fire extinguishers 56412-001 (34H)",
    "hand-operated fire extinguishers 56412-002 (38H)",
    "Hardpoint Assemblies",
    "Harness/Container Assemblies",
    "Harvard AT-16 (Army)",
    "Hawker 750",
    "Hawker 800",
    "Hawker 800 (U-125A)",
    "Hawker 800XP",
    "Hawker 850XP",
    "Hawker 900XP",
    "Hawker 1000",
    "HB-23 2400",
    "HBV Diamant",
    "HC-12V20-3",
    "HC-12V20-7",
    "HC-12V20-8",
    "HC-12X20-1",
    "HC-12X20-2",
    "HC-12X20-3",
    "HC-12X20-4",
    "HC-12X20-5",
    "HC-12X20-6",
    "HC-12X20-7",
    "HC-12X20-8",
    "HC-12X20-9",
    "HC-13X20-5",
    "HC-13X20-6",
    "HC-13X20-8",
    "HC-42XF-2",
    "HC-52X20-1",
    "HC-82-XK-1",
    "HC-82-XK-2",
    "HC-82-XK-3",
    "HC-82VF-2",
    "HC-82VK-2",
    "HC-82VL-2",
    "HC-82X20-1",
    "HC-82X20-3",
    "HC-82X20-5",
    "HC-82XF-1",
    "HC-82XF-2",
    "HC-82XF-3",
    "HC-82XF-6",
    "HC-82XG-1",
    "HC-82XG-2",
    "HC-82XG-6",
    "HC-82XL-1",
    "HC-82XL-2",
    "HC-82XL-6",
    "HC-82XS-2",
    "HC-83V20-1",
    "HC-83V20-2",
    "HC-83VF-2",
    "HC-83VF-3",
    "HC-83X20-1",
    "HC-83X20-2",
    "HC-83XF-1",
    "HC-83XF-2",
    "HC-83XF-3",
    "HC-83XK-1",
    "HC-83XK-2",
    "HC-92W",
    "HC-92ZF-1",
    "HC-92ZF-2",
    "HC-92ZF-6",
    "HC-92ZK-1",
    "HC-92ZK-2",
    "HC-92ZK-6",
    "HC-92ZK-8",
    "HC-92ZL-2",
    "HC-93Z20-1",
    "HC-93Z20-2",
    "HC-93Z30-2",
    "HC-93ZF-2",
    "HC-102YF-1",
    "HC-A2MV",
    "HC-A2V",
    "HC-A2X20-1",
    "HC-A2X20-2",
    "HC-A2X20-4",
    "HC-A2X20-5",
    "HC-A2X20-G",
    "HC-A2XF-1",
    "HC-A2XF-2",
    "HC-A2XF-G",
    "HC-A2XK-1",
    "HC-A2XK-2",
    "HC-A2XK-G",
    "HC-A2XL-1",
    "HC-A2XL-2",
    "HC-A2XL-G",
    "HC-A3MV",
    "HC-A3V",
    "HC-A3X20-1",
    "HC-A3X20-2",
    "HC-A3X20-5",
    "HC-A3X21-2",
    "HC-A3XF-2",
    "HC-A3XF-4",
    "HC-A3XK-2",
    "HC-A3XK-4",
    "HC-A6A-3",
    "HC-B3M",
    "HC-B3MN-3",
    "HC-B3P20-4",
    "HC-B3P30-1",
    "HC-B3P30-2",
    "HC-B3R20-4",
    "HC-B3R30-1",
    "HC-B3R30-2",
    "HC-B3R30-4",
    "HC-B3T",
    "HC-B3W20",
    "HC-B3W30",
    "HC-B3WF",
    "HC-B3WN",
    "HC-B3Z20-1",
    "HC-B3Z20-2",
    "HC-B3Z22-7",
    "HC-B3Z30-2",
    "HC-B3ZF-2",
    "HC-B4MN-5",
    "HC-B4MP-3",
    "HC-B4TN-3",
    "HC-B4TN-5",
    "HC-B4TW-3",
    "HC-B4W40-2",
    "HC-B5MA-2",
    "HC-B5MA-3",
    "HC-B5MA-5",
    "HC-B5MP-3",
    "HC-B5MP-5",
    "HC-C2Y",
    "HC-C3Y",
    "HC-C4YF-1",
    "HC-C4YF-2",
    "HC-C4YN-2",
    "HC-C4YR-2",
    "HC-D2V20-3",
    "HC-D2V20-7",
    "HC-D2V20-8",
    "HC-D2X20-3",
    "HC-D2X20-7",
    "HC-D2X20-8",
    "HC-D3V20-6",
    "HC-D3X20-6",
    "HC-D4",
    "HC-D4N",
    "HC-E2Y",
    "HC-E3YR-1",
    "HC-E3YR-2",
    "HC-E3YR-7",
    "HC-E4",
    "HC-E4A",
    "HC-E5",
    "HC-F2YL-1",
    "HC-F2YL-2",
    "HC-F2YR-1",
    "HC-F2YR-2",
    "HC-F3YK-1",
    "HC-F3YK-2",
    "HC-F3YR-1",
    "HC-F3YR-2",
    "HC-F4YR-1",
    "HC-F4YR-2",
    "HC-G3YF-1",
    "HC-G3YF-2",
    "HC-G3YR-4",
    "HC-H3YF-1",
    "HC-H3YF-2",
    "HC-H3YF-3",
    "HC-H3YN-2",
    "HC-I2YF-1",
    "HC-I2YF-4",
    "HC-I2YR-1",
    "HC-I3Y1R-1",
    "HC-I3YR-1",
    "HC-I3YR-2",
    "HC-J2YF-1",
    "HC-J3Y",
    "HC-L2YF-2",
    "HC-L2YF-4",
    "HC-M2YR-1",
    "HC-M2YR-2",
    "HC-M3YR-1",
    "HD-E6C-3",
    "HE-1",
    "Head 90",
    "HF120",
    "HFB 320 Hansa",
    "HH-1K",
    "HH-3C",
    "HH-3E",
    "HH-34J",
    "HH-60L",
    "High Landing Gear Aft Crosstube Assembly",
    "High landing gear forward crosstube assembly",
    "High-pressure and medium-pressure hoses",
    "HIO 540-A1A",
    "HIO-360-A1A",
    "HIO-360-A1B",
    "HIO-360-B1A",
    "HIO-360-B1B",
    "HIO-360-C1A",
    "HIO-360-C1B",
    "HIO-360-D1A",
    "HIO-360-E1AD",
    "HIO-360-E1BD",
    "HIO-360-F1AD",
    "HIO-360-G1A",
    "HIO-540-A1A",
    "HK 36 R SUPER DIMONA",
    "HK 36 TC",
    "HK 36 TS",
    "HK 36 TTC",
    "HK 36 TTC-ECO",
    "HK 36 TTC-ECO (Restricted Category)",
    "HK 36 TTS",
    "HO 4/27 ( )",
    "HO 4/27 ( ) HM",
    "HO 27 HM",
    "HO-360-A1A",
    "HO-360-B1A",
    "HO-360-B1B",
    "HO-360-C1A",
    "HO-V 62",
    "HO-V 72",
    "HO-V 343",
    "Honeywell CAS67A ACAS II",
    "Hornet A",
    "Hornet A-1",
    "Hornet A-2",
    "Hornet A-3",
    "Hornet B",
    "Hornet B1",
    "Hornet B1-G",
    "Hornet S1B1",
    "Hornet S1B1-G",
    "Hornet S1E-G",
    "Hornet S1E2-G",
    "Hornet S1E3-G",
    "Hornet S2B1",
    "Hornet S2B1-G",
    "Hornet S2E-G",
    "Hornet S3B1",
    "Hornet SB-1",
    "Hose Assemblies",
    "Hoses",
    "HOUSE",
    "HP-C-130A",
    "HP-P2V-7",
    "HRS-1/CH-19",
    "HRS-3/H-19B",
    "HS 748 Series 2A",
    "HS 748 Series 2B",
    "HS.125 Series 1B",
    "HS.125 Series 1B-522",
    "HS.125 Series 1B/R-522",
    "HS.125 Series 1B/S-522",
    "HS.125 Series 3B",
    "HS.125 Series 3B/R",
    "HS.125 Series 3B/RA",
    "HS.125 Series 3B/RB",
    "HS.125 Series 3B/RC",
    "HS.125 Series 400A",
    "HS.125 Series 400B",
    "HS.125 Series 400B/1",
    "HS.125 Series 401B",
    "HS.125 Series 403A(C)",
    "HS.125 Series 403B",
    "HS.125 Series 600A",
    "HS.125 Series 600B",
    "HS.125 Series 600B/1",
    "HS.125 Series 600B/2",
    "HS.125 Series 600B/3",
    "HS.125 Series 700A",
    "HS.125 Series 700B",
    "HS.125 Series F3B",
    "HS.125 Series F3B/RA",
    "HS.125 Series F400B",
    "HS.125 Series F403B",
    "HS.125 Series F600B",
    "HSS-1/UH-34D",
    "HSS-1N/UH-34J",
    "HST-550",
    "HST-550A (USAF AU-24A)",
    "HT-295",
    "HU-16D",
    "HUS-1",
    "HW-75",
    "IB75A",
    "Ignition Switches",
    "IGO-480-A1A6",
    "IGO-480-A1B6",
    "IGO-540-A1A",
    "IGO-540-A1B",
    "IGO-540-A1C",
    "IGO-540-B1A",
    "IGO-540-B1B",
    "IGO-540-B1C",
    "IGSO-480-A1A6",
    "IGSO-480-A1A6 (O-480-3)",
    "IGSO-480-A1B6",
    "IGSO-480-A1C6",
    "IGSO-480-A1D6",
    "IGSO-480-A1E6",
    "IGSO-480-A1F3",
    "IGSO-480-A1F6",
    "IGSO-480-A1G6",
    "IGSO-540-A1A",
    "IGSO-540-A1B",
    "IGSO-540-A1C",
    "IGSO-540-A1D",
    "IGSO-540-A1E",
    "IGSO-540-A1F",
    "IGSO-540-A1H",
    "IGSO-540-B1A",
    "IGSO-540-B1C",
    "Inertial Navigation Systems",
    "Inertial Reference Units (IRU)",
    "INF",
    "Inflatable life preservers and individual flotation devices",
    "Inflatable Slide Regulator",
    "Integrated Flight Displays",
    "Intercommunication Sets",
    "IO-240-A",
    "IO-240-B",
    "IO-320-A1A",
    "IO-320-A2A",
    "IO-320-B1A",
    "IO-320-B1B",
    "IO-320-B1C",
    "IO-320-B1D",
    "IO-320-B1E",
    "IO-320-B2A",
    "IO-320-C1A",
    "IO-320-C1B",
    "IO-320-D1A",
    "IO-320-D1B",
    "IO-320-D1C",
    "IO-320-E1A",
    "IO-320-E1B",
    "IO-320-E2A",
    "IO-320-E2B",
    "IO-320-F1A",
    "IO-346-A",
    "IO-346-B",
    "IO-360",
    "IO-360-A",
    "IO-360-A1A",
    "IO-360-A1A1",
    "IO-360-A1A2",
    "IO-360-A1B",
    "IO-360-A1B6",
    "IO-360-A1B6D",
    "IO-360-A1C",
    "IO-360-A1D",
    "IO-360-A1D6",
    "IO-360-A1D6D",
    "IO-360-A2A",
    "IO-360-A2A1",
    "IO-360-A2A2",
    "IO-360-A2B",
    "IO-360-A2C",
    "IO-360-A3A1",
    "IO-360-A3A2",
    "IO-360-A3B6",
    "IO-360-A3B6D",
    "IO-360-A3D6D",
    "IO-360-A4A1",
    "IO-360-A4A2",
    "IO-360-A5A1",
    "IO-360-A5A2",
    "IO-360-A6A1",
    "IO-360-A6A2",
    "IO-360-AB",
    "IO-360-AF",
    "IO-360-B",
    "IO-360-B1A",
    "IO-360-B1A1",
    "IO-360-B1A2",
    "IO-360-B1B",
    "IO-360-B1C",
    "IO-360-B1D",
    "IO-360-B1E",
    "IO-360-B1F",
    "IO-360-B1F6",
    "IO-360-B1G6",
    "IO-360-B2A1",
    "IO-360-B2A2",
    "IO-360-B2E",
    "IO-360-B2F",
    "IO-360-B2F6",
    "IO-360-B3A1",
    "IO-360-B3A2",
    "IO-360-B4",
    "IO-360-B4A",
    "IO-360-B4A1",
    "IO-360-B4A2",
    "IO-360-B5A1",
    "IO-360-B5A2",
    "IO-360-B6A1",
    "IO-360-B6A2",
    "IO-360-C",
    "IO-360-C1A",
    "IO-360-C1A1",
    "IO-360-C1A2",
    "IO-360-C1B",
    "IO-360-C1C",
    "IO-360-C1C6",
    "IO-360-C1D6",
    "IO-360-C1E6",
    "IO-360-C1E6D",
    "IO-360-C1F",
    "IO-360-C1G6",
    "IO-360-C2A1",
    "IO-360-C2A2",
    "IO-360-C3A1",
    "IO-360-C3A2",
    "IO-360-C4A1",
    "IO-360-C4A2",
    "IO-360-C5A1",
    "IO-360-C5A2",
    "IO-360-C6A1",
    "IO-360-C6A2",
    "IO-360-CB",
    "IO-360-D",
    "IO-360-D1A",
    "IO-360-D1A1",
    "IO-360-D1A2",
    "IO-360-D2A1",
    "IO-360-D2A2",
    "IO-360-D3A1",
    "IO-360-D3A2",
    "IO-360-D4A1",
    "IO-360-D4A2",
    "IO-360-D5A1",
    "IO-360-D5A2",
    "IO-360-D6A1",
    "IO-360-D6A2",
    "IO-360-DB",
    "IO-360-E",
    "IO-360-E1A",
    "IO-360-E1A1",
    "IO-360-E1A2",
    "IO-360-E2A1",
    "IO-360-E2A2",
    "IO-360-E3A1",
    "IO-360-E3A2",
    "IO-360-E4A1",
    "IO-360-E4A2",
    "IO-360-E5A1",
    "IO-360-E5A2",
    "IO-360-E6A1",
    "IO-360-E6A2",
    "IO-360-ES",
    "IO-360-F1A",
    "IO-360-G",
    "IO-360-GB",
    "IO-360-H",
    "IO-360-HB",
    "IO-360-J",
    "IO-360-J1A6D",
    "IO-360-J1AD",
    "IO-360-JB",
    "IO-360-K",
    "IO-360-K2A",
    "IO-360-KB",
    "IO-360-L2A",
    "IO-360-M1A",
    "IO-360-M1B",
    "IO-390-A1A6",
    "IO-390-A1B6",
    "IO-390-A3A6",
    "IO-390-A3B6",
    "IO-470-A",
    "IO-470-C",
    "IO-470-D",
    "IO-470-E",
    "IO-470-F",
    "IO-470-G",
    "IO-470-H",
    "IO-470-J",
    "IO-470-K",
    "IO-470-L",
    "IO-470-LO",
    "IO-470-M",
    "IO-470-N",
    "IO-470-P",
    "IO-470-R",
    "IO-470-S",
    "IO-470-T",
    "IO-470-U",
    "IO-470-V",
    "IO-470-VO",
    "IO-520-A",
    "IO-520-B",
    "IO-520-BA",
    "IO-520-BB",
    "IO-520-C",
    "IO-520-CB",
    "IO-520-D",
    "IO-520-E",
    "IO-520-F",
    "IO-520-J",
    "IO-520-K",
    "IO-520-L",
    "IO-520-M",
    "IO-520-MB",
    "IO-520-N",
    "IO-520-NB",
    "IO-520-P",
    "IO-540-A1A5",
    "IO-540-AA1A5",
    "IO-540-AA1B5",
    "IO-540-AB1A5",
    "IO-540-AC1A5",
    "IO-540-AE1A5",
    "IO-540-AF1A5",
    "IO-540-AG1A5",
    "IO-540-B1A5",
    "IO-540-B1B5",
    "IO-540-B1C5",
    "IO-540-C1B5",
    "IO-540-C1C5",
    "IO-540-C2C",
    "IO-540-C4B5",
    "IO-540-C4B5D",
    "IO-540-C4C5",
    "IO-540-C4D5",
    "IO-540-C4D5D",
    "IO-540-D4A5",
    "IO-540-D4B5",
    "IO-540-D4C5",
    "IO-540-E1A5",
    "IO-540-E1B5",
    "IO-540-E1C5",
    "IO-540-G1A5",
    "IO-540-G1B5",
    "IO-540-G1C5",
    "IO-540-G1D5",
    "IO-540-G1E5",
    "IO-540-G1F5",
    "IO-540-J4A5",
    "IO-540-K1A5",
    "IO-540-K1A5D",
    "IO-540-K1B5",
    "IO-540-K1B5D",
    "IO-540-K1C5",
    "IO-540-K1D5",
    "IO-540-K1E5",
    "IO-540-K1E5D",
    "IO-540-K1F5",
    "IO-540-K1F5D",
    "IO-540-K1G5",
    "IO-540-K1G5D",
    "IO-540-K1H5",
    "IO-540-K1J5",
    "IO-540-K1J5D",
    "IO-540-K1K5",
    "IO-540-K2A5",
    "IO-540-L1A5",
    "IO-540-L1A5D",
    "IO-540-L1B5D",
    "IO-540-L1C5",
    "IO-540-M1A5",
    "IO-540-M1A5D",
    "IO-540-M1B5D",
    "IO-540-M1C5",
    "IO-540-M2A5D",
    "IO-540-MX1",
    "IO-540-N1A5",
    "IO-540-P1A5",
    "IO-540-R1A5",
    "IO-540-S1A5",
    "IO-540-T4A5D",
    "IO-540-T4B5",
    "IO-540-T4B5D",
    "IO-540-T4C5D",
    "IO-540-U1A5D",
    "IO-540-U1B5D",
    "IO-540-V4A5",
    "IO-540-V4A5D",
    "IO-540-W1A5",
    "IO-540-W1A5D",
    "IO-540-W3A5D",
    "IO-550-A",
    "IO-550-B",
    "IO-550-C",
    "IO-550-D",
    "IO-550-E",
    "IO-550-F",
    "IO-550-G",
    "IO-550-L",
    "IO-550-N",
    "IO-550-P",
    "IO-550-R",
    "IO-580-A1A",
    "IO-580-B1A",
    "IO-720-A1A",
    "IO-720-A1B",
    "IO-720-A1BD",
    "IO-720-B1A",
    "IO-720-B1B",
    "IO-720-B1BD",
    "IO-720-C1B",
    "IO-720-C1BD",
    "IO-720-D1B",
    "IO-720-D1BD",
    "IO-720-D1C",
    "IO-720-D1CD",
    "IOF-240-B",
    "IOF-550-B",
    "IOF-550-C",
    "IOF-550-D",
    "IOF-550-E",
    "IOF-550-F",
    "IOF-550-L",
    "IOF-550-N",
    "IOF-550-P",
    "IOF-550-R",
    "IS-28B2",
    "IS-29D2",
    "IVO-360-A1A",
    "IVO-540-A1A",
    "J-2",
    "J-2 Gyroplane",
    "J-3",
    "J3C-40",
    "J3C-50",
    "J3C-50S",
    "J3C-65 (Army L-4; L-4A; L-4B (Navy NE-1); L-4H; L-4J (Navy NE-2))",
    "J3C-65S",
    "J3F-50",
    "J3F-50S",
    "J3F-60",
    "J3F-60S",
    "J3F-65 (Army L-4D)",
    "J3F-65S",
    "J3L",
    "J3L-65 (Army L-4C)",
    "J3L-65S",
    "J3L-S",
    "J3P",
    "J4",
    "J4A",
    "J4A-S",
    "J4B",
    "J4E (Army L-4E)",
    "J4F",
    "J5A (Army L-4F)",
    "J5A-80",
    "J5B (Army L-4G)",
    "J5C",
    "J35",
    "J50",
    "J60-P-5B",
    "JANUS",
    "JDCC-90",
    "Jetstream 3201",
    "Jetstream Model 4101",
    "Jetstream Series 200",
    "Jetstream Series 3101",
    "JFTD12A-4A (T73P-1)",
    "JFTD12A-5A (T73-P-700)",
    "JRB-6",
    "JT3C-4",
    "JT3C-6",
    "JT3C-7",
    "JT3C-12",
    "JT3D-1",
    "JT3D-1-MC6",
    "JT3D-1-MC7",
    "JT3D-1A",
    "JT3D-1A-MC6",
    "JT3D-1A-MC7",
    "JT3D-3",
    "JT3D-3B",
    "JT3D-3C",
    "JT3D-5A",
    "JT3D-7",
    "JT3D-7A",
    "JT4A-3",
    "JT4A-5",
    "JT4A-9",
    "JT4A-10",
    "JT4A-11",
    "JT4A-12",
    "JT8D-1",
    "JT8D-1A",
    "JT8D-1B",
    "JT8D-5",
    "JT8D-7",
    "JT8D-7A",
    "JT8D-7B",
    "JT8D-9",
    "JT8D-9A",
    "JT8D-11",
    "JT8D-15",
    "JT8D-15A",
    "JT8D-17",
    "JT8D-17A",
    "JT8D-17AR",
    "JT8D-17R",
    "JT8D-209",
    "JT8D-217",
    "JT8D-217A",
    "JT8D-217C",
    "JT8D-219",
    "JT9D-3A",
    "JT9D-7",
    "JT9D-7A",
    "JT9D-7AH",
    "JT9D-7F",
    "JT9D-7H",
    "JT9D-7J",
    "JT9D-7Q",
    "JT9D-7Q3",
    "JT9D-7R4D",
    "JT9D-7R4D1",
    "JT9D-7R4E",
    "JT9D-7R4E1",
    "JT9D-7R4E4",
    "JT9D-7R4G2",
    "JT9D-7R4H1",
    "JT9D-20",
    "JT9D-20J",
    "JT9D-59A",
    "JT9D-70A",
    "JT12A-6",
    "JT12A-6A",
    "JT12A-8",
    "JT15D-1",
    "JT15D-1A",
    "JT15D-1B",
    "JT15D-4",
    "JT15D-4B",
    "JT15D-4C",
    "JT15D-4D",
    "JT15D-5",
    "JT15D-5A",
    "JT15D-5B",
    "JT15D-5F",
    "JT15D-5R",
    "K 8",
    "K 8 B",
    "K-190A",
    "K-225",
    "K-240",
    "K-300/1-Stu",
    "K-600",
    "K-630/1-Ri",
    "K-630/1-Stu",
    "K-780/2-Stu",
    "K-945/2-Ri",
    "K-945/2-Stu",
    "K-1000/3-Stu",
    "K-1200",
    "K-1260/3-Stu",
    "K-1680/4-Ri",
    "K-1680/4-Stu",
    "K7",
    "K35",
    "Ka 6",
    "Ka 6 B",
    "Ka 6 BR",
    "Ka 6 C",
    "Ka 6 CR",
    "Ka 6 CR-Pe",
    "KA 6 E",
    "Ka2B",
    "Kestrel",
    "KGS200 Mercury  wide area augmentation system (WAAS) global navigation satellite sensor units (GNSSU)",
    "Kinner R-5 Series 2",
    "Kinner R-55",
    "Kinner R-56",
    "Kiwi-90",
    "KNF",
    "Kodiak 100",
    "KR-03A Peregrine",
    "KT74 Mode S transponders",
    "Kub -ek burners",
    "KV107-II",
    "KV107-IIA",
    "L 13 SDM VIVAT",
    "L 13 SEH VIVAT",
    "L 23 Super - Blanik",
    "L 410 NG",
    "L 410 UVP-E20",
    "L 410 UVP-E20 CARGO",
    "L-5",
    "L-5B",
    "L-5C",
    "L-5D",
    "L-5E",
    "L-5E-1",
    "L-5G",
    "L-14",
    "L-420",
    "L-1011-385-1",
    "L-1011-385-1-14",
    "L-1011-385-1-15",
    "L-1011-385-3",
    "L-Spatz 55",
    "L/IO-470-A",
    "L/IO-520-P",
    "L33 SOLO",
    "LA-87 Spark Plugs",
    "Lake 250",
    "Lake LA-4",
    "Lake LA-4-200",
    "Lake LA-4A",
    "Lake LA-4P",
    "Lake Model 250",
    "Lap Belt Assemblies",
    "Lavatories equipped with paper or linen waste receptacles",
    "LB-30 (Army)",
    "LBL BOX",
    "LC40-550FG",
    "LC40-550G",
    "LC41-550FG",
    "LC42-550FG",
    "LCA",
    "LD-S",
    "LEAP-1A23",
    "LEAP-1A24",
    "LEAP-1A24E1",
    "LEAP-1A26",
    "LEAP-1A26CJ",
    "LEAP-1A26E1",
    "LEAP-1A29",
    "LEAP-1A29CJ",
    "LEAP-1A30",
    "LEAP-1A32",
    "LEAP-1A33",
    "LEAP-1A33B2",
    "LEAP-1A35A",
    "LEAP-1B21",
    "LEAP-1B23",
    "LEAP-1B25",
    "LEAP-1B27",
    "LEAP-1B28",
    "LEAP-1B28B1",
    "LEAP-1B28B2",
    "LEAP-1B28B2C",
    "LEAP-1B28B3",
    "LEAP-1B28BBJ1",
    "LEAP-1B28BBJ2",
    "LF507-1F",
    "LF507-1H",
    "LFA",
    "LHIO-360-C1A",
    "LHIO-360-C1B",
    "LHIO-360-F1AD",
    "Life jackets",
    "Life Jackets",
    "Life Preservers and Individual Flotation Devices",
    "Life Raft Systems",
    "Life Rafts",
    "Life Vests",
    "Liferafts",
    "Lightbulb-100",
    "LIO-320-B1A",
    "LIO-320-C1A",
    "LIO-360-C1E6",
    "LIO-360-M1A",
    "LIO-470-A",
    "LIO-520-P",
    "Lithium Sulfur Dioxide Batteries",
    "LO-360-A1G6D",
    "LO-360-A1H6",
    "LO-360-E1A6D",
    "LO-360-E1AD",
    "LO-360-E1BD",
    "LO-360-E2AD",
    "LO-360-E2BD",
    "LS 3-A",
    "LS 4",
    "LS 4a",
    "LS-3",
    "LS1-f",
    "LS6-c",
    "LTIO-540-F2BD",
    "LTIO-540-J2B",
    "LTIO-540-J2BD",
    "LTIO-540-K1AD",
    "LTIO-540-N2BD",
    "LTIO-540-R2AD",
    "LTIO-540-U2A",
    "LTIO-540-V2AD",
    "LTIO-540-W2A",
    "LTO-360-A1A6D",
    "LTO-360-E1A6D",
    "LTP 101-600",
    "LTP 101-600A",
    "LTP 101-600A-1A",
    "LTP 101-700A-1A",
    "LTS101-600A",
    "LTS101-600A-1",
    "LTS101-600A-2",
    "LTS101-600A-3",
    "LTS101-600A-3A",
    "LTS101-600B",
    "LTS101-600B-3",
    "LTS101-650A",
    "LTS101-650A-1",
    "LTS101-650A-2",
    "LTS101-650B-1",
    "LTS101-650B-1A",
    "LTS101-650C",
    "LTS101-650C-2",
    "LTS101-650C-3",
    "LTS101-650C-3A",
    "LTS101-700D-2",
    "LTS101-750A-1",
    "LTS101-750A-3",
    "LTS101-750B-1",
    "LTS101-750B-2",
    "LTS101-750C-1",
    "LTS101-850B-2",
    "LTSIO-360-E",
    "LTSIO-360-EB",
    "LTSIO-360-KB",
    "LTSIO-360-RB",
    "LTSIO-520-AE",
    "LZ N07-100",
    "M-4",
    "M-4-180C",
    "M-4-180S",
    "M-4-180T",
    "M-4-180V",
    "M-4-210",
    "M-4-210C",
    "M-4-210S",
    "M-4-210T",
    "M-4-220",
    "M-4-220C",
    "M-4-220S",
    "M-4-220T",
    "M-4C",
    "M-4S",
    "M-4T",
    "M-5-180C",
    "M-5-200",
    "M-5-210C",
    "M-5-210TC",
    "M-5-220C",
    "M-5-235C",
    "M-6-180",
    "M-6-235",
    "M-7-235",
    "M-7-235A",
    "M-7-235B",
    "M-7-235C",
    "M-7-260",
    "M-7-260C",
    "M-7-420A",
    "M-7-420AC",
    "M-8-235",
    "M-9-235",
    "M-18C",
    "M-18C 55",
    "M-18L",
    "M-18LA",
    "M-34",
    "M-42",
    "M-56",
    "M-56C",
    "M-62A",
    "M-62A-3",
    "M-62A-4",
    "M-62B",
    "M-62C",
    "M-65",
    "M-65C",
    "M-77",
    "M-77C",
    "M-90",
    "M-100 S",
    "M-105",
    "M-120",
    "M-130",
    "M-145",
    "M-160",
    "M-200",
    "M.S. 760 (Paris I)",
    "M.S. 760.A (Paris IA)",
    "M.S. 760B (Paris II)",
    "M10",
    "M19A",
    "M20",
    "M20A",
    "M20B",
    "M20C",
    "M20D",
    "M20E",
    "M20F",
    "M20G",
    "M20J",
    "M20K",
    "M20L",
    "M20M",
    "M20R",
    "M20S",
    "M20TN",
    "M20V",
    "M22",
    "M35",
    "M337B",
    "M601D-11",
    "M601E-11",
    "M601E-11A",
    "M601E-11AS",
    "M601E-11S",
    "M601F",
    "MAC-125C",
    "MAC-145",
    "MAC-145A",
    "MAC-145B",
    "Magnetos",
    "Magnum IX",
    "Main Brake Assemblies",
    "Main Brake Assembly",
    "Main Wheel Assemblies",
    "Makila 1A",
    "Makila 1A1",
    "Makila 1A2",
    "Makila 2A",
    "Makila 2A1",
    "Manufacturing Company Auxiliary Power Units",
    "Mark V",
    "Mask Presentation Units",
    "MBB-BK 117 A-1",
    "MBB-BK 117 A-3",
    "MBB-BK 117 A-4",
    "MBB-BK 117 B-1",
    "MBB-BK 117 B-2",
    "MBB-BK 117 C-1",
    "MBB-BK 117 C-2",
    "MBB-BK 117 D-2",
    "MBB-BK 117 D-3",
    "MC10 series crew oxygen mask regulators",
    "MD-10-10F",
    "MD-10-30F",
    "MD-11",
    "MD-11 Series (all)",
    "MD-11F",
    "MD-88",
    "MD-90-30",
    "MD3-160",
    "MD900",
    "Mercure 100C",
    "MG-1000",
    "Mickey-90",
    "Mickeyhat-97",
    "Military C-131B",
    "Military C-131D",
    "Military C-131E",
    "Military C-131F/R4Y-1",
    "Military Cyclone R-1300 Series",
    "Military Cyclone R-1820 Series",
    "Military O-435-2-M1",
    "Military R-985 Series",
    "Military R-1340 Series",
    "Military R-1830 Series",
    "Military R-2000 Series",
    "Military R-2800 Series",
    "Mini-Nimbus B",
    "Mini-Nimbus HS-7",
    "Mode S Transponder",
    "Model 358 Torso Restraint System",
    "Monitor-80",
    "Monitor-105",
    "Mosquito",
    "MR. POTATO HEAD-90",
    "MS 880B",
    "MS 885",
    "MS 892A-150",
    "MS 892E-150",
    "MS 893A",
    "MS 893E",
    "MS 894A",
    "MS 894E",
    "MT( )( )( )-1( )",
    "MT( )( )( )-2( )",
    "MT( )( )( )-3( )",
    "MT( )( )( )-4( )",
    "MT( )( )( )-6( )",
    "MT-7-235",
    "MT-7-260",
    "MT-7-420",
    "MTOsport 2017",
    "MTV-1-( )",
    "MTV-3",
    "MTV-5-1-( )",
    "MTV-5-1-B",
    "MTV-5-1-D",
    "MTV-5-1-E",
    "MTV-6-( )",
    "MTV-7-( )",
    "MTV-9-( )",
    "MTV-9-AA",
    "MTV-9-B",
    "MTV-9-D",
    "MTV-9-E",
    "MTV-9-K",
    "MTV-11-C",
    "MTV-11-D",
    "MTV-11-F",
    "MTV-12-( )",
    "MTV-12-B",
    "MTV-12-C",
    "MTV-12-D",
    "MTV-14-B",
    "MTV-14-D",
    "MTV-15-( )",
    "MTV-16-( )",
    "MTV-18-( )",
    "MTV-18-B",
    "MTV-18-C",
    "MTV-18-D",
    "MTV-21",
    "MTV-27-( )",
    "MU-2B",
    "MU-2B-10",
    "MU-2B-15",
    "MU-2B-20",
    "MU-2B-25",
    "MU-2B-26",
    "MU-2B-26A",
    "MU-2B-30",
    "MU-2B-35",
    "MU-2B-36",
    "MU-2B-36A",
    "MU-2B-40",
    "MU-2B-60",
    "MU-300",
    "MU-300-10",
    "MX-7-160",
    "MX-7-160C",
    "MX-7-180",
    "MX-7-180A",
    "MX-7-180AC",
    "MX-7-180B",
    "MX-7-180C",
    "MX-7-235",
    "MX-7-420",
    "MXT-7-160",
    "MXT-7-180",
    "MXT-7-180A",
    "MXT-7-420",
    "Mystere-Falcon 20 - C5",
    "Mystere-Falcon 20 - D5",
    "Mystere-Falcon 20 - E5",
    "Mystere-Falcon 20 - F5",
    "Mystere-Falcon 50",
    "Mystere-Falcon 200",
    "Mystere-Falcon 900",
    "N-31",
    "N-42",
    "N-56",
    "N-65",
    "N-70",
    "N-77",
    "N-90",
    "N-90S",
    "N-100",
    "N-105",
    "N-120",
    "N-133",
    "N-145",
    "N-160",
    "N-180",
    "N-210",
    "N-250",
    "N-300",
    "N-355",
    "N-425",
    "N2T-1",
    "N3N-3",
    "N22B",
    "N22S",
    "N24A",
    "N35",
    "NA-260",
    "NA-265",
    "NA-265-20",
    "NA-265-30",
    "NA-265-40",
    "NA-265-60",
    "NA-265-65",
    "NA-265-70",
    "NA-265-80",
    "Navigation computers",
    "Navigation receivers",
    "Navigation Receivers",
    "Navigation System",
    "Navigation Systems",
    "Navion (Army L-17A)",
    "Navion A (Army L-17B and L-17C)",
    "Navion B",
    "Navion D",
    "Navion E",
    "Navion F",
    "Navion G",
    "Navion H",
    "Navy PV-1",
    "Navy R6D-1",
    "Navy R6D-1Z",
    "Navy SNB-1",
    "Newport",
    "Nickel Cadmium Battery",
    "Nimbus-2",
    "Nimbus-2B",
    "NL-280/STU",
    "NL-380/STU",
    "NL-510/STU",
    "NL-640/STU",
    "NL-840/STU",
    "NL-1000/STU",
    "Nord 262A Series",
    "O-31",
    "O-42",
    "O-47B",
    "O-56",
    "O-65",
    "O-70",
    "O-77",
    "O-84",
    "O-90",
    "O-105",
    "O-145-A1",
    "O-145-A2",
    "O-145-B1",
    "O-145-B2",
    "O-145-B3",
    "O-145-C1",
    "O-145-C2",
    "O-160",
    "O-200-A",
    "O-200-B",
    "O-200-C",
    "O-200-D",
    "O-200-X",
    "O-233-A1",
    "O-235",
    "O-235-A",
    "O-235-AP",
    "O-235-B",
    "O-235-BP",
    "O-235-BP-C",
    "O-235-C",
    "O-235-C1",
    "O-235-C1A",
    "O-235-C1B",
    "O-235-C1C",
    "O-235-C2A",
    "O-235-C2B",
    "O-235-C2C",
    "O-235-E1",
    "O-235-E1B",
    "O-235-E2A",
    "O-235-E2B",
    "O-235-F1",
    "O-235-F1B",
    "O-235-F2A",
    "O-235-F2B",
    "O-235-G1",
    "O-235-G1B",
    "O-235-G2A",
    "O-235-G2B",
    "O-235-H2C",
    "O-235-J2A",
    "O-235-J2B",
    "O-235-K2A",
    "O-235-K2B",
    "O-235-K2C",
    "O-235-L2A",
    "O-235-L2C",
    "O-235-M1",
    "O-235-M2C",
    "O-235-M3C",
    "O-235-N2A",
    "O-235-N2C",
    "O-235-P1",
    "O-235-P2A",
    "O-235-P2C",
    "O-235-P3C",
    "O-290",
    "O-290-A",
    "O-290-AP",
    "O-290-B",
    "O-290-C",
    "O-290-CP",
    "O-290-D",
    "O-290-D2",
    "O-290-D2A",
    "O-290-D2B",
    "O-290-D2C",
    "O-300-A",
    "O-300-B",
    "O-300-C",
    "O-300-D",
    "O-300-E",
    "O-320-A1A",
    "O-320-A1B",
    "O-320-A2A",
    "O-320-A2B",
    "O-320-A2C",
    "O-320-A2D",
    "O-320-A3A",
    "O-320-A3B",
    "O-320-A3C",
    "O-320-B1A",
    "O-320-B1B",
    "O-320-B2A",
    "O-320-B2B",
    "O-320-B2C",
    "O-320-B2D",
    "O-320-B2E",
    "O-320-B3A",
    "O-320-B3B",
    "O-320-B3C",
    "O-320-C1A",
    "O-320-C1B",
    "O-320-C2A",
    "O-320-C2B",
    "O-320-C2C",
    "O-320-C3A",
    "O-320-C3B",
    "O-320-C3C",
    "O-320-D1A",
    "O-320-D1B",
    "O-320-D1C",
    "O-320-D1D",
    "O-320-D1F",
    "O-320-D2A",
    "O-320-D2B",
    "O-320-D2C",
    "O-320-D2F",
    "O-320-D2G",
    "O-320-D2H",
    "O-320-D2J",
    "O-320-D3G",
    "O-320-E1A",
    "O-320-E1B",
    "O-320-E1C",
    "O-320-E1F",
    "O-320-E1J",
    "O-320-E2A",
    "O-320-E2B",
    "O-320-E2C",
    "O-320-E2D",
    "O-320-E2F",
    "O-320-E2G",
    "O-320-E2H",
    "O-320-E3D",
    "O-320-E3H",
    "O-320-H1AD",
    "O-320-H1BD",
    "O-320-H2AD",
    "O-320-H2BD",
    "O-320-H3AD",
    "O-320-H3BD",
    "O-340-A1A",
    "O-340-A2A",
    "O-340-B1A",
    "O-350",
    "O-350-A",
    "O-360",
    "O-360-A1A",
    "O-360-A1A1",
    "O-360-A1A2",
    "O-360-A1AD",
    "O-360-A1C",
    "O-360-A1D",
    "O-360-A1F",
    "O-360-A1F6",
    "O-360-A1F6D",
    "O-360-A1G",
    "O-360-A1G6",
    "O-360-A1G6D",
    "O-360-A1H",
    "O-360-A1H6",
    "O-360-A1LD",
    "O-360-A1P",
    "O-360-A2A",
    "O-360-A2A1",
    "O-360-A2A2",
    "O-360-A2D",
    "O-360-A2E",
    "O-360-A2F",
    "O-360-A2G",
    "O-360-A2H",
    "O-360-A3A",
    "O-360-A3A1",
    "O-360-A3A2",
    "O-360-A3AD",
    "O-360-A3D",
    "O-360-A4A",
    "O-360-A4A1",
    "O-360-A4A2",
    "O-360-A4AD",
    "O-360-A4D",
    "O-360-A4G",
    "O-360-A4J",
    "O-360-A4K",
    "O-360-A4M",
    "O-360-A4N",
    "O-360-A4P",
    "O-360-A5A1",
    "O-360-A5A2",
    "O-360-A5AD",
    "O-360-A6A1",
    "O-360-A6A2",
    "O-360-B1A",
    "O-360-B1A1",
    "O-360-B1A2",
    "O-360-B1B",
    "O-360-B2A",
    "O-360-B2A1",
    "O-360-B2A2",
    "O-360-B2B",
    "O-360-B2C",
    "O-360-B3A1",
    "O-360-B3A2",
    "O-360-B4A1",
    "O-360-B4A2",
    "O-360-B5A1",
    "O-360-B5A2",
    "O-360-B6A1",
    "O-360-B6A2",
    "O-360-C1A",
    "O-360-C1A1",
    "O-360-C1A2",
    "O-360-C1C",
    "O-360-C1E",
    "O-360-C1F",
    "O-360-C1G",
    "O-360-C2A",
    "O-360-C2A1",
    "O-360-C2A2",
    "O-360-C2B",
    "O-360-C2C",
    "O-360-C2D",
    "O-360-C2E",
    "O-360-C3A1",
    "O-360-C3A2",
    "O-360-C4A1",
    "O-360-C4A2",
    "O-360-C4F",
    "O-360-C4P",
    "O-360-C5A1",
    "O-360-C5A2",
    "O-360-C6A1",
    "O-360-C6A2",
    "O-360-D1A",
    "O-360-D1A1",
    "O-360-D1A2",
    "O-360-D2A",
    "O-360-D2A1",
    "O-360-D2A2",
    "O-360-D2B",
    "O-360-D3A1",
    "O-360-D3A2",
    "O-360-D4A1",
    "O-360-D4A2",
    "O-360-D5A1",
    "O-360-D5A2",
    "O-360-D6A1",
    "O-360-D6A2",
    "O-360-E1A1",
    "O-360-E1A2",
    "O-360-E1A6D",
    "O-360-E1AD",
    "O-360-E1BD",
    "O-360-E2A1",
    "O-360-E2A2",
    "O-360-E2AD",
    "O-360-E2BD",
    "O-360-E3A1",
    "O-360-E3A2",
    "O-360-E4A1",
    "O-360-E4A2",
    "O-360-E5A1",
    "O-360-E5A2",
    "O-360-E6A1",
    "O-360-E6A2",
    "O-360-F1A6",
    "O-360-G1A6",
    "O-360-J2A",
    "O-435",
    "O-435-25",
    "O-435-A",
    "O-435-A2",
    "O-435-B (O-435-5)",
    "O-435-C (O-435-1)",
    "O-435-C1 (O-435-11)",
    "O-435-C2 (O-435-13)",
    "O-435-K",
    "O-435-K1 (O-435-4)",
    "O-470-2",
    "O-470-4",
    "O-470-11",
    "O-470-11-CI",
    "O-470-11B",
    "O-470-11B-CI",
    "O-470-13",
    "O-470-13A",
    "O-470-15",
    "O-470-A",
    "O-470-B",
    "O-470-B-CI",
    "O-470-E",
    "O-470-G",
    "O-470-G-CI",
    "O-470-H",
    "O-470-J",
    "O-470-K",
    "O-470-K-CI",
    "O-470-L",
    "O-470-L-CI",
    "O-470-M",
    "O-470-M-CI",
    "O-470-N",
    "O-470-P",
    "O-470-R",
    "O-470-S",
    "O-470-T",
    "O-470-U",
    "O-540-A1A",
    "O-540-A1A5",
    "O-540-A1B5",
    "O-540-A1C5",
    "O-540-A1D",
    "O-540-A1D5",
    "O-540-A2B",
    "O-540-A3D5",
    "O-540-A4A5",
    "O-540-A4B5",
    "O-540-A4C5",
    "O-540-A4D5",
    "O-540-A4E5",
    "O-540-B1A5",
    "O-540-B1B5",
    "O-540-B1D5",
    "O-540-B2A5",
    "O-540-B2B5",
    "O-540-B2C5",
    "O-540-B4A5",
    "O-540-B4B5",
    "O-540-D1A5",
    "O-540-E4A5",
    "O-540-E4B5",
    "O-540-E4C5",
    "O-540-E4G5",
    "O-540-F1A5",
    "O-540-F1B5",
    "O-540-G1A5",
    "O-540-G2A5",
    "O-540-H1A5",
    "O-540-H1A5D",
    "O-540-H1B5D",
    "O-540-H2A5",
    "O-540-H2A5D",
    "O-540-H2B5D",
    "O-540-J1A5D",
    "O-540-J1B5D",
    "O-540-J1C5D",
    "O-540-J1D5D",
    "O-540-J2A5D",
    "O-540-J2B5D",
    "O-540-J2C5D",
    "O-540-J2D5D",
    "O-540-J3A5",
    "O-540-J3A5D",
    "O-540-J3C5D",
    "O-540-L3C5D",
    "OA7 Optica Series 300",
    "Occupant Restraint System Assemblies",
    "OH-13E",
    "OH-13H",
    "OH-58A",
    "OH-58A+",
    "OH-58C",
    "Oil Filters",
    "Olympus 593 Mk. 610-14-28",
    "OMF-100-160",
    "Oxygen Connectors",
    "Oxygen cylinder and valve assemblies",
    "Oxygen cylinders",
    "Oxygen Cylinders",
    "Oxygen Mask Assembly",
    "Oxygen Mask Plug-In Connectors",
    "Oxygen Masks",
    "Oxygen Regulators",
    "Oxygen valve assemblies (body and gage assemblies)",
    "P 68",
    "P 68 Observer",
    "P 68B",
    "P 68C",
    "P 68C-TC",
    "P-3A",
    "P-38E (Army)",
    "P-38J (F-5E)",
    "P-38L (F-5F and F-5G)",
    "P-38M",
    "P-180",
    "P.68",
    "P.68 Observer",
    "P.68 Observer 2",
    "P.68B",
    "P.68C",
    "P.68C-TC",
    "P.68R",
    "P.68TC Observer",
    "P.136-L",
    "P.136-L1",
    "P.136-L2",
    "P.166",
    "P/N 299K63-1",
    "P2V-5F (SP-2E)",
    "P3A",
    "P3B",
    "P4Y-2 (Convair)",
    "P35",
    "P68 Observer 2",
    "P68TC Observer",
    "P172D",
    "P206",
    "P206A",
    "P206B",
    "P206C",
    "P206D",
    "P206E",
    "P210N",
    "P210R",
    "P220",
    "P337H",
    "P2006T",
    "P2012 Traveller",
    "PA-11",
    "PA-11S",
    "PA-12",
    "PA-12S",
    "PA-14",
    "PA-15",
    "PA-16",
    "PA-16S",
    "PA-17",
    "PA-18",
    "PA-18-105 (Special)",
    "PA-18-125 (Army L-21A)",
    "PA-18-135",
    "PA-18-150",
    "PA-18A",
    "PA-18A (Restricted)",
    "PA-18A-135",
    "PA-18A-135 (Restricted)",
    "PA-18A-150",
    "PA-18A-150 (Restricted)",
    "PA-18AS-125",
    "PA-18AS-135",
    "PA-18AS-150",
    "PA-18S",
    "PA-18S-105 (Special)",
    "PA-18S-125",
    "PA-18S-135",
    "PA-18S-150",
    "PA-19 (Army L-18C)",
    "PA-19S",
    "PA-20",
    "PA-20-115",
    "PA-20-135",
    "PA-20S",
    "PA-20S-115",
    "PA-20S-135",
    "PA-22",
    "PA-22-108",
    "PA-22-135",
    "PA-22-150",
    "PA-22-160",
    "PA-22S-135",
    "PA-22S-150",
    "PA-22S-160",
    "PA-23",
    "PA-23-160",
    "PA-23-235",
    "PA-23-250",
    "PA-23-250 (Navy UO-1)",
    "PA-24",
    "PA-24-250",
    "PA-24-260",
    "PA-24-400",
    "PA-25",
    "PA-25-235",
    "PA-25-260",
    "PA-28-140",
    "PA-28-150",
    "PA-28-151",
    "PA-28-160",
    "PA-28-161",
    "PA-28-180",
    "PA-28-181",
    "PA-28-201T",
    "PA-28-235",
    "PA-28-236",
    "PA-28R-180",
    "PA-28R-200",
    "PA-28R-201",
    "PA-28R-201T",
    "PA-28RT-201",
    "PA-28RT-201T",
    "PA-28S-160",
    "PA-28S-180",
    "PA-30",
    "PA-31",
    "PA-31-300",
    "PA-31-325",
    "PA-31-350",
    "PA-31P",
    "PA-31P-350",
    "PA-31T",
    "PA-31T1",
    "PA-31T2",
    "PA-31T3",
    "PA-32-260",
    "PA-32-300",
    "PA-32-301",
    "PA-32-301FT",
    "PA-32-301T",
    "PA-32-301XTC",
    "PA-32R-300",
    "PA-32R-301 (HP)",
    "PA-32R-301 (SP)",
    "PA-32R-301T",
    "PA-32RT-300",
    "PA-32RT-300T",
    "PA-32S-300",
    "PA-34-200",
    "PA-34-200T",
    "PA-34-220T",
    "PA-34-220T (Seneca V)",
    "PA-36-285",
    "PA-36-300",
    "PA-36-375",
    "PA-38-112",
    "PA-39",
    "PA-40",
    "PA-42",
    "PA-42-720",
    "PA-42-720R",
    "PA-42-1000",
    "PA-44-180",
    "PA-44-180T",
    "PA-46-310P",
    "PA-46-350P",
    "PA-46-500TP",
    "PA-46-600TP",
    "PA-46R-350T",
    "PA-60-600 (Aerostar 600)",
    "PA-60-601 (Aerostar 601)",
    "PA-60-601P (Aerostar 601P)",
    "PA-60-602P (Aerostar 602P)",
    "PA-60-700P (Aerostar 700P)",
    "PA-E23-250",
    "Pacemaker 31-42",
    "Pacemaker 31-42 (Floats)",
    "Paper Induction Air Filters",
    "Parachute Assemblies",
    "Parachute Canopies",
    "Parachute Harnesses",
    "Parachute Ripcord Handles",
    "Parachute Systems",
    "Parachutes",
    "Passenger Oxygen Masks",
    "Passenger Oxygen System",
    "Passenger seat assemblies",
    "Passenger Seat with Footrest Assembly",
    "Passenger seats",
    "PBR-90",
    "PC-6",
    "PC-6-H1",
    "PC-6-H2",
    "PC-6/350",
    "PC-6/350-H1",
    "PC-6/350-H2",
    "PC-6/A",
    "PC-6/A-H1",
    "PC-6/A-H2",
    "PC-6/B-H2",
    "PC-6/B1-H2",
    "PC-6/B2-H2",
    "PC-6/B2-H4",
    "PC-6/C-H2",
    "PC-6/C1-H2",
    "PC-7",
    "PC-12",
    "PC-12/45",
    "PC-12/47",
    "PC-12/47E",
    "PC-24",
    "PC-203",
    "PCORN",
    "Penguin-90",
    "PFM3200N01",
    "PFM3200N02",
    "PFM3200N03",
    "PHC-A3V",
    "PHC-A3XF-2",
    "PHC-C3Y",
    "PHC-G3YF-1",
    "PHC-G3YF-2",
    "PHC-H3YF-1",
    "PHC-H3YF-2",
    "PHC-I3YF-1",
    "PHC-I3YF-2",
    "PHC-J3Y",
    "PHC-J3YF-1",
    "PHC-J3YF-2",
    "PHC-L3Y",
    "PHC-L3YF-1",
    "Phoebus A1",
    "Phoebus B1",
    "Phoebus C",
    "Piggy Bank 90",
    "PIK-20",
    "PIK-20B",
    "PIK-20D",
    "Pilot and Co-Pilot Seats",
    "Pilot and Co-Pilot seats",
    "Pilot seats 120/335",
    "Pirate C4",
    "Pirate C4S",
    "Pirate D4",
    "Piston Pins",
    "Pistons",
    "Pitot-Static Probes",
    "PMC1102-02",
    "PMC3100-00",
    "PNUT",
    "Portable Fire Extinguishers",
    "Portable Halon 1211 fire extinguisher, part number 863520 00",
    "Power turbine and Governor Assemblies",
    "PR-G1 (Army TG-32, Navy LNE-1)",
    "Pratt & Whitney Models (all)",
    "Primary Servo",
    "Primary turbo propeller governors",
    "Primus EPIC and Primus APEX flight management systems",
    "Primus II RNZ-850( ) integrated navigation unit",
    "Primus II RNZ-851( ) integrated navigation unit",
    "Propeller Blades",
    "Propeller governors",
    "Protective Breathing Equipment",
    "Protective breathing equipment (PBE) units",
    "Protective Breathing Equipment P/N 119003-11",
    "Protective Breathing Equipment P/N 119003-21",
    "PT-220-C",
    "PT6A-6",
    "PT6A-6/C20",
    "PT6A-6A",
    "PT6A-6B",
    "PT6A-11",
    "PT6A-11AG",
    "PT6A-15AG",
    "PT6A-20",
    "PT6A-20A",
    "PT6A-20B",
    "PT6A-21",
    "PT6A-25",
    "PT6A-25A",
    "PT6A-25C",
    "PT6A-27",
    "PT6A-28",
    "PT6A-29",
    "PT6A-34",
    "PT6A-34AG",
    "PT6A-34B",
    "PT6A-36",
    "PT6A-38",
    "PT6A-40",
    "PT6A-41",
    "PT6A-41AG",
    "PT6A-42",
    "PT6A-42A",
    "PT6A-45",
    "PT6A-45A",
    "PT6A-45B",
    "PT6A-45R",
    "PT6A-50",
    "PT6A-60",
    "PT6A-60A",
    "PT6A-60AG",
    "PT6A-61",
    "PT6A-61A",
    "PT6A-64",
    "PT6A-65AG",
    "PT6A-65AR",
    "PT6A-65B",
    "PT6A-65R",
    "PT6A-66",
    "PT6A-66A",
    "PT6A-66B",
    "PT6A-66D",
    "PT6A-67",
    "PT6A-67A",
    "PT6A-67AF",
    "PT6A-67AG",
    "PT6A-67B",
    "PT6A-67D",
    "PT6A-67F",
    "PT6A-67P",
    "PT6A-67R",
    "PT6A-67RM",
    "PT6A-67T",
    "PT6A-68",
    "PT6A-68D",
    "PT6A-110",
    "PT6A-112",
    "PT6A-114",
    "PT6A-114A",
    "PT6A-116",
    "PT6A-121",
    "PT6A-135",
    "PT6A-135A",
    "PT6B-9",
    "PT6B-36A",
    "PT6B-36B",
    "PT6B-37A",
    "PT6C-67C",
    "PT6E-66XT",
    "PT6E-67XP",
    "PT6T-3",
    "PT6T-3A",
    "PT6T-6",
    "Public Address Speaker Systems",
    "Pulselite Units",
    "Push-Pull Controls",
    "PW-5 Smyk",
    "PW-6U",
    "PW118",
    "PW118A",
    "PW118B",
    "PW119B",
    "PW119C",
    "PW120",
    "PW120A",
    "PW121",
    "PW121A",
    "PW123",
    "PW123AF",
    "PW123B",
    "PW123C",
    "PW123D",
    "PW123E",
    "PW124B",
    "PW125B",
    "PW126A",
    "PW127",
    "PW127E",
    "PW127F",
    "PW127G",
    "PW127M",
    "PW150A",
    "PW206A",
    "PW206B",
    "PW206B2",
    "PW206C",
    "PW206E",
    "PW207C",
    "PW207D",
    "PW207D1",
    "PW207D2",
    "PW207E",
    "PW210A",
    "PW210S",
    "PW305",
    "PW305A",
    "PW305B",
    "PW306A",
    "PW306B",
    "PW307A",
    "PW307D",
    "PW308A",
    "PW308C",
    "PW530A",
    "PW535A",
    "PW545A",
    "PW545B",
    "PW615F-A",
    "PW617F-E",
    "PW1122G-JM",
    "PW1124G-JM",
    "PW1124G1-JM",
    "PW1127G-JM",
    "PW1127G1-JM",
    "PW1127G1A-JM",
    "PW1127G1B-JM",
    "PW1127GA-JM",
    "PW1129G-JM",
    "PW1130G-JM",
    "PW1133G-JM",
    "PW1133GA-JM",
    "PW1428G-JM",
    "PW1428GA-JM",
    "PW1428GH-JM",
    "PW1431G-JM",
    "PW1431GA-JM",
    "PW1431GH-JM",
    "PW1519G",
    "PW1521G",
    "PW1521G-3",
    "PW1521GA",
    "PW1524G",
    "PW1524G-3",
    "PW1525G",
    "PW1525G-3",
    "PW1919G",
    "PW1921G",
    "PW1922G",
    "PW1923G",
    "PW1923G-A",
    "PW2037",
    "PW2037D",
    "PW2037M",
    "PW2040",
    "PW2040D",
    "PW2043",
    "PW2143",
    "PW2240",
    "PW2337",
    "PW2643",
    "PW4050",
    "PW4052",
    "PW4056",
    "PW4060",
    "PW4060A",
    "PW4060C",
    "PW4062",
    "PW4062A",
    "PW4074",
    "PW4074D",
    "PW4077",
    "PW4077D",
    "PW4084",
    "PW4084D",
    "PW4090",
    "PW4090-3",
    "PW4090D",
    "PW4098",
    "PW4152",
    "PW4156",
    "PW4156A",
    "PW4158",
    "PW4160",
    "PW4164",
    "PW4164-1D",
    "PW4164C",
    "PW4164C-1D",
    "PW4164C/B",
    "PW4164C/B-1D",
    "PW4168",
    "PW4168-1D",
    "PW4168A",
    "PW4168A-1D",
    "PW4170",
    "PW4460",
    "PW4462",
    "PW4650",
    "PW6122A",
    "PW6124A",
    "PX-203",
    "PY-203",
    "PZL M18",
    "PZL M18A",
    "PZL M18B",
    "PZL M20 03",
    "PZL M26 01",
    "PZL M28 05",
    "PZL W-3A",
    "PZL-3S",
    "PZL-3S 2nd Series",
    "PZL-10W",
    "PZL-104 WILGA 80",
    "PZL-104M WILGA 2000",
    "PZL-104MA WILGA 2000",
    "PZL-KOLIBER 150A",
    "PZL-KOLIBER 160A",
    "QUBE-80",
    "Quick Connectors",
    "R-4B (Army)",
    "R-530-D1",
    "R-530-D2",
    "R-670",
    "R-670-A",
    "R-670-B",
    "R-670-C",
    "R-670-D",
    "R-670-E",
    "R-670-F",
    "R-670-G",
    "R-670-H",
    "R-680",
    "R-680-2",
    "R-680-4",
    "R-680-5",
    "R-680-6",
    "R-680-B2",
    "R-680-B4 Series",
    "R-680-B5",
    "R-680-B6",
    "R-680-BA",
    "R-680-D5",
    "R-680-D6",
    "R-680-E1",
    "R-680-E2",
    "R-680-E3",
    "R-680-E3A",
    "R-680-E3B",
    "R4D-8",
    "R4D-8Z",
    "R9-A",
    "R22",
    "R22 ALPHA",
    "R22 BETA",
    "R22 MARINER",
    "R44",
    "R44 II",
    "R66",
    "R172E",
    "R172F",
    "R172G",
    "R172H",
    "R172J",
    "R172K",
    "R182",
    "R201",
    "R202",
    "R203",
    "R2160",
    "R3000/160",
    "Radio Magnetic Indicator",
    "Rallye 100S",
    "Rallye 150 ST",
    "Rallye 150 T",
    "Rallye 235 E",
    "Rallye 235C",
    "RB211 Trent 553-61",
    "RB211 Trent 553A2-61",
    "RB211 Trent 556-61",
    "RB211 Trent 556A2-61",
    "RB211 Trent 556B-61",
    "RB211 Trent 556B2-61",
    "RB211 Trent 560-61",
    "RB211 Trent 560A2-61",
    "RB211 Trent 768-60",
    "RB211 Trent 772-60",
    "RB211 Trent 772B-60",
    "RB211-22B-02",
    "RB211-22B/MOD 72-8700",
    "RB211-22C-02",
    "RB211-524-02",
    "RB211-524B-02",
    "RB211-524B-B-02",
    "RB211-524B2-19",
    "RB211-524B2-B-19",
    "RB211-524B3-02",
    "RB211-524B4-02",
    "RB211-524B4-D-02",
    "RB211-524C2-19",
    "RB211-524C2-B-19",
    "RB211-524D4-19",
    "RB211-524D4-39",
    "RB211-524D4-B-19",
    "RB211-524D4-B-39",
    "RB211-524D4X-19",
    "RB211-524D4X-B-19",
    "RB211-524G2-19",
    "RB211-524G2-T-19",
    "RB211-524G3-19",
    "RB211-524G3-T-19",
    "RB211-524H-36",
    "RB211-524H-T-36",
    "RB211-524H2-19",
    "RB211-524H2-T-19",
    "RB211-535C-37",
    "RB211-535E4-37",
    "RB211-535E4-B-37",
    "RB211-535E4-B-75",
    "RB211-535E4-C-37",
    "RB211-Trent 875-17",
    "RB211-Trent 877-17",
    "RB211-Trent 884-17",
    "RB211-Trent 884B-17",
    "RB211-Trent 892-17",
    "RB211-Trent 892B-17",
    "RB211-Trent 895-17",
    "RB211-TRENT 970-84",
    "RB211-TRENT 970B-84",
    "RB211-TRENT 972-84",
    "RB211-TRENT 972B-84",
    "RB211-TRENT 977-84",
    "RB211-TRENT 977B-84",
    "RB211-TRENT 980-84",
    "RB211-Trent-892B-17",
    "RC-3",
    "RC-45J",
    "Receivers",
    "Reciprocating Engine Connecting Rods",
    "Reciprocating Engine Cylinder Assemblies",
    "Reciprocating Engine Cylinders",
    "Refueling System",
    "Regulator",
    "Rescue hoists",
    "Rescue Locator Beacons",
    "Restraint Systems",
    "Rhonlerche II",
    "RNF",
    "Rockwell Collins Transponders",
    "rotary buckle assembly",
    "Rotary Buckle Assembly",
    "RR C90-8F",
    "RR C90-8FJ",
    "RR C90-12F",
    "RR C90-12FH",
    "RR C90-12FJ",
    "RR C90-12FP",
    "RR C90-14F",
    "RR C90-14FH",
    "RR C90-14FJ",
    "RR C90-16F",
    "RR O-200-A",
    "RR O-200-B",
    "RR O-200-C",
    "RR O-240-A",
    "RR O-300-A",
    "RR O-300-B",
    "RR O-300-C",
    "RR O-300-D",
    "RX-6",
    "RX-7",
    "RX-8",
    "RX-9",
    "RXS-8",
    "S-1B2",
    "S-1S",
    "S-1T",
    "S-2",
    "S-2A",
    "S-2B",
    "S-2C",
    "S-2R",
    "S-2S",
    "S-30",
    "S-40A",
    "S-49A",
    "S-50",
    "S-50A",
    "S-51",
    "S-51A",
    "S-52",
    "S-52-3",
    "S-52A",
    "S-53A",
    "S-53H",
    "S-55",
    "S-55A",
    "S-55B",
    "S-55C",
    "S-57A",
    "S-57H",
    "S-57S",
    "S-58A",
    "S-58B",
    "S-58BT",
    "S-58C",
    "S-58D",
    "S-58DT",
    "S-58E",
    "S-58ET",
    "S-58F",
    "S-58FT",
    "S-58G",
    "S-58H",
    "S-58HT",
    "S-58J",
    "S-58JT",
    "S-60A",
    "S-60H",
    "S-60S",
    "S-61A",
    "S-61D",
    "S-61E",
    "S-61L",
    "S-61N",
    "S-61NM",
    "S-61R",
    "S-61V",
    "S-64A",
    "S-64E",
    "S-64F",
    "S-65-CA",
    "S-66A",
    "S-70",
    "S-70A",
    "S-70C",
    "S-70C(M)",
    "S-70C(M1)",
    "S-70M",
    "S-71A",
    "S-76A",
    "S-76B",
    "S-76C",
    "S-76D",
    "S-77A",
    "S-81A",
    "S-90",
    "S-92A",
    "S-94",
    "S-105",
    "S-121",
    "S-130",
    "S-160",
    "S-307",
    "S.205 - 18/F",
    "S.205 - 18/R",
    "S.205 - 20/F",
    "S.205 - 20/R",
    "S.205 - 22/R",
    "S.208",
    "S.208A",
    "S.211A",
    "S/N 01867xxx through 02737xxx",
    "S2A",
    "S2R-G1",
    "S2R-G5",
    "S2R-G6",
    "S2R-G10",
    "S2R-R3S",
    "S2R-R1340",
    "S2R-R1820",
    "S2R-T11",
    "S2R-T15",
    "S2R-T34",
    "S2R-T45",
    "S2R-T65",
    "S2R-T660",
    "S2RHG-T34",
    "S2RHG-T65",
    "S6",
    "S6-RT",
    "S7AC",
    "S7CCM",
    "S7DC",
    "S7EC",
    "S11AC",
    "S11BC",
    "S11CC",
    "S15AC",
    "S18A",
    "S18D",
    "S35",
    "S62A",
    "S550",
    "SA 160",
    "SA 318B-Alouette Astazou",
    "SA 318C-Alouette Astazou",
    "SA 3180-Alouette Astazou",
    "SA-307B",
    "SA-307B-1",
    "SA-360C Dauphin",
    "SA-365C",
    "SA-365C1",
    "SA-365C2",
    "SA-365N",
    "SA-365N1",
    "SA-366G1",
    "SA.315B Alouette III",
    "SA.316B Alouette III",
    "SA.316C Alouette III",
    "SA.319B Alouette III",
    "SA18A",
    "SA18D",
    "SA26-AT",
    "SA26-T",
    "SA226-AT",
    "SA226-T",
    "SA226-T(B)",
    "SA226-TC",
    "SA227-AC (C-26A)",
    "SA227-AT",
    "SA227-BC (C-26A)",
    "SA227-CC",
    "SA227-DC (C-26B)",
    "SA227-PC",
    "SA227-TT",
    "SA330F",
    "SA330G",
    "SA330J",
    "SA341G",
    "SA342J",
    "SAAB 340B",
    "SAAB 2000",
    "Safety Belt Assemblies",
    "Safety Belt Buckles",
    "Safety Belts",
    "Safety Belts and Restraint Systems",
    "SAP cast cylinder assemblies",
    "Saphir 20 model 095 auxiliary power units",
    "SB7L-360A",
    "SB17L",
    "SC-7 Skyvan Series 2",
    "SC-7 Skyvan Series 3",
    "SC17B",
    "SC17L",
    "SC17R",
    "Scarab Jr. Series 40",
    "Scarab Jr. Series 50",
    "Scarab Series 28",
    "Scarab Series 29",
    "Scarab Series 30",
    "Scarab Series 40",
    "Scarab Series 50",
    "SD3-30",
    "SD3-60",
    "SD3-60 SHERPA",
    "SD3-SHERPA",
    "SD17S",
    "SE 313B-Alouette II",
    "SE 3130-Alouette II",
    "SE.3160 Alouette III",
    "SE17B",
    "Seat Belt Assemblies",
    "Seat Belts and Harnesses",
    "Seat Restraint Systems",
    "Seat Track Fittings",
    "Seats",
    "Seats and seating systems",
    "Servo gearbox units",
    "SF 25C",
    "SF 27A",
    "SF-26A Standard",
    "SF17D",
    "SF34",
    "SF50",
    "SF600",
    "SF600A",
    "SGM 2-37",
    "SGS 1-23",
    "SGS 1-23D",
    "SGS 1-23F",
    "SGS 1-23G",
    "SGS 1-23H",
    "SGS 1-23H-15",
    "SGS 1-26",
    "SGS 1-26A",
    "SGS 1-26B",
    "SGS 1-26C",
    "SGS 1-26D",
    "SGS 1-26E",
    "SGS 1-34",
    "SGS 1-34R",
    "SGS 1-35",
    "SGS 1-35A",
    "SGS 1-35C",
    "SGS 1-36",
    "SGS 2-8",
    "SGS 2-8A",
    "SGS 2-32",
    "SGS 2-33",
    "SGS 2-33A",
    "SGS 2-33AK",
    "SGU 2-22",
    "SGU 2-22A",
    "SGU 2-22B",
    "SGU 2-22C",
    "SGU 2-22CK",
    "SGU 2-22E",
    "SGU 2-22EK",
    "SGU-1-19",
    "SGU-1-19A",
    "SH-3A",
    "SH-3H",
    "SH-34J",
    "Shoe-90",
    "Shuttle-90",
    "Sky Arrow 650 TC",
    "Sky Arrow 650 TCN",
    "Sky Arrow 650TCNS",
    "Sky Arrow 650TCS",
    "Skyhawk",
    "Skyhook Reserve Pilotchutes",
    "Skysailor 90",
    "Skysailor 105",
    "Skyship 600",
    "Smoke detectors",
    "Smoke Detectors",
    "SN-601 Corvette",
    "SNJ-7",
    "SO-580-A1A",
    "SO-580-A1B",
    "SP-2H",
    "SP-2H (P2V-7)",
    "Sparrow",
    "Spey 506-14",
    "Spey 506-14A",
    "Spey 506-14D",
    "Spey 511-8",
    "Spey 511-8/Mod 2970",
    "Spey 511-14",
    "Spey 511-14W",
    "Spey 555-15",
    "Spey 555-15H",
    "Spey 555-15N",
    "Spey 555-15P",
    "SPHERE-90",
    "SPHERE-105",
    "SPI",
    "SPII",
    "SPIII",
    "Spray Pressure Regulator",
    "SR 305-230",
    "SR20",
    "SR22",
    "SR22T",
    "ST-3KR",
    "STAGECOACH-110",
    "Standard Libelle",
    "Standard Libelle-201B",
    "Standard-Cirrus",
    "Starters",
    "Starting Vibrator Assemblies",
    "Stemme S 10-VT",
    "Stemme S 12",
    "Stemme S10",
    "Stemme S10-V",
    "Stemme S10-VT",
    "Strobe Light Flash Tubes",
    "Sulphur Dusting",
    "SUN",
    "Super C-46/CW20-T",
    "Super DC-3",
    "Super Pirate D-4-87",
    "Super Scarab Series 40",
    "Super Scarab Series 50",
    "Super Scarab Series 50A",
    "Super Scarab Series 165",
    "Super Scarab Series 165-B",
    "Super Scarab Series 165-D",
    "Super Scarab Series 185",
    "Super Scarab Series 185J",
    "Super Scarab Series 185K",
    "Super Universal",
    "Super-V",
    "Survivor Locator Lights",
    "SW204 (UH-1B)",
    "SW204HP (UH-1B)",
    "SW205 (UH-1H)",
    "SW205A-1",
    "Swift Reserve Canopy",
    "Symbol Generator",
    "SZD-24-4A Foka-4",
    "SZD-50-3 Puchacz",
    "T",
    "T-1",
    "T-6G",
    "T-8F",
    "T-28A",
    "T-34C",
    "T-50",
    "T-150",
    "T-180",
    "T-210",
    "T.59.D Kestrel",
    "T.65A Vega",
    "T6-320-A",
    "T30 part number (P/N) CY050001 propane cylinders",
    "T67M260",
    "T67M260-T3A",
    "T182",
    "T182T",
    "T188C",
    "T206H",
    "T207",
    "T207A",
    "T210F",
    "T210G",
    "T210H",
    "T210J",
    "T210K",
    "T210L",
    "T210M",
    "T210N",
    "T210R",
    "T240",
    "T303",
    "T310P",
    "T310Q",
    "T310R",
    "T337B",
    "T337C",
    "T337D",
    "T337E",
    "T337F",
    "T337G",
    "T337H",
    "T337H-SP",
    "T5307A",
    "T5309A",
    "T5309B",
    "T5309C",
    "T5311A",
    "T5311B",
    "T5313A",
    "T5313B",
    "T5317A",
    "T5317A-1",
    "T5317B",
    "T5317BCV",
    "T5508D",
    "TA/RA VSI-TCAS Indicators",
    "TAE 125-01",
    "TAE 125-02-99",
    "TAE 125-02-114",
    "Taifun 17E",
    "TANK-77",
    "TAY 611-8",
    "TAY 611-8C",
    "TAY 620-15",
    "TAY 650-15",
    "TAY 651-54",
    "TB 10",
    "TB 20",
    "TB 21",
    "TB 200",
    "TB9",
    "TBF-1 (Navy)",
    "TBF-1C (Navy)",
    "TBM 700",
    "TBM-1 (Navy)",
    "TBM-1C (Navy)",
    "TBM-3 (Navy)",
    "TBM-3E (Navy)",
    "TBM700",
    "TC-4",
    "TC-4A",
    "TC-45G",
    "TC-45H",
    "TC-45J",
    "TEO-540-A1A",
    "TF33-P-7",
    "TF33-P-7A",
    "TFE731-2",
    "TFE731-2A",
    "TFE731-2B",
    "TFE731-2BR",
    "TFE731-2C",
    "TFE731-3",
    "TFE731-3A",
    "TFE731-3AR",
    "TFE731-3B",
    "TFE731-3BR",
    "TFE731-3C",
    "TFE731-3CR",
    "TFE731-3D",
    "TFE731-3DR",
    "TFE731-3R",
    "TFE731-4",
    "TFE731-4R",
    "TFE731-5",
    "TFE731-5A",
    "TFE731-5AR",
    "TFE731-5B",
    "TFE731-5BR",
    "TFE731-5R",
    "TFE731-20AR",
    "TFE731-20BR",
    "TFE731-20R",
    "TFE731-40",
    "TFE731-40AR",
    "TFE731-40BR",
    "TFE731-40R",
    "TFE731-50R",
    "TFE731-60",
    "TG-3A Army",
    "TG-8 (Army TG-8; Navy XLNP-1)",
    "TH-1F",
    "TH-1L",
    "TH-28",
    "TH55A",
    "Third Occupant Seat Assemblies",
    "TIGO-541-B1A",
    "TIGO-541-C1A",
    "TIGO-541-D1A",
    "TIGO-541-D1B",
    "TIGO-541-E1A",
    "TIGO-541-G1AD",
    "TIO-360-A1A",
    "TIO-360-A1B",
    "TIO-360-A3B6",
    "TIO-360-C1A6D",
    "TIO-540-A1A",
    "TIO-540-A1B",
    "TIO-540-A1C",
    "TIO-540-A2A",
    "TIO-540-A2B",
    "TIO-540-A2C",
    "TIO-540-AA1AD",
    "TIO-540-AB1AD",
    "TIO-540-AB1BD",
    "TIO-540-AE2A",
    "TIO-540-AF1A",
    "TIO-540-AF1B",
    "TIO-540-AG1A",
    "TIO-540-AH1A",
    "TIO-540-AJ1A",
    "TIO-540-AK1A",
    "TIO-540-C1A",
    "TIO-540-E1A",
    "TIO-540-F2BD",
    "TIO-540-G1A",
    "TIO-540-H1A",
    "TIO-540-J2B",
    "TIO-540-J2BD",
    "TIO-540-K1AD",
    "TIO-540-N2BD",
    "TIO-540-R2AD",
    "TIO-540-S1AD",
    "TIO-540-T2AD",
    "TIO-540-U2A",
    "TIO-540-V2AD",
    "TIO-540-W2A",
    "TIO-541-A1A",
    "TIO-541-E1A4",
    "TIO-541-E1B4",
    "TIO-541-E1C4",
    "TIO-541-E1D4",
    "Tire-100",
    "Tires",
    "Titanium Propane Cylinders",
    "TIVO-540-A2A",
    "TO-360-A1A6D",
    "TO-360-C1A6D",
    "TO-360-E1A6D",
    "TO-360-F1A6D",
    "TP206A",
    "TP206B",
    "TP206C",
    "TP206D",
    "TP206E",
    "TP500E",
    "TPE331-1",
    "TPE331-1U",
    "TPE331-1UA",
    "TPE331-2",
    "TPE331-2U",
    "TPE331-2UA",
    "TPE331-3",
    "TPE331-3U",
    "TPE331-3UW",
    "TPE331-3W",
    "TPE331-5",
    "TPE331-5A",
    "TPE331-5AB",
    "TPE331-5B",
    "TPE331-5U",
    "TPE331-6",
    "TPE331-6A",
    "TPE331-6U",
    "TPE331-8",
    "TPE331-8A",
    "TPE331-9",
    "TPE331-9U",
    "TPE331-10",
    "TPE331-10A",
    "TPE331-10AV",
    "TPE331-10B",
    "TPE331-10G",
    "TPE331-10GP",
    "TPE331-10GR",
    "TPE331-10GT",
    "TPE331-10J",
    "TPE331-10N",
    "TPE331-10P",
    "TPE331-10R",
    "TPE331-10T",
    "TPE331-10U",
    "TPE331-10UA",
    "TPE331-10UF",
    "TPE331-10UG",
    "TPE331-10UGR",
    "TPE331-10UJ",
    "TPE331-10UK",
    "TPE331-10UR",
    "TPE331-11U",
    "TPE331-11UA",
    "TPE331-12",
    "TPE331-12B",
    "TPE331-12JR",
    "TPE331-12UA",
    "TPE331-12UAR",
    "TPE331-12UER",
    "TPE331-12UHR",
    "TPE331-14A",
    "TPE331-14B",
    "TPE331-14F",
    "TPE331-14GR",
    "TPE331-14HR",
    "TPE331-15AW",
    "TPE331-25A",
    "TPE331-25AA",
    "TPE331-25AB",
    "TPE331-25B",
    "TPE331-25C",
    "TPE331-25D",
    "TPE331-25DA",
    "TPE331-25DB",
    "TPE331-25E",
    "TPE331-25F",
    "TPE331-25FA",
    "TPE331-29A",
    "TPE331-43",
    "TPE331-43-A",
    "TPE331-43-B",
    "TPE331-45",
    "TPE331-47",
    "TPE331-47-A",
    "TPE331-47-B",
    "TPE331-51",
    "TPE331-55",
    "TPE331-55-A",
    "TPE331-55-B",
    "TPE331-57",
    "TPE331-57-B",
    "TPE331-61",
    "TPE331-61-A",
    "TPR-720 Mode S transponders",
    "TPR-900 Mode S transponders",
    "TR-1",
    "TR182",
    "Traffic Alert and Collision Avoidance System",
    "Traffic alert and collision avoidance system (TCAS) units",
    "Traffic Alert and Collision Avoidance System II processors",
    "Traffic Alert and Collision Avoidance Systems",
    "Transponders",
    "Trent 1000-A",
    "Trent 1000-A2",
    "Trent 1000-AE",
    "Trent 1000-AE2",
    "Trent 1000-AE3",
    "Trent 1000-C",
    "Trent 1000-C2",
    "Trent 1000-CE",
    "Trent 1000-CE2",
    "Trent 1000-CE3",
    "Trent 1000-D",
    "Trent 1000-D2",
    "Trent 1000-D3",
    "Trent 1000-E",
    "Trent 1000-E2",
    "Trent 1000-G",
    "Trent 1000-G2",
    "Trent 1000-G3",
    "Trent 1000-H",
    "Trent 1000-H2",
    "Trent 1000-H3",
    "Trent 1000-J2",
    "Trent 1000-J3",
    "Trent 1000-K2",
    "Trent 1000-K3",
    "Trent 1000-L2",
    "Trent 1000-L3",
    "Trent 1000-M3",
    "Trent 1000-N3",
    "Trent 1000-P3",
    "Trent 1000-Q3",
    "Trent 1000-R3",
    "Trent XWB-75",
    "Trent XWB-79",
    "Trent XWB-79B",
    "Trent XWB-84",
    "Trent XWB-97",
    "Trent7000-72",
    "Trent7000-72C",
    "Tricycle Landing Gear",
    "Trojan A-2",
    "TS-2A",
    "TSE331-3U",
    "TSIO-360-A",
    "TSIO-360-AB",
    "TSIO-360-B",
    "TSIO-360-BB",
    "TSIO-360-C",
    "TSIO-360-CB",
    "TSIO-360-D",
    "TSIO-360-DB",
    "TSIO-360-E",
    "TSIO-360-EB",
    "TSIO-360-F",
    "TSIO-360-FB",
    "TSIO-360-G",
    "TSIO-360-GB",
    "TSIO-360-H",
    "TSIO-360-HB",
    "TSIO-360-JB",
    "TSIO-360-KB",
    "TSIO-360-LB",
    "TSIO-360-MB",
    "TSIO-360-NB",
    "TSIO-360-PB",
    "TSIO-360-RB",
    "TSIO-360-SB",
    "TSIO-470-B",
    "TSIO-470-C",
    "TSIO-470-D",
    "TSIO-520-A",
    "TSIO-520-AE",
    "TSIO-520-AF",
    "TSIO-520-B",
    "TSIO-520-BB",
    "TSIO-520-BE",
    "TSIO-520-C",
    "TSIO-520-CE",
    "TSIO-520-D",
    "TSIO-520-DB",
    "TSIO-520-E",
    "TSIO-520-EB",
    "TSIO-520-G",
    "TSIO-520-H",
    "TSIO-520-J",
    "TSIO-520-JB",
    "TSIO-520-K",
    "TSIO-520-KB",
    "TSIO-520-L",
    "TSIO-520-LB",
    "TSIO-520-M",
    "TSIO-520-N",
    "TSIO-520-NB",
    "TSIO-520-P",
    "TSIO-520-R",
    "TSIO-520-T",
    "TSIO-520-U",
    "TSIO-520-UB",
    "TSIO-520-VB",
    "TSIO-520-WB",
    "TSIO-550-A",
    "TSIO-550-B",
    "TSIO-550-C",
    "TSIO-550-E",
    "TSIO-550-G",
    "TSIO-550-J",
    "TSIO-550-K",
    "TSIO-550-N",
    "TSIOF-550-D",
    "TSIOF-550-J",
    "TSIOF-550-K",
    "TSIOF-550-P",
    "TSIOL-550-A",
    "TSIOL-550-B",
    "TSIOL-550-C",
    "TSS-4100 Traffic Surveillance System Processing Unit, TSSA-4100 Field Loadable Software",
    "TT31 Mode S transponders",
    "TU206A",
    "TU206B",
    "TU206C",
    "TU206D",
    "TU206E",
    "TU206F",
    "TU206G",
    "Tubeless Tires",
    "Turbine Power Conversions",
    "Turbo 8",
    "Turbochargers",
    "Turmo IVA",
    "Turmo IVC",
    "Turn and Bank Indicators",
    "TVO-435-A1A",
    "TVO-435-B1A",
    "TVO-435-B1A (O-435-25A)",
    "TVO-435-B1B",
    "TVO-435-C1A",
    "TVO-435-D1A",
    "TVO-435-D1B",
    "TVO-435-E1A",
    "TVO-435-F1A",
    "TVO-435-G1A",
    "TVO-435-G1B",
    "TVO-540-A1A",
    "Twin Wasp 2SD-G",
    "Twin Wasp 2SD1-G",
    "Twin Wasp 2SD13-G",
    "Twin Wasp D-3",
    "Twin Wasp D-4",
    "Twin Wasp D-5",
    "Twin Wasp D-7",
    "Twin Wasp D-14",
    "Twin Wasp E1",
    "Twin Wasp S1C-G",
    "Twin Wasp S1C3-G",
    "Twin Wasp S3C4-G",
    "Twin Wasp S4C4-G",
    "Twin Wasp SB-G",
    "Twin Wasp SB3-G",
    "Twin Wasp SC-G",
    "Twin Wasp SC3-G",
    "Tyne 512",
    "Tyne 515",
    "Type 102 Mk 3 life jackets",
    "Type 102 Mk 4 life jackets",
    "Type 105 Mk 1 life jackets",
    "Type T.53.B.",
    "U206",
    "U206A",
    "U206B",
    "U206C",
    "U206D",
    "U206E",
    "U206F",
    "U206G",
    "UC-1",
    "UC-45J",
    "UH-1A",
    "UH-1B",
    "UH-1E",
    "UH-1F",
    "UH-1H",
    "UH-1L",
    "UH-1P",
    "UH-12",
    "UH-12A",
    "UH-12B",
    "UH-12C",
    "UH-12D",
    "UH-12E",
    "UH-12E-L",
    "UH-12L",
    "UH-12L4",
    "UH-19D",
    "UH-34D",
    "UH-34D (HUS-1)",
    "UH-34E (HUS-1A)",
    "UH-60A",
    "UKS-7",
    "Unapproved Appliances",
    "USAF C-118A",
    "USAF CH-3C",
    "V",
    "V-2",
    "V-31",
    "V-42",
    "V-56",
    "V-65",
    "V-77",
    "V-90",
    "V-105",
    "V35",
    "V35A",
    "V35B",
    "V2500-A1",
    "V2522-A5",
    "V2524-A5",
    "V2525-D5",
    "V2527-A5",
    "V2527E-A5",
    "V2527M-A5",
    "V2528-D5",
    "V2530-A5",
    "V2531-E5",
    "V2533-A5",
    "vacuum pump",
    "Vacuum Pumps",
    "Vacuum Systems",
    "Vector 3 SE",
    "Vega 1",
    "Vega 5",
    "Vega 5-A Executive",
    "Vega 5-C (Army UC-101)",
    "Ventus-2a",
    "Ventus-2b",
    "Vertical Gyro",
    "Very-High-Frequency Navigation/Communication Radio",
    "VFW-614",
    "VHF AM Transceivers",
    "Viper Mk. 521",
    "Viper Mk. 522",
    "Viper Mk. 526",
    "Viper Mk. 601-22",
    "Viscount 744",
    "Viscount 745D",
    "Viscount 810",
    "VKS-7 (Army UC-72D)",
    "VKS-7F",
    "VO-360-A1A",
    "VO-360-A1B",
    "VO-360-B1A",
    "VO-435-A1A",
    "VO-435-A1A (O-435-21)",
    "VO-435-A1B",
    "VO-435-A1B (O-435-6)",
    "VO-435-A1C",
    "VO-435-A1C (O-435-23, -23A, -23B, -23C)",
    "VO-435-A1D",
    "VO-435-A1D (O-435-6A)",
    "VO-435-A1E",
    "VO-435-A1F",
    "VO-435-B1A",
    "VO-540-A1A",
    "VO-540-A2A",
    "VO-540-B1A",
    "VO-540-B1B",
    "VO-540-B1B3",
    "VO-540-B1C",
    "VO-540-B1D",
    "VO-540-B1E",
    "VO-540-B1F",
    "VO-540-B1H3",
    "VO-540-B2A",
    "VO-540-B2C",
    "VO-540-B2D",
    "VO-540-B2E",
    "VO-540-B2G",
    "VO-540-C1A",
    "VO-540-C1B",
    "VO-540-C1C3",
    "VO-540-C2A",
    "VO-540-C2A (O-540-9)",
    "VO-540-C2B",
    "VO-540-C2C",
    "Volaire 10",
    "Volaire 10A",
    "VSO-580-A1A",
    "Vulcanair V1.0",
    "W-670-9A",
    "W100LB",
    "W670-6A",
    "W670-6N",
    "W670-16",
    "Wasp A",
    "Wasp B",
    "Wasp C",
    "Wasp C1",
    "Wasp CD",
    "Wasp Jr. A",
    "Wasp Jr. B-4",
    "Wasp Jr. B-5",
    "Wasp Jr. SB",
    "Wasp Jr. SB-2",
    "Wasp Jr. SB-3",
    "Wasp Jr. T1B",
    "Wasp Jr. T1B2",
    "Wasp Jr. T1B3",
    "Wasp Jr. T3A",
    "Wasp Jr. TB",
    "Wasp Jr. TB-2",
    "Wasp Major B5",
    "Wasp Major B6",
    "Wasp Major B7",
    "Wasp Major B13",
    "Wasp Major B14",
    "Wasp Major CB2",
    "Wasp Major TSB3-G",
    "Wasp R-1340-59",
    "Wasp R-1340-61",
    "Wasp S1D1",
    "Wasp S1D1-G",
    "Wasp S1H1",
    "Wasp S1H1-G",
    "Wasp S1H2",
    "Wasp S1H4",
    "Wasp S3D1",
    "Wasp S3H1",
    "Wasp S3H1-G",
    "Wasp S3H2",
    "Wasp SC",
    "Wasp SC-1",
    "Wasp T1D1",
    "Wasp T2D1",
    "Wasp TD1",
    "Water Boilers/Coffee Makers/Beverage Makers",
    "Water Heaters and Coffee Makers",
    "Westland 30 Series 100",
    "Westland 30 Series 100-60",
    "Wheel Assemblies",
    "Wheel Assembly",
    "Wheel Skis",
    "Wheel/Brake Assembly",
    "Wire Bundles",
    "XA42",
    "XL-2",
    "Y12 IV",
    "YS-11",
    "YS-11A-200",
    "YS-11A-300",
    "YS-11A-500",
    "YS-11A-600",
    "Z-31",
    "Z-42",
    "Z-60",
    "Z-65",
    "Z-69",
    "Z-70",
    "Z-77",
    "Z-90",
    "Z-105",
    "Z-120",
    "Z-133",
    "Z-140",
    "Z-143L",
    "Z-145",
    "Z-150",
    "Z-160",
    "Z-180",
    "Z-210",
    "Z-225",
    "Z-242L",
    "Z-250",
    "ZL-56",
    "ZL-60",
    "ZL-65",
    "ZL-70",
    "ZL-77",
    "ZL-84",
    "Zlin 526L"
]

