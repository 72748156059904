import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { SquawkCollection } from "../../keys";
import { AddSquawk } from "./addSquawkModal";
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { useLoadingOverlay } from '../../loading';
import { AddMxLog } from '../DashboardComponents/mxLogbook';
import AddSquawkIcon from '../../assets/icons/AddSquawkIcon.svg';
import { InfoSeverityIcon, Severity } from '../../components/severity';
import { cn } from '../../userData/userData';
import { CustomButtonFill } from "../../components/buttonfill";

export const SquawkList = ({ aircraftId, onCloseMx, setSquawkSeverity }: {
    aircraftId: string,
    onCloseMx: () => void, setSquawkSeverity: (severity: Severity) => void
}) => {
    const [squawks, setSquawks] = useState<any[]>([])
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfimComplete, setShowConfimComplte] = useState(false);
    const [selected, setSelected] = useState<any[]>([]);
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllSquawks()
    }, [])

    type PriorityType = "High" | "Medium" | "Low";
    const priority = { "High": 1, "Medium": 2, "Low": 3 };

    const getAllSquawks = async () => {
        const db = getFirestore()
        const data: any[] = [];

        const q = query(collection(db, SquawkCollection), where("status", "==", "Pending"), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });
        data.sort((a: any, b: any) => priority[a.severity as PriorityType] - priority[b.severity as PriorityType]);

        setSquawkSeverity(data.some((a: any) => a.severity == "High") ? Severity.High : data.some((a: any) => a.severity == "Medium") ? Severity.Medium : Severity.Low);
        setSquawks(data);
    }

    const getSeverity = (severity: string) => {
        return severity == "High" ? Severity.High : severity == "Medium" ? Severity.Medium : Severity.Low
    }

    return <>
        <div className="">
            <div className="flex mb-3">
                <span className="text-[14px] font-bold">SQUAWKS</span>
                <div className="mt-[-3px] ml-3">
                    <Tooltip title="Add squawk" arrow>
                        <div className="w-[25px] cursor-pointer" onClick={() => { setShowAddModal(true) }} ><img src={AddSquawkIcon} /></div>
                    </Tooltip>
                </div>
            </div>

            {selected.length > 0 && <div className="fixed mt-[100px] ml-[15vw] shadow-lg ">
                <CustomButtonFill text={`Fix Squawk(s) - (${selected.length})`} onClick={() => {setShowConfimComplte(true) }} />
            </div>}

            {squawks.map((value, i) => {
                return <div key={i} className={cn("flex items-center border-2 p-[2px] rounded-md mb-1", "border-orange-500")}>
                    <InfoSeverityIcon severity={getSeverity(value.severity)} />
                    <p className="text-gray-700 text-[12px] ml-4"><b>{value.squawkNumber}</b></p>
                    <p className="text-gray-700 text-[12px] ml-2">{value.date}</p>
                    <p className="flex-1 text-gray-700 text-[12px] ml-2">{value.description}</p>
                    <p className="text-gray-700 text-[12px] ml-2"><b>{value.severity}</b></p>
                    <p className="text-gray-700 text-[12px] ml-2 mr-2">{value.pilot}</p>
                    <div className="cursor-pointer ">
                        <FormControlLabel
                            control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                                if (e.target.checked) {
                                    await setSelected([...selected, value])
                                } else {
                                    await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                                }
                            }} name="fixSquawk" />}
                            label=""
                        />
                    </div>
                    {/* <div className="cursor-pointer" onClick={(e: any) => {
                        setSelected(value)
                        setShowConfimComplte(true)
                    }}>
                        <Tooltip title="Fix Squawk" arrow>
                            <BuildIcon className='p-1' />
                        </Tooltip>
                    </div> */}
                </div>
            })
            }
            {
                squawks.length == 0 && <p className="text-[13px] text-[green]">No overdue squawks</p>
            }

            {
                showAddModal && <AddSquawk aircraftId={aircraftId} onClose={() => {
                    setShowAddModal(false)
                    getAllSquawks()
                }} />
            }

            {
                showConfimComplete && <AddMxLog onClose={() => {
                    onCloseMx()
                    setSelected([])
                    setShowConfimComplte(false)
                    getAllSquawks()
                }} aircraftId={aircraftId}
                    descriptionPreFill={"Pilot reported " + selected.map(item => item.description).join(", ") + "- RESOLVED"}
                    squawk={selected}
                />
            }
        </div>
    </>
}


// const CompleteSquawk = ({ squawk, onClose }: { squawk: any, onClose: () => void }) => {
//     const { showOverlay, hideOverlay } = useLoadingOverlay();

//     const style = {
//         position: 'absolute' as 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         bgcolor: 'background.paper',
//         p: 3,
//     };

//     return <Modal
//         open={true}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//     >
//         <Card className='' sx={style}>
//             <div className='flex  flex-row justify-between'>
//                 <h1 className="text-3xl mb-5">Complete SQUAWK</h1>
//                 <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
//             </div>
//             <h3>
//                 Click ok to complete squawk as completed.
//             </h3>

//             <div className="flex mt-5 gap-3">
//                 <CustomButtonFill text='Yes' onClick={async () => {
//                     showOverlay()
//                     const db = getFirestore()
//                     let squawkToSave = {
//                         ...squawk,
//                         status: "Completed"
//                     }
//                     console.log(squawk)

//                     await setDoc(doc(db, SquawkCollection, squawk.id), squawkToSave)
//                     hideOverlay()
//                     onClose()
//                 }} />
//                 <CustomButton text="No" onClick={() => onClose()} />
//             </div>

//         </Card>
//     </Modal>
// }