
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import InfoIcon from '@mui/icons-material/Info';

export enum Severity {
    Done,
    Low,
    Medium,
    High,
    Info
}

export const InfoSeverityIcon = ({ severity }: { severity: Severity }) => {
    if (severity == Severity.Done) {
        return <CheckCircleIcon className="text-[green]" />
    }else if (severity == Severity.Low) {
        return <ArrowDropDownCircleIcon className="text-[#89CFF0]" />
    }  else if (severity == Severity.Medium) {
        return <AccessTimeFilledIcon className="text-[#FFA500]" />
    } else if (severity == Severity.High) {
        return <ErrorIcon className="text-[red]" />
    } else if (severity == Severity.Info) {
        return <InfoIcon className="text-[#FFA500]" />
    } 
    return <></>
}

export const getSeverityColor = (severity: Severity) => {
    if (severity == Severity.Done) {
        return "green"
    }else if (severity == Severity.Low) {
        return "#89CFF0"
    } else if (severity == Severity.Medium) {
        return "#FFA500";
    }
    else if (severity == Severity.High) {
        return "red"
    }
    return "gray"
}

export const getSeverityBgColor = (severity: Severity) => {
    if (severity == Severity.Done) {
        return "bg-[green]"
    }else if (severity == Severity.Low) {
        return "bg-[#89CFF0]"
    } else if (severity == Severity.Medium) {
        return "bg-[#FFA500]";
    }
    else if (severity == Severity.High) {
        return "bg-[red]"
    }
    return "bg-[gray]"
}