import { Modal, Card } from "@mui/material";
import { RJSFSchema, ObjectFieldTemplateProps, UiSchema } from "@rjsf/utils";
import { getFirestore, addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { CustomButtonFill } from "../components/buttonfill";
import { userKey, userNameKey, PilotLogbookCollection, AircraftCollection, PilotLogbookBase } from "../keys";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import CloseIcon from '@mui/icons-material/Close';
import { useLoadingOverlay } from "../loading";

export const PilotBringforward = (prop: { onClose: (val: boolean) => void }) => {
    const [formData, setFormData] = useState<any>({
        date: new Date().toISOString().split("T")[0]
    })
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const classes = ['col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',
        'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',
        'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',
        'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',
        'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',
        'col-span-3', 'col-span-3',
    ];

    const schema: RJSFSchema = {
        type: 'object',
        properties: {
            "totalTime": {
                type: "number",
                title: "Total Time",
            },
            "sel": {
                type: "number",
                title: "Airplane SEL",
            },
            "mel": {
                type: "number",
                title: "Airplane MEL",
            },
            "rotocraftHelicopter": {
                type: "number",
                title: "Airplane Rotocraft/Helicopter",
            },
            "tailwheel": {
                type: "number",
                title: "Tailwheel",
            },
            "groundTrainer": {
                type: "number",
                title: "Ground Trainer Hours",
            },
            "pic": {
                type: "number",
                title: "PIC",
            },
            "sic": {
                type: "number",
                title: "SIC",
            },
            "night": {
                type: "number",
                title: "Night",
            },
            "solo": {
                type: "number",
                title: "Solo",
            },
            "xCountry": {
                type: "number",
                title: "X-Country",
            },
            "actualInstrument": {
                type: "number",
                title: "Actual Instrument",
            },
            "simulatedInstrument": {
                type: "number",
                title: "Simulated Instrument",
            },
            "instructor": {
                type: "number",
                title: "Instructor/ Duel Given",
            },
            "dualReceived": {
                type: "number",
                title: "Dual Received",
            },
            "dayLandingTotal": {
                type: "number",
                title: "Day Landing Total",
            },
            "nightLandingTotal": {
                type: "number",
                title: "Night Landing Total",
            },
            "instrumentApproachTotal": {
                type: "number",
                title: "Instrument Approach Total",
            },
        },
        "required": [
            "totalTime",
            "sel",
            "mel",
            "rotocraftHelicopter",
            "tailwheel",
            "groundTrainer",
            "pic",
            "sic",
            "night",
            "solo",
            "xCountry",
            "actualInstrument",
            "simulatedInstrument",
            "instructor",
            "dualReceived",
            "dayLandingTotal",
            "nightLandingTotal",
            "instrumentApproachTotal"
        ]
    };

    const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
        return (
            <div className='grid grid-cols-12 gap-4'>
                {props.properties.map((element, index) => (
                    <div key={index} className={classes[index]}>{element.content}</div>
                ))}
            </div>
        );
    }

    const uiSchema: UiSchema = {
        'ui:ObjectFieldTemplate': ObjectFieldTemplate
    };



    return (<Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[1000px]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Bring Forward Pilot Totals</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
            </div>
            <Form schema={schema} validator={validator} uiSchema={uiSchema} formData={formData}
                onSubmit={async (data) => {
                    showOverlay()
                    console.log(data)
                    const pilotData = { ...data.formData, pilotId: localStorage.getItem(userKey), pilot: localStorage.getItem(userNameKey) }

                    await setDoc(doc(db, PilotLogbookBase, localStorage.getItem(userKey)!), pilotData)
                    hideOverlay()
                    prop.onClose(false);
                }} >
                <div className='mt-3'>
                    <CustomButtonFill text='Submit' onClick={() => { }} />
                </div>
            </Form>
        </Card>
    </Modal>)

}