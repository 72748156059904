import { Button, Checkbox, Popover, Skeleton, Switch, Tooltip } from "@mui/material"

import { useEffect, useState } from "react"
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore"
import { AircraftCollection, aircraftShowHide, pilotShowHide, userIdKey, userKey, UsersCollection } from "../../../keys"
import { CustomButton } from "../../../components/button"
import { cn } from "../../../userData/userData"
import { AirCraftItem } from "./aircraft"
import { PilotItem } from "./pilot"
import { AddAircraft } from "../../addAircraft"
import TuneIcon from '@mui/icons-material/Tune';
import { AddPilotLog } from "../pilotLogbooks"
import AddFlightIcon from '../../../assets/icons/AddFlightIcon.svg';
import AddAircraftIcon from '../../../assets/icons/AddAircraftIcon.svg';

export const DashboardInner = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openFlightModal, setOpenFlightModal] = useState(false);
    const [aircrafts, setAircrafts] = useState<any[]>([])
    const [pilots, setPilots] = useState<any[]>([])

    const [aircraftsLoaded, setAircraftsLoaded] = useState(false)
    const [pilotsLoaded, setPilotsLoaded] = useState(false)

    const [selectedAircraft, setSerlectedAircraft] = useState<any>({});

    const [isDiv1Visible, setDiv1Visible] = useState(true);
    const [isDiv2Visible, setDiv2Visible] = useState(true);

    const [aircraftsShow, setAircraftsShow] = useState<any[]>(() => {
        const stored = localStorage.getItem(aircraftShowHide);
        return stored ? JSON.parse(stored) : [];
    })
    const [pilotsShow, setPilotsShow] = useState<any[]>(() => {
        const stored = localStorage.getItem(pilotShowHide);
        return stored ? JSON.parse(stored) : [];
    })
    const [rerenderMe, setRerenderMe] = useState("me")

    useEffect(() => {
        getAllAircrafts()
        getAllPilots()
    }, [])

    useEffect(() => {
        getAllAircrafts()
    }, [openModal])

    const getAllAircrafts = async () => {
        const db = getFirestore()

        const data: any[] = [];

        const querySnapshot = await getDocs(collection(db, AircraftCollection));
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });

        setAircrafts(data);
        setAircraftsLoaded(true)
    }

    const getAllPilots = async () => {
        const db = getFirestore()

        const data: any[] = [];

        const q = query(collection(db, UsersCollection), where("type", "in", ["Pilot", "PilotAndMaintainer"]));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        setPilots(data);
        setPilotsLoaded(true)
    }

    // ########## select hide
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);

    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'simple-popover' : undefined;
    //###############

    return <>
        <div className="flex gap-4 justify-end pr-5 mt-[-25px]">

            <label>
                Show Aircraft:
                <Switch defaultChecked onChange={() => setDiv1Visible(!isDiv1Visible)} />
            </label>
            <label>
                Show Pilots:
                <Switch defaultChecked onChange={() => setDiv2Visible(!isDiv2Visible)} />
            </label>
        </div>

        <div className={cn("grid  gap-[30px] m-5 ", `grid-cols-${isDiv1Visible && isDiv2Visible ? '2' : '1'}`)}>

            {isDiv1Visible && <div className="flex flex-col col-span-1">
                <div className="flex items-center justify-center mb-3 ">
                    <Tooltip title="Add New Aircraft" arrow>
                        <div className="w-[55px] cursor-pointer" onClick={() => setOpenModal(true)}><img src={AddAircraftIcon} /></div>
                    </Tooltip>
                    <span className="font-[500] text-[25px] text-[#222222] text-center mb-2 ml-2">Select Aircraft
                        <Button aria-describedby={id1} onClick={handleClick1}>
                            <TuneIcon className="ml-3 text-blue-500 cursor-pointer" />
                        </Button>
                        <Popover
                            id={id1}
                            open={open1}
                            anchorEl={anchorEl1}
                            onClose={handleClose1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="p-3">
                                {aircraftsLoaded && aircrafts.map((a, index) => <p key={index}><Checkbox
                                    edge="start"
                                    checked={aircraftsShow.includes(a.aircraftId)}
                                    tabIndex={-1}
                                    onChange={(e) => {
                                        console.log(a.aircraftId, aircraftsShow)
                                        const ac = e.target.checked ? [...aircraftsShow, a.aircraftId] : aircraftsShow.filter(e => e != a.aircraftId)
                                        setAircraftsShow(ac)
                                        localStorage.setItem(aircraftShowHide, JSON.stringify(ac))
                                    }}
                                /> {a.aircraftId}</p>)}
                            </div>
                        </Popover>
                    </span>
                </div>

                {aircrafts.map((a, index) => aircraftsShow.includes(a.aircraftId) || aircraftsShow.length == 0 ? <AirCraftItem key={index} aircraft={a} getAll={getAllAircrafts} /> : <div></div>)}
                {
                    !aircraftsLoaded && <div>
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                    </div>
                }

                <div className="pt-3 mb-3">
                    {/* <CustomButton text="Add New Aircraft" onClick={() => setOpenModal(true)} /> */}
                    {/* <Tooltip title="Add New Aircraft" arrow>
                        <div className="w-[55px] cursor-pointer" onClick={() => setOpenModal(true)}><img src={AddAircraftIcon} /></div>
                    </Tooltip> */}

                </div>

                {openModal && <AddAircraft onClose={(val: boolean) => {
                    setOpenModal(val);
                    getAllAircrafts();
                }} data={{}} isEditMode={false} />}
            </div>}
            {isDiv2Visible && <div className="flex flex-col  col-span-1">
                <div className="flex items-center justify-center mb-3 ">
                    <Tooltip title="Add New Flight" arrow>
                        <div className="w-[55px] cursor-pointer" onClick={() => setOpenFlightModal(true)}><img src={AddFlightIcon} /></div>
                    </Tooltip>
                    <span className="font-[500] text-[25px] text-[#222222] mb-2 text-center ml-2">Select Pilot

                        <Button aria-describedby={id} onClick={handleClick}>
                            <TuneIcon className="ml-3 text-blue-500 cursor-pointer" />
                        </Button>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="p-3">
                                {pilotsLoaded && pilots.map((a, index) => <p key={index}><Checkbox

                                    edge="start"
                                    checked={pilotsShow.includes(a.email)}
                                    tabIndex={-1}
                                    onChange={(e) => {
                                        console.log(a.email, pilotsShow)
                                        const ps = e.target.checked ? [...pilotsShow, a.email] : pilotsShow.filter(e => e != a.email)
                                        setPilotsShow(ps)
                                        localStorage.setItem(pilotShowHide, JSON.stringify(ps))
                                    }}
                                /> {a.firstName} {a.lastName}</p>)}
                            </div>
                        </Popover>
                    </span>
                </div>
                {pilots.map((a, index) => pilotsShow.includes(a.email) || pilotsShow.length == 0 ? <PilotItem pilot={a} key={index}
                    onChange={() => { getAllPilots() }} rerenderKey={a.email == localStorage.getItem(userKey) ? rerenderMe : a.email}
                /> : <div></div>)}
                {
                    !pilotsLoaded && <div>
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                        <Skeleton variant="rectangular" className="mt-2" height={65} />
                    </div>
                }

                <div className="pt-3 mb-3">
                    {/* <CustomButton text="Add New Flight" onClick={() => setOpenFlightModal(true)} /> */}
                    {/* <Tooltip title="Add New Flight" arrow>
                        <div className="w-[55px] cursor-pointer" onClick={() => setOpenFlightModal(true)}><img src={AddFlightIcon} /></div>
                    </Tooltip> */}

                </div>

                {openFlightModal && <AddPilotLog onClose={(val: boolean) => {
                    setOpenFlightModal(val)
                    getAllAircrafts()
                    setRerenderMe("me" + Math.random())
                }} openAddAircraft={() => setOpenModal(true)} />}
            </div>}
        </div>

    </>
}
