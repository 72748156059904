import { CircularProgress } from '@mui/material';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useState, useEffect } from 'react';

export const FirebaseImage = ({ img, classes }: { img: string, classes: string }) => {
    const [imageUrl, setImageUrl] = useState('');
    const storage = getStorage();

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                const url = await getDownloadURL(ref(storage, img))
                setImageUrl(url);
            } catch (error) {
                // console.error('Error fetching image URL:', error);
            }
        };
console.log(imageUrl)
        fetchImageUrl();
    }, [img]);

    return (
        <div className={classes}>
            {imageUrl ? (
                <img src={imageUrl} key={imageUrl} alt="Firebase Image" className='max-h-[55px]'/>
            ) : (
                <CircularProgress color="inherit" className='p-1 mt-1'/>
            )}
        </div>
    );
};
