import { Alert, Box, Button, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material"
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import CloseIcon from '@mui/icons-material/Close';
import { CustomButtonFill } from "../../components/buttonfill"
import { AircraftCollection, MxLogbookCollection, MxLogbookTemplateCollection, SquawkCollection } from "../../keys"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { logBookCategory } from "../../dropdownItems"
import { useLoadingOverlay } from "../../loading"
import { formatDateToYYYYMMDDToday, getDateDiff } from "../../utils/dateutils"
import { SquawksModal } from "../squawk/squawksModal";
import { CustomButton } from "../../components/button";
import { toFixedNumber } from "../../utils/numberUtils";

export const MxLogbooks = () => {
    const [logs, setLogs] = useState<any[]>([]);
    const [loaded, setLoaded] = useState(false)
    const { aircraftId } = useParams();

    const [aircraft, setAircraft] = useState<any>();

    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllLogs()
        getAircraft()
    }, [])

    const getAircraft = async () => {
        showOverlay()
        const db = getFirestore()
        const docRef = doc(db, AircraftCollection, aircraftId!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setAircraft(docSnap.data())
        }

        hideOverlay()
    }

    const getAllLogs = async () => {
        showOverlay()
        const db = getFirestore()

        const data: any[] = [];

        const q = query(collection(db, MxLogbookCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        setLogs(data);
        setLoaded(true)
        hideOverlay()
    }

    return (<>
        <div>
            <Card className="mt-3 pt-3">
                <div className="font-[700] text-[22px] text-[#85144B] p-3">{aircraft?.aircraftId} MX logbook</div>
                <table className="pt-5 w-[100%] border-2 border-[#CCCCCC]">
                    <tbody>
                        <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Tach Time</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Hobbs</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Logbook Category</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Description</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainer Full Legal Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainer First Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">MI</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainer Last Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Certificate</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">IA</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">A&P</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Owner</th>
                        </tr>
                        {
                            logs.map((log, index) => <tr className="h-[57px] text-center  border">
                                <td className="border text-[8px]">{log?.date}</td>
                                <td className="border text-[8px]">{log?.tachtime?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.hobbs?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.logbookCategory}</td>
                                <td className="border text-[8px]">{log?.description}</td>
                                <td className="border text-[8px]">{log?.maintainerFullLegalName}</td>
                                <td className="border text-[8px]">{log?.maintainerFirstName}</td>
                                <td className="border text-[8px]">{log?.mi}</td>
                                <td className="border text-[8px]">{log?.maintainerLastName}</td>
                                <td className="border text-[8px]">{log?.certificate}</td>
                                <td className="border text-[8px]">{log?.ia ? 'YES' : 'NO'}</td>
                                <td className="border text-[8px]">{log?.aAndP ? 'YES' : 'NO'}</td>
                                <td className="border text-[8px]">{log?.owner ? 'YES' : 'NO'}</td>
                            </tr>)
                        }
                    </tbody>

                </table>
            </Card>
        </div>
    </>)
}

export const AddMxLog = (prop: {
    aircraftId: string, onClose: (val: boolean) => void, descriptionPreFill?: string, squawk?: any[], ad?: any[]
}) => {
    const [aircraft, setAircraft] = useState<any>();
    const [formValues, setFormValues] = useState({
        date: new Date().toISOString().split("T")[0],
        ia: false,
        aAndP: false,
        owner: false,
        tachtime: 0,
        tachtime2: 0,
        "hobbs": 0,
        "hobbs2": 0,
        "logbookCategory": [],
        "fromTemplate": null,
        "description": prop.descriptionPreFill ?? "",
        "saveAsTemplate": false,
        "templateName": null,
        "maintainerFullLegalName": null,
        "maintainerFirstName": null,
        "mi": null,
        "maintainerLastName": null,
        "certificate": null,
        oilChange: false,
        oilChange_engine2: false
    });

    const [templates, setTemplates] = useState<any[]>([]);
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [currentTachDiff, setCurrentTachDiff] = useState(0)
    const [currentTachDiff2, setCurrentTachDiff2] = useState(0)
    const [minTach, setMinTach] = useState(0);
    const [minTach2, setMinTach2] = useState(0);
    const [customErrors, setcustomErrors] = useState("");
    const [showSquawks, setShowSquawks] = useState(false);

    const TachtimeError = "Tach time should be higher than current Tach value. Please select a past date to add a historical data."

    useEffect(() => {
        getAllTemplate()
        setCurrentTachDiff(0)
        setCurrentTachDiff2(0)
        getAircraft()
    }, [])

    const getAllTemplate = async () => {

        const data: any[] = [];

        const q = query(collection(db, MxLogbookTemplateCollection));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        setTemplates(data);
    }

    const getAircraft = async () => {
        showOverlay()
        const db = getFirestore()
        const docRef = doc(db, AircraftCollection, prop.aircraftId!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var data = docSnap.data()
            setAircraft(data)
            setMinTach(data.currentTach)
            setMinTach2(data.currentTach_engine2 ?? 0)
            setFormValues({
                ...formValues,
                tachtime: data.currentTach,
                tachtime2: data.currentTach_engine2 ?? 0,
            })
        }

        hideOverlay()
    }

    const [formErrors, setFormErrors] = useState({
        date: false,
        ia: false,
        aAndP: false,
        owner: false,
        tachtime: false,
        tachtime2: false,
        "hobbs": false,
        "hobbs2": false,
        "logbookCategory": false,
        "fromTemplate": false,
        "description": false,
        "saveAsTemplate": false,
        "templateName": false,
        "maintainerFullLegalName": false,
        "maintainerFirstName": false,
        "mi": false,
        "maintainerLastName": false,
        "certificate": false
    });

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (typeof (value) != "object") {
            if (value.trim() === '') {
                setFormErrors({
                    ...formErrors,
                    [name]: true,
                });
            } else {
                setFormErrors({
                    ...formErrors,
                    [name]: false,
                });
            }
        }

        var custom = {}
        // custom updates
        if (name == 'fromTemplate') {
            const tempData = templates.find(a => a.templateName === value)
            custom = {
                description: tempData.value
            }
        }

        setFormValues({
            ...formValues,
            ...custom,
            [name]: value,
        });

        if (name == "date") {
            if (getDateDiff(formatDateToYYYYMMDDToday(), value) >= 0) {
                setMinTach(aircraft?.currentTach)
                setMinTach2(aircraft?.currentTach_engine2 ?? 0)
                if (
                    formValues.tachtime < minTach
                ) {
                    setcustomErrors(TachtimeError)
                } else if (
                    formValues.tachtime2 < minTach2
                ) {
                    setcustomErrors(TachtimeError)
                } else {
                    setcustomErrors("")
                }
            }
            else {
                setMinTach(0)
                setMinTach2(0)
            }
        }
    };

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;
        if (name == "tachtime") {
            if ( value > 0) {
                setCurrentTachDiff(value - aircraft?.currentTach)
            }

            if (
                value < minTach
            ) {
                setcustomErrors(TachtimeError)
            } else {
                setcustomErrors("")
            }
        }

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleChangeNumber2 = (e: any) => {

        const { name, value } = e.target;
        if (name == "tachtime2") {
            if ( value > 0) {
                setCurrentTachDiff2(value - aircraft?.currentTach_engine2)
            }

            if (
                value < minTach2
            ) {
                setcustomErrors(TachtimeError)
            } else {
                setcustomErrors("")
            }
        }

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        showOverlay()
        const flight = { ...formValues, aircraftId: prop.aircraftId }

        var res = await addDoc(collection(db, MxLogbookCollection), flight);

        if (flight.saveAsTemplate) {
            const temp = {
                templateName: flight.templateName,
                value: flight.description
            }

            await addDoc(collection(db, MxLogbookTemplateCollection), temp);
        }

        // update aircraft here
        if (getDateDiff(formatDateToYYYYMMDDToday(), formValues.date) >= 0) {
            // const docRef = doc(db, AircraftCollection, prop.aircraftId);
            // const docSnap = await getDoc(docRef);
            // let aircraftData = docSnap.data();
            var aircraftData: any = {
                ...aircraft,
                currentTach: toFixedNumber(flight.tachtime),
                currentTach_engine2: toFixedNumber(flight.tachtime2),
                hobbs: toFixedNumber(flight.hobbs) ?? aircraft?.hobbs,
                hobbs2: toFixedNumber(flight.hobbs2) ?? aircraft?.hobbs2,
            }

            if (flight.oilChange) {
                aircraftData = {
                    ...aircraft,
                    lastOilChangeDate: flight.date,
                    tachTimeAtLastOilChange: toFixedNumber(flight.tachtime)
                }
            }
            if (flight.oilChange_engine2) {
                aircraftData = {
                    ...aircraft,
                    lastOilChangeDate_engine2: flight.date,
                    tachTimeAtLastOilChange_engine2: toFixedNumber(flight.tachtime2)
                }
            }
            await setDoc(doc(db, AircraftCollection, prop.aircraftId), aircraftData)
        }

        // Fix squawk
        await Promise.all(
            (prop.squawk ?? []).map(async (squawk: any) => {
                if (squawk !== null && squawk !== undefined) {
                    let squawkToSave = {
                        ...squawk,
                        status: "Completed",
                        mxId: res.id
                    }

                    return await setDoc(doc(db, SquawkCollection, squawk.id), squawkToSave)
                }
            }))

        // Fix AD
        await Promise.all((prop.ad ?? []).map(async (adItem: any) => {
            if (adItem !== null && adItem !== undefined) {
                let squawkToSave = {
                    ...adItem,
                    status: "Completed"
                }

                await setDoc(doc(db, SquawkCollection, adItem.id), squawkToSave)
            }
        }))

        hideOverlay()
        prop.onClose(false);
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return (<Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[90vw]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <div className="flex mb-5 gap-5">
                    <h1 className="text-3xl">Add MX Log</h1>
                    <CustomButton text='SQUAWKS' onClick={() => { setShowSquawks(true) }} />
                </div>

                <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
            </div>
            <h4 className=""><b>Aircraft Id:</b> {aircraft?.aircraftId} |  <b>AIRFRAME TT (Total Time):</b> {(aircraft?.totalTime + (currentTachDiff > currentTachDiff2 ? currentTachDiff : currentTachDiff2)).toFixed(1)} | <b>ENGINE TSMOH:</b>  {(aircraft?.tsmoh + currentTachDiff).toFixed(1)}<sup>(E1)</sup> {aircraft?.engineMoake_engine2 ? `, ${(aircraft?.tsmoh_engine2 + currentTachDiff2).toFixed(1)}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</h4>

            {customErrors != "" && <Alert severity="error"><span className="text-[red] mb-4">{customErrors}</span></Alert>}
            <div className='h-[80vh] overflow-scroll px-3 pt-2'>
                <Box
                    component="form"
                    sx={{ flexGrow: 1 }}
                    onSubmit={handleSubmit}
                    className="mt-4"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                type="date"
                                name="date"
                                value={formValues.date}
                                onChange={handleChange}
                                label="Date"
                                error={formErrors.date}
                                helperText={formErrors.date ? 'Date is required' : ''}
                                className="w-full"
                                InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Logbook Category</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    name="logbookCategory"
                                    value={formValues.logbookCategory}
                                    label="Select Option"
                                    onChange={handleChange}
                                    required
                                    multiple
                                    className="h-[50px]"
                                    sx={{ fontSize: '0.8rem' }}
                                >
                                    {logBookCategory.map(l => <MenuItem value={l.value}>{l.value}</MenuItem>)}
                                    {aircraft?.engineMoake_engine2 && <MenuItem value={"Engine 2"}>{"Engine 2"}</MenuItem>}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <p className='font-bold mb-[-9px]'>
                                Engine 1
                            </p>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Tach Time"
                                name="tachtime"
                                type="number"
                                value={formValues.tachtime}
                                onChange={handleChangeNumber}
                                error={formErrors.tachtime}
                                helperText={formErrors.tachtime ? 'Tach Time is required' : ''}
                                required
                                className="w-full"
                                // inputProps={{ min: minTach }}

                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Hobbs"
                                name="hobbs"
                                type="number"
                                value={formValues.hobbs}
                                onChange={handleChangeNumber}
                                // error={formErrors.hobbs}
                                className="w-full"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={formValues.oilChange} onChange={handleChangeCheckbox} name="oilChange" />}
                                label="Engine 1 Oil change"
                            />
                        </Grid>

                        {aircraft?.engineMoake_engine2 && <Grid item xs={12}>
                            <p className='font-bold mb-[-9px]'>
                                Engine 2
                            </p>
                        </Grid>}
                        {aircraft?.engineMoake_engine2 && <Grid item xs={3}>
                            <TextField
                                label="Tach Time"
                                name="tachtime2"
                                type="number"
                                value={formValues.tachtime2}
                                onChange={handleChangeNumber2}
                                error={formErrors.tachtime}
                                helperText={formErrors.tachtime2 ? 'Tach Time is required' : ''}
                                required
                                className="w-full"
                                // inputProps={{ min: minTach2 }}

                            />
                        </Grid>}
                        {aircraft?.engineMoake_engine2 && <Grid item xs={3}>
                            <TextField
                                label="Hobbs"
                                name="hobbs"
                                type="number"
                                value={formValues.hobbs2}
                                onChange={handleChangeNumber2}
                                // error={formErrors.hobbs}
                                className="w-full"
                            />
                        </Grid>}
                        {
                            aircraft?.engineMoake_engine2 && <Grid item xs={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={formValues.oilChange_engine2} onChange={handleChangeCheckbox} name="oilChange_engine2" />}
                                    label="Engine 2 Oil change"
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Select From Template</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    name="fromTemplate"
                                    value={formValues.fromTemplate}
                                    label="Select Option"
                                    onChange={handleChange}
                                    className="h-[50px] text-[10px]"
                                    sx={{ fontSize: '0.8rem' }}
                                >
                                    {templates.map(l => <MenuItem value={l.templateName}>{l.templateName}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                name="description"
                                value={formValues.description}
                                onChange={handleChange}
                                error={formErrors.description}
                                className="w-full custom-textarea"
                                rows={10}
                                multiline
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={formValues.saveAsTemplate} onChange={handleChangeCheckbox} name="saveAsTemplate" />}
                                label="Save as a Template"
                            />
                        </Grid>

                        <Grid item xs={9}>
                            <TextField
                                label="Template Name (Applicable if save as a Template)"
                                name="templateName"
                                value={formValues.templateName}
                                onChange={handleChange}
                                error={formErrors.templateName}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Maintainer Full Legal Name"
                                name="maintainerFullLegalName"
                                value={formValues.maintainerFullLegalName}
                                onChange={handleChange}
                                error={formErrors.maintainerFullLegalName}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Maintainer First Name"
                                name="maintainerFirstName"
                                value={formValues.maintainerFirstName}
                                onChange={handleChange}
                                error={formErrors.maintainerFirstName}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="MI"
                                name="mi"
                                value={formValues.mi}
                                onChange={handleChange}
                                error={formErrors.mi}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Maintainer Last Name"
                                name="maintainerLastName"
                                value={formValues.maintainerLastName}
                                onChange={handleChange}
                                error={formErrors.maintainerLastName}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Certificate"
                                name="certificate"
                                value={formValues.certificate}
                                onChange={handleChange}
                                error={formErrors.certificate}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={formValues.ia} onChange={handleChangeCheckbox} name="ia" />}
                                label="IA"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={formValues.aAndP} onChange={handleChangeCheckbox} name="aAndP" />}
                                label="A&P"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={formValues.owner} onChange={handleChangeCheckbox} name="owner" />}
                                label="Owner"
                            />
                        </Grid>
                    </Grid>



                    <div className="mt-5 flex gap-3">
                        <CustomButtonFill text='Submit' onClick={() => { }} />

                    </div>
                </Box>
            </div>
            {showSquawks && <SquawksModal aircraftId={prop.aircraftId} onClose={() => { setShowSquawks(false) }} />}
        </Card>
    </Modal>)

}

const SampleForm = () => {
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: false,
        email: false,
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });

        if (value.trim() === '') {
            setFormErrors({
                ...formErrors,
                [name]: true,
            });
        } else {
            setFormErrors({
                ...formErrors,
                [name]: false,
            });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // Validate form fields
        const errors = {
            name: formValues.name.trim() === '',
            email: formValues.email.trim() === '',
        };
        setFormErrors(errors);

        const hasErrors = Object.values(errors).some(error => error);

        if (!hasErrors) {
            console.log('Form Submitted:', formValues);
            // Perform further actions (e.g., API calls)
        }
    };

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', width: '300px', margin: '0 auto' }}
            onSubmit={handleSubmit}
        >
            <TextField
                label="Name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                error={formErrors.name}
                helperText={formErrors.name ? 'Name is required' : ''}
                required
                margin="normal"
            />

            <TextField
                label="Email"
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleChange}
                error={formErrors.email}
                helperText={formErrors.email ? 'Email is required' : ''}
                required
                margin="normal"
            />

            <Button variant="contained" type="submit" sx={{ marginTop: '16px' }}>
                Submit
            </Button>
        </Box>
    );
};
