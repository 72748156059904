import { Card } from "@mui/material"
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ADCompliancesCollection, AircraftADCompliancesCollection, AircraftCollection } from "../../keys";
import { useLoadingOverlay } from "../../loading";
import { AdSummary } from "./adSummary";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const AdCompliances = () => {
    const [airframeAds, setAirframeAds] = useState<any[]>([]);
    const [engineAds, setEngineAds] = useState<any[]>([]);
    const [propellerAds, setPropellerAds] = useState<any[]>([]);
    // const [adDocs, setAdDocs] = useState<any[]>([]);
    const { aircraftId } = useParams();
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [aircraft, setAircraft] = useState<any>();
    const [showSummary, setShowSummary] = useState(false);
    const [selectedAd, setSelectedAd] = useState<any | null>();

    useEffect(() => {
        getAllAds()
        // getAllAdRefs()
        getAircraft()
        // var str = "```json\n{\n  \"summary\": \"This Airworthiness Directive (AD) mandates the inspection of the engine control cable support bracket (P/N 17892-00) to prevent failure due to cracks, distortion, or insecure attachment in Piper PA-23 and PA-23-160 aircraft. The initial inspection should occur within 50 hours of service time for aircraft with less than 2000 total hours. Recurrent inspections are required every 100 service hours unless an improved bracket (Piper P/N 16975-00) is installed, after which repeated inspections are no longer necessary.\",\n  \"dueDate\": \"20-12-2024\",\n  \"isRecurring\": \"Yes\"\n}\n```"
        // str = str.replace("```json\n", "")
        // str = str.replace("\n```", "")
        // console.log(JSON.parse(str))
    }, [])

    const getAircraft = async () => {
        showOverlay()
        const db = getFirestore()
        const docRef = doc(db, AircraftCollection, aircraftId!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setAircraft({ ...docSnap.data(), id: docSnap.id })
        }

        hideOverlay()
    }

    const getAllAds = async () => {
        showOverlay()
        const db = getFirestore()
        const data: any[] = [];

        const q = query(collection(db, AircraftADCompliancesCollection), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });

        setAirframeAds(data.filter(a => a.type == "Aircraft"));

        setEngineAds(data.filter(a => a.type == "Engine" || a.type == "Engine2"));
        // engineAdsT.map(a => a.adDoc = adDocdata.find(d => d.documentGuid == a.adId))
        setPropellerAds(data.filter(a => a.type == "Propeller" || a.type == "Propeller"));

        hideOverlay()
    }

    // const getAllAdRefs = async () => {
    //     const db = getFirestore()
    //     const data: any[] = [];

    //     const q = query(collection(db, ADCompliancesCollection));
    //     const querySnapshot = await getDocs(q);
    //     querySnapshot.forEach((doc) => {
    //         data.push({ ...doc.data(), id: doc.id });
    //     });

    //     setAdDocs(data)
    // }

    return <>
        <div>
            <Card className="mt-3 pt-3">
                <div className="font-[700] text-[22px] text-[#85144B] p-3">{aircraft?.aircraftId} AD Compliances</div>
                <span className="font-bold ml-3">Airframe Model: {aircraft?.model} S/N: {aircraft?.aircraftSerialNumber}</span>
                <table className="pt-5 w-[100%] border-2 border-[#CCCCCC] ">
                    <tbody>
                        <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Ad Number</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Effective Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Subject</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Due date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Compliance Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Action Taken</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border max-w-[15vw]">Recurring</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainers Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainers license certificate #</th>
                        </tr>
                        {
                            airframeAds.map((log, index) => {
                                return <tr className="h-[57px] text-center  border">
                                    <td className="border text-[12px]">
                                        <p className="text-gray-700 text-[12px]">
                                            <a href={log?.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                                <b>{log?.documentNumber}</b>
                                            </a>
                                        </p>
                                    </td>
                                    <td className="border text-[12px]">{log?.effectiveDate?.split("T")[0]}</td>
                                    <td className="border text-[12px]">
                                        <div className="text-center" onClick={() => {
                                            setSelectedAd(log)
                                            setShowSummary(true)
                                        }}>
                                            {log?.adfrawdSubject} <OpenInNewIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" /></div>
                                    </td>
                                    <td className="border text-[12px]">{log.dueDateExplanation ?? "-"}</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px] max-w-[15vw]">{!log.dueDateExplanation ? "-" : log.isRecurring ? "Yes" : "No"} {log.recurringFrequency ? '(' + log.recurringFrequency + ')' : ""}</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px]">TBD</td>
                                </tr>
                            })
                        }
                    </tbody>

                </table>

                <div className="h-[15px]"></div>
                <span className="font-bold mt-3 ml-3">Engine Model: {aircraft?.engineModel} S/N: {aircraft?.engineSerialNumber}</span>
                <table className="pt-5 w-[100%] border-2 border-[#CCCCCC]">
                    <tbody>
                        <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Ad Number</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Effective Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Subject</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Due date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Compliance Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Action Taken</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border max-w-[15vw]">Recurring</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainers Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainers license certificate #</th>
                        </tr>
                        {
                            engineAds.map((log, index) => {
                                return <tr className="h-[57px] text-center  border">
                                    <td className="border text-[12px]">
                                        <p className="text-gray-700 text-[12px]">
                                            <a href={log?.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                                <b>{log?.documentNumber}</b>
                                            </a>
                                        </p>
                                    </td>
                                    <td className="border text-[12px]">{log?.effectiveDate?.split("T")[0]}</td>
                                    <td className="border text-[12px]">
                                        <div className="text-center" onClick={() => {
                                            setSelectedAd(log)
                                            setShowSummary(true)
                                        }}>
                                            {log?.adfrawdSubject} <OpenInNewIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" /></div>
                                    </td>
                                    <td className="border text-[12px]">{log.dueDateExplanation ?? "-"}</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px] max-w-[15vw]">{!log.dueDateExplanation ? "-" : log.isRecurring ? "Yes" : "No"} {log.recurringFrequency ? '(' + log.recurringFrequency + ')' : ""}</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px]">TBD</td>
                                </tr>
                            })
                        }
                    </tbody>

                </table>

                <div className="h-[15px]"></div>
                <span className="font-bold pt-3 ml-3">Propeller Model: {aircraft?.propellerModel} Hub S/N: {aircraft?.propellerHubSerialNumber} Prop1 S/N: {aircraft?.prop1SerialNumber} Prop2 S/N: {aircraft?.prop2SerialNumber} Prop3 S/N: {aircraft?.prop3SerialNumber}</span>
                <table className="pt-5 w-[100%] border-2 border-[#CCCCCC]">
                    <tbody>
                        <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Ad Number</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Effective Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Subject</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Due date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Compliance Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Action Taken</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border max-w-[15vw]">Recurring</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainers Name</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Maintainers license certificate #</th>
                        </tr>
                        {
                            propellerAds.map((log, index) => {

                                return <tr className="h-[57px] text-center  border">
                                    <td className="border text-[12px]">
                                        <p className="text-gray-700 text-[12px]">
                                            <a href={log?.documentURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                                <b>{log?.documentNumber}</b>
                                            </a>
                                        </p>
                                    </td>
                                    <td className="border text-[12px]">{log?.effectiveDate?.split("T")[0]}</td>
                                    <td className="border text-[12px]">
                                        <div className="text-center" onClick={() => {
                                            setSelectedAd(log)
                                            setShowSummary(true)
                                        }}>
                                            {log?.adfrawdSubject} <OpenInNewIcon sx={{ width: "15px" }} className="ml-3 p-0 cursor-pointer" /></div>
                                    </td>
                                    <td className="border text-[12px]">{log.dueDateExplanation ?? "-"}</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px] max-w-[15vw]">{!log.dueDateExplanation ? "-" : log.isRecurring ? "Yes" : "No"} {log.recurringFrequency ? '(' + log.recurringFrequency + ')' : ""}</td>
                                    <td className="border text-[12px]">TBD</td>
                                    <td className="border text-[12px]">TBD</td>
                                </tr>
                            })
                        }
                    </tbody>

                </table>
            </Card>

            {
                showSummary && <AdSummary log={selectedAd} onClose={() => {
                    setSelectedAd(null)
                    setShowSummary(false)
                }} />
            }
        </div>
    </>
}