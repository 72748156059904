import { Alert, Avatar, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Modal } from "@mui/material"

import Avatarimg from '../assets/images/avatarimg.svg';

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardInner } from "./DashboardComponents/DashboardInner/dashboardInner";
import { AdCompliances } from "./ad/adCompliances";
import { PilotLogbooks } from "./DashboardComponents/pilotLogbooks";
import { getAuth } from "firebase/auth";
import { PilotLogbookBase, userKey, userNameKey, UsersCollection } from "../keys";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import Logo from '../assets/images/logo.svg';
import { FirebaseImage } from "../components/firebaseImage";
import { PilotBringforward } from "./pilotBringForward";
import { CustomButtonFill } from "../components/buttonfill";
import { CustomButton } from "../components/button";
import { MxLogbooks } from "./DashboardComponents/mxLogbook";
import { UserDataFormDialog } from "../userData/userDataformDialog";
import { useLoadingOverlay } from "../loading";

export const Dashboard = () => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [user, setUser] = useState<any>()
    const [pilotBaseData, setPilotBaseData] = useState<any>();
    const [bringPilotForward, setBringPilotForward] = useState(false)
    const [openPilotBringForward, setOpenPilotBringForward] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [key, setKey] = useState('dashboard')

    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, [])

    useEffect(() => {
        if (user && (user.type == "Pilot" || user.type == "PilotAndMaintainer")) {
            getUserBase();
        }
    }, [user])

    const getUser = async () => {
        showOverlay()
        const db = getFirestore()
        if (localStorage.getItem(userKey)) {
            const docRef = doc(db, UsersCollection, localStorage.getItem(userKey)!);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUser(docSnap.data())
                localStorage.setItem(userNameKey, `${docSnap.data()?.firstName} ${docSnap.data()?.lastName}`)
                hideOverlay()
            } else {
                navigate("/profile")
                hideOverlay()
            }
        }
    }

    const getUserBase = async () => {
        const db = getFirestore()

        const docRef = doc(db, PilotLogbookBase, localStorage.getItem(userKey)!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setPilotBaseData(docSnap.data())
        } else {
            setOpenPilotBringForward(true)
        }
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpenPilotBringForward = () => {
        setOpenPilotBringForward(true);
    };

    const handleClosePilotBringForward = () => {
        setOpenPilotBringForward(false);
    };

    return (
        <>
            <Card className="m-5 p-5 min-h-[96vh] w-[100vw] ">
                <div className="flex">
                    <img src={Logo} alt="Air Loggbooks" className="w-[150px] cursor-pointer" onClick={() => navigate("/")}/>
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between w-[85vw]">
                            <div className="flex flex-col mt-[-15px]">
                                <span className="text-[2.5rem] text-[#235e8a] ml-[30px] p-[0px]">Fly Smart - Maintain Easy - Comply with Confidence</span>
                            </div>
                            <div className="flex float-right">


                                <div>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        {user?.image ? (
                                            <Avatar alt="Remy Sharp"  >
                                                <FirebaseImage img={user.image} classes="" />
                                            </Avatar>
                                        ) : (
                                            <Avatar alt="Remy Sharp" src={Avatarimg} />
                                        )}
                                        <div className="ml-2 flex flex-col ">
                                            <span className="font-semibold text-[12px]">{user?.firstName} {user?.lastName}</span>
                                            <span className="font-semibold text-[10px]">-</span>
                                        </div>
                                    </IconButton>

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setOpenEditModal(true)
                                        }}>Edit My Profile</MenuItem>
                                        <MenuItem onClick={() => {
                                            localStorage.clear()
                                            auth.signOut()
                                        }}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[1px] ml-[30px] border-[#235e8a]"></div>
                    </div>
                </div>

                <div className="w-[100%]">
                    {openPilotBringForward &&
                        <Dialog
                            open={openPilotBringForward}
                            onClose={() => setOpenPilotBringForward(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Great! Welcome to Airlogbooks.com!"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Now, grab your current logbook and let’s get your pilot history up to date. Click here to bring forward your logbook totals.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <CustomButton onClick={() => {
                                    setOpenPilotBringForward(false)
                                }} text="Later" />
                                <CustomButtonFill onClick={() => {
                                    setOpenPilotBringForward(false)
                                    setBringPilotForward(true)
                                }} text="continue" />
                            </DialogActions>
                        </Dialog>
                        // <div className="mx-5 mt-2 mb-2">
                        //     <Alert severity="info" className="cursor-pointer" onClick={() => { setBringPilotForward(true) }}>
                        //         Great! Welcome to Airlogbooks.com! Now, grab your current logbook and let’s get your pilot history up to date. Click here to bring forward your logbook totals.
                        //     </Alert>
                        // </div>
                    }

                    {componentToRender(location.pathname, key)}
                </div>
            </Card>
            {bringPilotForward && <PilotBringforward onClose={setBringPilotForward} />}
            {openEditModal && <UserDataFormDialog userData={user} onClose={(val) => {
                setOpenEditModal(val)
                setKey(location.pathname + Math.random().toString(36).substring(2, 5))
                getUser()
            }} />}
        </>
    );

    // return (<>
    //     <div className="p-5 bg-[#F8F9FF]">
    //         {loaded && user && <div className="flex flex-row gap-5">
    //             {/* <Sidebar selectedIndex={selected} /> */}
    //             <div className="w-[100%]">
    //                 <Card className="h-[78px] sticky p-4">
    //                     <div className="float-right flex">
    //                         {user?.image ? <Avatar alt="Remy Sharp" src={user?.image} /> : <Avatar alt="Remy Sharp" src={Avatarimg} />}

    //                         <div className="ml-1 flex flex-col align-center">
    //                             <span className="font-semibold text-[12px] ">{user?.firstName} {user?.lastName}</span>
    //                             <span className="font-semibold text-[10px] ">-</span>
    //                         </div>

    //                     </div>
    //                 </Card>

    //                 <div className="w-[100%]">
    //                     {componentToRender(location.pathname)}
    //                 </div>
    //             </div>
    //         </div>}
    //         {!loaded && <div className="flex flex-row gap-3">
    //             <div className="mt-[-240px] w-[256px] h-[96vh]">
    //                 <Skeleton height={1200} />
    //             </div>

    //             <div className="w-[100%]">
    //                 <div>
    //                     <Skeleton height={100} />
    //                 </div>
    //                 <div className="mt-[-110px]">
    //                     <Skeleton height={500} />
    //                 </div>
    //                 <div className="mt-[-200px]">
    //                     <Skeleton height={600} />
    //                 </div>

    //             </div>

    //         </div>}
    //     </div>
    // </>)
}

const componentToRender = (path: string, key: string) => {
    if (path === '/') {
        return <div key={key}><DashboardInner /></div>;
    } else if (path.includes('/mx-log')) {
        return <div key={key}><MxLogbooks /></div>;
    } else if (path.includes('/ad-compliances')) {
        return <div key={key}><AdCompliances /></div>;
    } else if (path === '/pilots') {
        // return <Pilots />;
    } else if (path.includes('/pilot-logbook/')) {
        return <div key={key}><PilotLogbooks /></div>;
    }

    return <div></div>;
};