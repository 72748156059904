import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const SearchableDropdown = (props: any) => {
    const { options, onChange, value, label } = props;

    return (
        <Autocomplete
            options={options.options}
            getOptionLabel={(option) => option}
            onChange={(event, selectedOption) => {
                console.log(selectedOption)
                onChange(selectedOption ? selectedOption : '');
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option === value}
            value={options.options?.find((option: any) => option === value) || null}
            // disableClearable
            freeSolo
            sx={{
                height: '56px', // Adjust the height here
                '& .MuiOutlinedInput-root': {
                    height: '56px', // Ensure input has the same height
                    '& fieldset': {
                        height: '56px', // Adjust fieldset height if needed
                    },
                },
            }}
        />
    );
};