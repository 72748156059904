import { Alert, Card, Link } from '@mui/material';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import './auth.css'

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';
import { userIdKey, userKey } from '../keys';
import Logo from '../assets/images/logo.svg';
import { CustomButtonFill } from '../components/buttonfill';
import { useLoadingOverlay } from '../loading';

export const Signup = () => {
    const [error, setError] = useState<string | null>(null);
    const auth = getAuth();
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const uiSchema = {
        'ui:submitButtonOptions': {
            submitText: 'SignUp',
        },
        "email": {
            "ui:placeholder": "Enter your email"
        },
        "password": {
            "ui:widget": "password",
            "ui:placeholder": "Enter your password"
        },
    };

    const schema: RJSFSchema = {
        title: 'AirLogbooks SignUp',
        type: 'object',
        properties: {
            email: {
                type: 'string',
                "format": "email"
            },
            password: {
                type: 'string',
                "minLength": 8
            },
        },
        "required": ["email", "password"]
    };

    // click sumbit will signup and login
    return (
        <div className="signup-form">
            <Card className='signup-card w-[500px]'>
                <img src={Logo} alt="Air Loggbooks" className="w-[100px] mt-[17px] m-auto" />
                {error && <Alert severity="error">{error}</Alert>}
                <Form schema={schema} validator={validator} uiSchema={uiSchema}
                    onSubmit={(data) => {
                        showOverlay()
                        createUserWithEmailAndPassword(auth, data.formData.email, data.formData.password)
                        .then((userCredential) => {
                            signInWithEmailAndPassword(auth, data.formData.email, data.formData.password)
                                .then((userCredential) => {
                                    localStorage.setItem(userKey, userCredential.user.email!);
                                    localStorage.setItem(userIdKey, userCredential.user.uid)
                                    hideOverlay()
                                })
                                .catch((error) => {
                                    hideOverlay()
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    alert(error.message)
                                });
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            hideOverlay()
                            setError("User already exist or something went wrong")
                        })}} >
                    <div className='mt-3'>
                        <CustomButtonFill text='SignUp' onClick={() => { }} />
                    </div>
                </Form>

                <h1 className='mt-3'>Already have a account?</h1>
                <Link href="/signin" className='mt-10'>SignIn</Link>
            </Card>
        </div>
    );
};
