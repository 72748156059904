import { Navigate, useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './auth/useAuth';
import { SignIn } from './auth/signin';
import { Signup } from './auth/signup';
import ProtectedRoute from './protectedRoute';
import { UserData } from './userData/userData';
import { Dashboard } from './Dashboard/dashboard';
import './App.css';

export const App = () => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('user', user)
    if (user && (location.pathname === '/signin' || location.pathname === '/signup')) {
      navigate('/');
    }
  }, [user, location, navigate]);

  const routes = useRoutes([
    {
      path: '/signin',
      element: <SignIn />,
    },
    {
      path: '/signup',
      element: <Signup />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/profile',
      element: (
        <ProtectedRoute>
          <UserData />
        </ProtectedRoute>
      )
    },
    {
      path: '/ad-compliances/:aircraftId',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/mx-log/:aircraftId',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/pilots',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: '/pilot-logbook/:email',
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      )
    }
    // Other routes
  ]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return routes;
};

export default App;
