export const addDate = (dateString: string, days: number, months: number, years: number): string => {
    // Parse the input date string
    const date = new Date(dateString);

    // Add the number of days to the date
    date.setDate(date.getDate() + days);

    // Add the number of months to the date
    date.setMonth(date.getMonth() + months);

    // Add the number of years to the date
    date.setFullYear(date.getFullYear() + years);

    // Convert the date back to a string in the format YYYY-MM-DD
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const getDateDiff = (d1: string, d2: string) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    // Get the time difference in milliseconds
    const diffInMs = date2.getTime() - date1.getTime();

    // Convert milliseconds to days
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays;
}

export const formatDateToYYYYMMDDToday = (): string => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}