import { useEffect, useState } from "react";
import { CustomButton } from "../../../components/button";
import { CustomButtonFill } from "../../../components/buttonfill";
import { cn } from "../../../userData/userData";
import Aircraft from '../../../assets/images/aircraft.svg'
import { FirebaseImage } from "../../../components/firebaseImage";
import { useNavigate } from "react-router-dom";
import { AddMxLog } from "../mxLogbook";
import { AddAircraft } from "../../addAircraft";
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Modal, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { AircraftCollection } from "../../../keys";
import { addDate, formatDateToYYYYMMDDToday, getDateDiff } from "../../../utils/dateutils";
import { SquawkList } from "../../squawk/squawkList";
import EditAircraftIcon from '../../../assets/icons/EditAircraftIcon.svg';
import AddMXIcon from '../../../assets/icons/AddMXIcon.svg';
import ViewMXLogbookIcon from '../../../assets/icons/ViewMXLogbookIcon.svg';
import { AdList } from "../../ad/adList";
import AddAdIcon from '../../../assets/icons/AddAdIcon.svg';
import { useLoadingOverlay } from "../../../loading";
import { getSeverityBgColor, InfoSeverityIcon, Severity } from "../../../components/severity";

export const AirCraftItem = ({ aircraft, getAll }: { aircraft: any, getAll: () => void }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [squawkSeverity, setSquawkSeverity] = useState(Severity.Done)
    const [adSeverity, setAdSeverity] = useState(Severity.Done)

    useEffect(() => {
    }, [])

    const getColor = () => {
        var severity = Severity.Done
        var engine1OilChangeSeverity = calculateOilChangeseverity();
        var engine2OilChangeSeverity = calculateOilChangeseverity_engine2();
        const nextLastAnnualDate = addDate(aircraft.lastAnnualDate, 0, 0, 1);
        var dateDiff = getDateDiff(nextLastAnnualDate, formatDateToYYYYMMDDToday())

        if (dateDiff > -30 && dateDiff < 0) {
            severity = Severity.Medium
        } else if (dateDiff < -30) {
            severity = Severity.Done
        } else {
            severity = Severity.High
        }


        if (severity == Severity.High || engine1OilChangeSeverity == Severity.High || engine2OilChangeSeverity == Severity.High || squawkSeverity == Severity.High || adSeverity == Severity.High) {
            return getSeverityBgColor(Severity.High)
        } else if (severity == Severity.Medium || engine1OilChangeSeverity == Severity.Medium || engine2OilChangeSeverity == Severity.Medium || squawkSeverity == Severity.Medium || adSeverity == Severity.Medium) {
            return getSeverityBgColor(Severity.Medium)
        } else {
            return getSeverityBgColor(Severity.Done)
        }
    }

    const calculateOilChangeseverity = () => {
        var severity = Severity.Done
        if (aircraft?.lastOilChangeDate && aircraft?.engineOilChangeDays && aircraft?.engineOilChangeDays != 0) {
            var dateDiff = getDateDiff(aircraft?.lastOilChangeDate, formatDateToYYYYMMDDToday())
            if (aircraft?.engineOilChangeDays < dateDiff) severity = Severity.High
            else if (dateDiff > aircraft?.engineOilChangeDays - 15) severity = Severity.Medium
        }

        var severityHours = Severity.Done
        if (aircraft?.tachTimeAtLastOilChange && aircraft?.engineOilChangeHours && aircraft?.engineOilChangeHours != 0) {
            var tachDiff = aircraft?.currentTach - aircraft?.tachTimeAtLastOilChange
            if (aircraft?.engineOilChangeHours < tachDiff) severityHours = Severity.High
            else if (tachDiff > aircraft?.engineOilChangeHours - 5) severityHours = Severity.Medium
        }
        return (severity == Severity.High || severityHours == Severity.High) ? Severity.High :
            (severity == Severity.Medium || severityHours == Severity.Medium) ? Severity.Medium : Severity.Done
    }

    const calculateOilChangeseverity_engine2 = () => {
        var severity = Severity.Done
        if (aircraft?.lastOilChangeDate_engine2 && aircraft?.engineOilChangeDays_engine2 && aircraft?.engineOilChangeDays_engine2 != 0) {
            var dateDiff = getDateDiff(aircraft?.lastOilChangeDate_engine2, formatDateToYYYYMMDDToday())
            if (aircraft?.engineOilChangeDays_engine2 < dateDiff) severity = Severity.High
            else if (dateDiff > aircraft?.engineOilChangeDays_engine2 - 15) severity = Severity.Medium
        }

        var severityHours = Severity.Done
        if (aircraft?.tachTimeAtLastOilChange_engine2 && aircraft?.engineOilChangeHours_engine2 && aircraft?.engineOilChangeHours_engine2 != 0) {
            var tachDiff = aircraft?.currentTach_engine2 - aircraft?.tachTimeAtLastOilChange_engine2
            if (aircraft?.engineOilChangeHours_engine2 < tachDiff) severityHours = Severity.High
            else if (tachDiff > aircraft?.engineOilChangeHours_engine2 - 5) severityHours = Severity.Medium
        }
        return (severity == Severity.High || severityHours == Severity.High) ? Severity.High :
            (severity == Severity.Medium || severityHours == Severity.Medium) ? Severity.Medium : Severity.Done
    }

    return <>
        <div className="">
            <div className={cn("p-2 border border-black rounded-[15px] cursor-pointer", getColor())} onClick={() => setIsOpen(!isOpen)}>

                <div className="flex">
                    {
                        aircraft.image ? <FirebaseImage img={aircraft.image} classes="w-[80px] h-[51px] ml-0" /> :
                            <img src={Aircraft} alt="Air Loggbooks" className="w-[80px] h-[51px] ml-0 " style={{ fill: 'blue' }} />
                    }

                    <span className={cn("font-[500] text-[20px]  mt-3 ml-6 text-[#ffffff]")}>{aircraft.aircraftId}</span>
                    {/* <div className="bg-white ml-auto px-3 rounded-[15px] flex gap-3">
                        <Tooltip title="Squawk Error" arrow>
                            <div className="relative mt-3 ml-auto mr-3">
                                <img
                                    src={AddSquawkIcon}
                                    alt="Background"
                                    className="w-[35px]"
                                />

                                <div className="absolute inset-0 flex items-center justify-center mt-[-40px] ml-[30px]">
                                    <InfoSeverityIcon severity={Severity.High} />
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title="Squawk Error" arrow>
                            <div className="relative mt-3 ml-auto mr-3">
                                <img
                                    src={AddSquawkIcon}
                                    alt="Background"
                                    className="w-[35px]"
                                />

                                <div className="absolute inset-0 flex items-center justify-center mt-[-40px] ml-[30px]">
                                    <InfoSeverityIcon severity={Severity.Medium} />
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title="Squawk Error" arrow>
                            <div className="relative mt-3 ml-auto mr-3">
                                <img
                                    src={AddAdIcon}
                                    alt="Background"
                                    className="w-[35px]"
                                />

                                <div className="absolute inset-0 flex items-center justify-center mt-[-40px] ml-[30px]">
                                    <InfoSeverityIcon severity={Severity.Low} />
                                </div>
                            </div>
                        </Tooltip>
                        <Tooltip title="Squawk Error" arrow>
                            <div className="relative mt-3 ml-auto mr-3">
                                <img
                                    src={AddAdIcon}
                                    alt="Background"
                                    className="w-[35px]"
                                />

                                <div className="absolute inset-0 flex items-center justify-center mt-[-40px] ml-[30px]">
                                    <InfoSeverityIcon severity={Severity.Done} />
                                </div>
                            </div>
                        </Tooltip>
                    </div> */}

                </div>
            </div>

            {/* Animated div */}
            <div
                className={`mt-4 transition-all duration-500 transform ${isOpen ? "max-h-[650px] opacity-100 scale-100" : "max-h-0 opacity-0 scale-95"
                    } overflow-hidden`}
            >
                <div className="p-4 bg-gray-100 rounded mb-3">
                    <table className="col-span-2 w-[100%]">
                        <tr>
                            <td><span className="text-[#4995CF]">{"Make and Model"}: </span> {aircraft?.make}, {aircraft?.model}</td>
                            <td><span className="text-[#4995CF]">{"Airframe TT"}: </span> {aircraft?.totalTime.toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF]">{"Engine TSMOH"}: </span> {aircraft?.tsmoh.toFixed(1)}<sup>(E1)</sup> {aircraft?.tsmoh_engine2 ? `, ${aircraft?.tsmoh_engine2.toFixed(1)}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</td>
                            <td><span className="text-[#4995CF]">{"Tach"}: </span> {aircraft?.currentTach.toFixed(1)}<sup>(E1)</sup> {aircraft?.currentTach_engine2 ? `, ${aircraft?.currentTach_engine2.toFixed(1)}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF]">{"Hobbs"}: </span> {aircraft?.hobbs ? aircraft?.hobbs.toFixed(1) : '-'} {aircraft?.hobbs2 ? `, ${aircraft?.hobbs2.toFixed(1)}` : ''}</td>
                            <td><span className="text-[#4995CF]">{"Last Annual"}: </span> {aircraft?.lastAnnualDate}</td>
                        </tr>
                    </table>

                    <hr className="border-t border-gray-300 my-3 border-dashed" />
                    <span className="text-[14px] font-bold">Engine Oil Change</span>

                    <div className={cn("flex items-center border-2 p-[2px] rounded-md mb-1 mr-3")}>
                        <InfoSeverityIcon severity={calculateOilChangeseverity()} />
                        <p className="text-gray-700 text-[12px] ml-2"><b>Engine 1</b></p>
                        <p className="text-gray-700 text-[12px] flex-1 ml-3"><b>Last Oil changed: {aircraft?.lastOilChangeDate} | Tach: {aircraft?.tachTimeAtLastOilChange}</b></p>
                        <p className="text-gray-700 text-[12px] mx-2"><b>{aircraft?.engineOilChangeHours} hours or {aircraft?.engineOilChangeDays} Days</b></p>
                    </div>
                    {
                        aircraft?.engineMoake_engine2 &&
                        <div className={cn("flex items-center border-2 p-[2px] rounded-md mb-1 mr-3")}>
                            <InfoSeverityIcon severity={calculateOilChangeseverity_engine2()} />
                            <p className="text-gray-700 text-[12px] ml-2"><b>Engine 2</b></p>
                            <p className="text-gray-700 text-[12px] flex-1 ml-3"><b>Last Oil changed: {aircraft?.lastOilChangeDate_engine2} | Tach: {aircraft?.tachTimeAtLastOilChange_engine2}</b></p>
                            <p className="text-gray-700 text-[12px] mx-2"><b>{aircraft?.engineOilChangeHours_engine2} hours or {aircraft?.engineOilChangeDays_engine2} Days</b></p>
                        </div>
                    }

                    <hr className="border-t border-gray-300 my-3 border-dashed" />

                    <div className="max-h-[150px] pr-3 pt-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400">
                        <SquawkList
                            aircraftId={aircraft.id}
                            onCloseMx={() => {
                                getAll()
                            }} setSquawkSeverity={setSquawkSeverity} />
                    </div>
                    <hr className="border-t border-gray-300 my-3 border-dashed" />

                    <div className="max-h-[150px] pr-3 pt-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400">
                        <AdList
                            aircraftId={aircraft.id}
                            tt={aircraft.totalTime}
                            tsmoh={aircraft.tsmoh}
                            tachTime={aircraft.currentTach}
                            limit={3}
                            onCloseMx={() => {
                                getAll()
                            }} />
                    </div>

                    {openModal && <AddMxLog onClose={(val: boolean) => {
                        setOpenModal(val)
                        getAll()
                    }} aircraftId={aircraft.id} />}
                    {openDeleteModal && <DeleteAircraft onClose={() => {
                        setOpenDeleteModal(false)
                        getAll()
                    }} id={aircraft.id} />}

                    <div className="flex mt-4 gap-3">
                        <Tooltip title="View MX Logbook" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { navigate("/mx-log/" + aircraft.id) }}><img src={ViewMXLogbookIcon} /></div>
                        </Tooltip>
                        <Tooltip title="Add MX Entry" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { setOpenModal(true) }}><img src={AddMXIcon} /></div>
                        </Tooltip>
                        <Tooltip title="Edit Aircraft" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { setOpenEditModal(true) }}><img src={EditAircraftIcon} /></div>
                        </Tooltip>
                        <Tooltip title="AD Compliances" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { navigate("/ad-compliances/" + aircraft.id) }}><img src={AddAdIcon} /></div>
                        </Tooltip>
                        <DeleteIcon className="mt-2 text-blue-500 cursor-pointer" onClick={() => { setOpenDeleteModal(true) }} />
                    </div>

                </div>
            </div>
        </div>

        {openEditModal && <AddAircraft onClose={(val: boolean) => {
            setOpenEditModal(val)
            getAll();
        }} data={aircraft} isEditMode={true} />}
    </>
}

const DeleteAircraft = ({ id, onClose }: { id: string, onClose: () => void }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Delete Aircraft</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <h3>
                This can not be undone. You will lose all data! Are you sure?
            </h3>

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Yes' onClick={async () => {
                    showOverlay()
                    const db = getFirestore()
                    await deleteDoc(doc(db, AircraftCollection, id));
                    onClose()
                    hideOverlay()
                }} />
                <CustomButton text="No" onClick={() => onClose()} />
            </div>

        </Card>
    </Modal>
}
