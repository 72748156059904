import { Alert, Box, Card, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material"
import { addDoc, collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import CloseIcon from '@mui/icons-material/Close';
import { AircraftCollection, PilotLogbookCollection, userKey, userNameKey } from "../../keys"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useLoadingOverlay } from "../../loading"
import { CustomButtonFill } from "../../components/buttonfill";
import { aircraftCategory } from "../../dropdownItems";
import { formatDateToYYYYMMDDToday, getDateDiff } from "../../utils/dateutils";
import { SquawksModal } from "../squawk/squawksModal";
import { CustomButton } from "../../components/button";
import { toFixedNumber } from "../../utils/numberUtils";

export const PilotLogbooks = () => {
    const [logs, setLogs] = useState<any[]>([]);
    const [loaded, setLoaded] = useState(false)
    const { email } = useParams();

    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllLogs()
    }, [])

    const getAllLogs = async () => {
        showOverlay()
        const db = getFirestore()

        const data: any[] = [];

        const q = query(collection(db, PilotLogbookCollection), where("pilotId", "==", email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        setLogs(data);
        setLoaded(true)
        hideOverlay()
    }

    return (<>
        <div>
            {/* <span className="font-[700] text-[28px] text-[#222222]">Pilot Logbook</span> */}
            <Card className="mt-3 pt-3">
                <div className="font-[700] text-[22px] text-[#85144B] p-3">{email} pilot logbook</div>
                <table className="pt-5 w-[100%] border-2 border-[#CCCCCC]">
                    <tbody>
                        <tr className="bg-[#3A8CC9] p-3 w-[100%] h-[57px]">
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Date</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Tach Start</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Tach End</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Hobbs Start</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Hobbs End</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Aircraft Id</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Pilot</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Aircraft Make and Model</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Aircraft Year</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Departure Airport</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Arrival Airport</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Aircraft Categort</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Total Time</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Day Landing(s)</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Night Landing(s)</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">PIC</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">SIC</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Cross Country</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Actual Inst.</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Simulated Inst.</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Night</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Instructor</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Dual Received</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Solo</th>
                            <th className="font-[600] text-[10px] text-[#FFFFFF] border">Remarks, Procedures, Maneuvers</th>
                        </tr>
                        {
                            logs.map((log, index) => <tr className="h-[57px] text-center  border">
                                <td className="border text-[8px]">{log?.date}</td>
                                <td className="border text-[8px]">{log?.tachStart?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.tachEnd?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.hobbsStart?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.hobbsEnd?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.aircraftId}</td>
                                <td className="border text-[8px]">{log?.pilot}</td>
                                <td className="border text-[8px]">{log?.["make&Model"]}</td>
                                <td className="border text-[8px]">{log?.year}</td>
                                <td className="border text-[8px]">{log?.departureAirport}</td>
                                <td className="border text-[8px]">{log?.arrivalAirport}</td>
                                <td className="border text-[8px]">{log?.aircraftCategory}</td>
                                <td className="border text-[8px]">{log?.totalTime?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.dayLanding}</td>
                                <td className="border text-[8px]">{log?.nightLanding}</td>
                                <td className="border text-[8px]">{log?.pic?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.sic?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.crossCountry?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.actualInst?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.simulatedInst?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.night?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.instructor?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.dualReceived?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.solo?.toFixed(1)}</td>
                                <td className="border text-[8px]">{log?.remarks}</td>
                            </tr>)
                        }
                    </tbody>

                </table>
            </Card>
        </div>
    </>)
}

export const AddPilotLog = (prop: { onClose: (val: boolean) => void, openAddAircraft: () => void }) => {
    const [aircrafts, setAircrafts] = useState<any[]>([])
    const [formValues, setFormValues] = useState({
        "tachStart": null,
        "tachEnd": null,
        "hobbsStart": null,
        "hobbsEnd": null,
        "tach2Start": null,
        "tach2End": null,
        "hobbs2Start": null,
        "hobbs2End": null,
        "date": new Date().toISOString().split("T")[0],
        "aircraftId": null,
        "year": "",
        "make&Model": "",
        "departureAirport": null,
        "arrivalAirport": null,
        "aircraftCategory": "",
        "totalTime": 0,
        "dayLanding": null,
        "nightLanding": null,
        "pic": null,
        "sic": null,
        "crossCountry": null,
        "actualInst": null,
        "simulatedInst": null,
        "night": null,
        "instructor": null,
        "dualReceived": null,
        "solo": null,
        "remarks": null
    });
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [minTach, setMinTach] = useState(0);
    const [minTach2, setMinTach2] = useState(0);
    const [selectedAircraft, setSelectedAircraft] = useState<any>(null)
    const [showSquawks, setShowSquawks] = useState(false)

    const [formErrors, setFormErrors] = useState({
        "tachStart": false,
        "tachEnd": false,
        "hobbsStart": false,
        "hobbsEnd": false,
        "tach2Start": false,
        "tach2End": false,
        "hobbs2Start": false,
        "hobbs2End": false,
        "date": false,
        "aircraftId": false,
        "year": false,
        "make&Model": false,
        "departureAirport": false,
        "arrivalAirport": false,
        "aircraftCategory": false,
        "totalTime": false,
        "dayLanding": false,
        "nightLanding": false,
        "pic": false,
        "sic": false,
        "crossCountry": false,
        "actualInst": false,
        "simulatedInst": false,
        "night": false,
        "instructor": false,
        "dualReceived": false,
        "solo": false,
        "remarks": false
    });
    const [customErrors, setcustomErrors] = useState("");
    const TachtimeError = "Tach time should be higher than current Tach value. Please select a past date to add a historical data."

    useEffect(() => {
        getAllAircrafts()
    }, [])

    const getAllAircrafts = async () => {

        const data: any[] = [];

        const querySnapshot = await getDocs(collection(db, AircraftCollection));
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        console.log(data)

        setAircrafts(data);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (typeof (value) != "object") {
            if (value.trim() === '') {
                setFormErrors({
                    ...formErrors,
                    [name]: true,
                });
            } else {
                setFormErrors({
                    ...formErrors,
                    [name]: false,
                });
            }
        }

        var custom = {}

        if (name == "aircraftId") {
            if (value === "ADD NEW") {
                prop.onClose(false);
                prop.openAddAircraft()
            } else {
                const aircraftData = aircrafts.find(a => a.aircraftId === value);

                if (aircraftData) {
                    setSelectedAircraft(aircraftData)
                    if (getDateDiff(formatDateToYYYYMMDDToday(), formValues.date) >= 0) {
                        setMinTach(aircraftData.currentTach)
                        setMinTach2(aircraftData.currentTach_engine2)
                        if (
                            formValues.tachStart != null && formValues.tachStart < aircraftData.currentTach
                        ) {
                            setcustomErrors(TachtimeError)
                        } else if (
                            formValues.tach2Start != null && formValues.tach2Start < aircraftData.currentTach_engine2
                        ) {
                            setcustomErrors(TachtimeError)
                        } else {
                            setcustomErrors("")
                        }
                    } else {
                        setMinTach(0)
                        setMinTach2(0)
                        setcustomErrors("")
                    }

                    custom = {
                        "make&Model": `${aircraftData.make}, ${aircraftData.model}`,
                        year: aircraftData.year,
                        aircraftCategory: aircraftData.aircraftCategory
                    }
                }
            }
        }

        setFormValues({
            ...formValues,
            ...custom,
            [name]: value,
        });

        if (name == "date") {
            if (getDateDiff(formatDateToYYYYMMDDToday(), value) >= 0 && selectedAircraft != null) {
                setMinTach(selectedAircraft.currentTach)
                setMinTach2(selectedAircraft.currentTach_engine2)
                if (
                    formValues.tachStart != null && formValues.tachStart < selectedAircraft.currentTach
                ) {
                    setcustomErrors(TachtimeError)
                } else if (
                    formValues.tach2Start != null && formValues.tach2Start < selectedAircraft.currentTach_engine2
                ) {
                    setcustomErrors(TachtimeError)
                } else {
                    setcustomErrors("")
                }
            }
            else {
                setMinTach(0)
                setMinTach2(0)
                setcustomErrors("")
            }
        }
    };

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleChangeNumberCustom = (e: any) => {

        const { name, value } = e.target;

        let totaltimeDiff = 0;
        var tachStart = name == 'tachStart' ? value : formValues.tachStart ?? 0
        var tachEnd = name == 'tachEnd' ? value : formValues.tachEnd ?? 0
        var hobbsStart = name == 'hobbsStart' ? value : formValues.hobbsStart ?? 0
        var hobbsEnd = name == 'hobbsEnd' ? value : formValues.hobbsEnd ?? 0

        if (tachEnd > 0 && tachStart > 0 && tachEnd > tachStart) {
            totaltimeDiff = tachEnd - tachStart
        } else if (hobbsEnd > 0 && hobbsStart > 0 && hobbsEnd > hobbsStart) {
            totaltimeDiff = hobbsEnd - hobbsStart
        }

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
            totalTime: toFixedNumber(totaltimeDiff)
        });

        if (name == 'tachStart') {
            if (
                value < minTach
            ) {
                setcustomErrors(TachtimeError)
            } else {
                setcustomErrors("")
            }
        }

    };

    const handleChangeNumberCustom2 = (e: any) => {

        const { name, value } = e.target;

        let totaltimeDiff = 0;
        var tach2Start = name == 'tach2Start' ? value : formValues.tach2Start ?? 0
        var tach2End = name == 'tach2End' ? value : formValues.tach2End ?? 0
        var hobbs2Start = name == 'hobbs2Start' ? value : formValues.hobbs2Start ?? 0
        var hobbs2End = name == 'hobbs2End' ? value : formValues.hobbs2End ?? 0

        if (tach2End > 0 && tach2Start > 0 && tach2End > tach2Start) {
            totaltimeDiff = tach2End - tach2Start
        } else if (hobbs2End > 0 && hobbs2Start > 0 && hobbs2End > hobbs2Start) {
            totaltimeDiff = hobbs2End - hobbs2Start
        }

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
            totalTime: formValues.totalTime > toFixedNumber(totaltimeDiff) ? formValues.totalTime : toFixedNumber(totaltimeDiff)
        });

        if (name == 'tach2Start') {
            if (
                value < minTach2
            ) {
                setcustomErrors(TachtimeError)
            } else {
                setcustomErrors("")
            }
        }

    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        showOverlay()
        const flight = { 
            ...formValues, 
            pilotId: localStorage.getItem(userKey), 
            pilot: localStorage.getItem(userNameKey) 
        }

        await addDoc(collection(db, PilotLogbookCollection), flight);

        // update aircraft here
        if (getDateDiff(formatDateToYYYYMMDDToday(), formValues?.date) >= 0) {
            let aircraftData = aircrafts.find(a => a.aircraftId === formValues?.aircraftId)
            if (aircraftData) {
                //engine 1
                var hobbs = aircraftData.hobbs ?? 0
                var tachDiff = (flight.tachEnd ?? 0) - (flight.tachStart ?? 0)
                var hobbsdiff = (flight.hobbsEnd ?? 0) - (flight.hobbsStart ?? 0)

                //engine 2
                var hobbs2 = aircraftData.hobbs2 ?? 0
                var tachDiff2 = (flight.tach2End ?? 0) - (flight.tach2Start ?? 0)
                var hobbsdiff2 = (flight.hobbs2End ?? 0) - (flight.hobbs2Start ?? 0)
                aircraftData = {
                    ...aircraftData,
                    currentTach: toFixedNumber(aircraftData.currentTach + tachDiff),
                    totalTime: toFixedNumber(aircraftData.totalTime + flight.totalTime),
                    hobbs: toFixedNumber(hobbs + hobbsdiff),
                    hobbs2: toFixedNumber(hobbs2 + hobbsdiff2),
                    tsmoh: toFixedNumber(aircraftData.tsmoh + (tachDiff > 0 ? tachDiff : hobbsdiff)),
                    tsmoh_engine2: toFixedNumber(aircraftData.tsmoh_engine2 + (tachDiff2 > 0 ? tachDiff2 : hobbsdiff2))
                }
                await setDoc(doc(db, AircraftCollection, aircraftData.aircraftId), aircraftData)
            }
        }

        hideOverlay()

        prop.onClose(false);
    };

    return (<Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[90vw]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <div className="flex gap-5 mb-5">
                    <h1 className="text-3xl">Add New Flight</h1> {selectedAircraft != null && <CustomButton text='SQUAWKS' onClick={() => { setShowSquawks(true) }} />}
                </div>

                <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
            </div>

            {customErrors != "" && <Alert severity="error"><span className="text-[red] mb-4">{customErrors}</span></Alert>}

            <div className='h-[80vh] overflow-scroll px-3 pt-2'>
                <Box
                    component="form"
                    sx={{ flexGrow: 1 }}
                    onSubmit={handleSubmit}
                    className="mt-4"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Aircraft ID</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    name="aircraftId"
                                    value={formValues.aircraftId}
                                    label="Select Option"
                                    onChange={handleChange}
                                    required
                                    className="h-[50px]"
                                    sx={{ fontSize: '0.8rem' }}
                                >
                                    <MenuItem value={"ADD NEW"}>ADD NEW</MenuItem>
                                    {aircrafts.map(l => <MenuItem value={l.aircraftId}>{l.aircraftId}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={9}>
                            <h4 className="mt-3"><b>Aircraft Tach Time:</b> {selectedAircraft?.currentTach ? `${selectedAircraft?.currentTach}(Engine 1)` : "-"} {selectedAircraft?.currentTach_engine2 ? `, ${selectedAircraft?.currentTach_engine2}(Engine 2)` : ""}</h4>
                        </Grid>

                        <Grid item xs={12}>
                            <p className='font-bold mb-[-9px]'>
                                Engine 1
                            </p>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Tach Start"
                                name="tachStart"
                                type="number"
                                value={formValues.tachStart}
                                onChange={handleChangeNumberCustom}
                                error={formErrors.tachStart}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                                inputProps={{ min: minTach }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Tach End"
                                name="tachEnd"
                                type="number"
                                value={formValues.tachEnd}
                                onChange={handleChangeNumberCustom}
                                error={formErrors.tachEnd}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Hobbs Start"
                                name="hobbsStart"
                                type="number"
                                value={formValues.hobbsStart}
                                onChange={handleChangeNumberCustom}
                                error={formErrors.hobbsStart}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Hobbs End"
                                name="hobbsEnd"
                                type="number"
                                value={formValues.hobbsEnd}
                                onChange={handleChangeNumberCustom}
                                error={formErrors.hobbsEnd}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                            />
                        </Grid>

                        {selectedAircraft?.engineMoake_engine2 && <Grid item xs={12}>
                            <p className='font-bold mb-[-10px]'>
                                Engine 2
                            </p>
                        </Grid>
                        }
                        {selectedAircraft?.engineMoake_engine2 && <Grid item xs={3}>
                            <TextField
                                label="Tach Start"
                                name="tach2Start"
                                type="number"
                                value={formValues.tach2Start}
                                onChange={handleChangeNumberCustom2}
                                error={formErrors.tach2Start}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                                inputProps={{ min: minTach }}
                            />
                        </Grid>}

                        {selectedAircraft?.engineMoake_engine2 && <Grid item xs={3}>
                            <TextField
                                label="Tach End"
                                name="tach2End"
                                type="number"
                                value={formValues.tach2End}
                                onChange={handleChangeNumberCustom2}
                                error={formErrors.tach2End}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                            />
                        </Grid>}

                        {selectedAircraft?.engineMoake_engine2 && <Grid item xs={3}>
                            <TextField
                                label="Hobbs Start"
                                name="hobbs2Start"
                                type="number"
                                value={formValues.hobbs2Start}
                                onChange={handleChangeNumberCustom2}
                                error={formErrors.hobbs2Start}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                            />
                        </Grid>}

                        {selectedAircraft?.engineMoake_engine2 && <Grid item xs={3}>
                            <TextField
                                label="Hobbs End"
                                name="hobbs2End"
                                type="number"
                                value={formValues.hobbs2End}
                                onChange={handleChangeNumberCustom2}
                                error={formErrors.hobbs2End}
                                className="w-full border rounded-md bg-[#C4DFF0]"
                            />
                        </Grid>}
                        
                        <Grid item xs={3}>
                            <TextField
                                type="date"
                                name="date"
                                value={formValues.date}
                                onChange={handleChange}
                                label="Date"
                                error={formErrors.date}
                                helperText={formErrors.date ? 'Date is required' : ''}
                                className="w-full "
                                InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Aircraft Year"
                                name="year"
                                type="number"
                                value={formValues.year}
                                onChange={handleChangeNumber}
                                error={formErrors.year}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Aircraft make And Model"
                                name="make&Model"
                                value={formValues["make&Model"]}
                                onChange={handleChange}
                                error={formErrors["make&Model"]}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Departure Airport"
                                name="departureAirport"
                                value={formValues.departureAirport}
                                onChange={handleChange}
                                error={formErrors.departureAirport}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Arrival Airport"
                                name="arrivalAirport"
                                value={formValues.arrivalAirport}
                                onChange={handleChange}
                                error={formErrors.arrivalAirport}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Aircraft Category</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    name="aircraftCategory"
                                    value={formValues.aircraftCategory}
                                    label="Select Option"
                                    onChange={handleChange}
                                    required
                                    className="h-[50px]"
                                    sx={{ fontSize: '0.8rem' }}
                                >
                                    {aircraftCategory.map(item => <MenuItem value={item.value}>{item.value}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </Grid>



                        <Grid item xs={3}>
                            <TextField
                                label="Day Landing(s)"
                                name="dayLanding"
                                type="number"
                                value={formValues.dayLanding}
                                onChange={handleChangeNumber}
                                error={formErrors.dayLanding}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="Night Landing(s)"
                                name="nightLanding"
                                type="number"
                                value={formValues.nightLanding}
                                onChange={handleChangeNumber}
                                error={formErrors.nightLanding}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                label="Total Time"
                                name="totalTime"
                                type="number"
                                value={formValues.totalTime}
                                onChange={handleChangeNumber}
                                error={formErrors.totalTime}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="PIC"
                                name="pic"
                                type="number"
                                value={formValues.pic}
                                onChange={handleChangeNumber}
                                error={formErrors.pic}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="SIC"
                                name="sic"
                                type="number"
                                value={formValues.sic}
                                onChange={handleChangeNumber}
                                error={formErrors.sic}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                label="XC (Cross Country)"
                                name="crossCountry"
                                type="number"
                                value={formValues.crossCountry}
                                onChange={handleChangeNumber}
                                error={formErrors.crossCountry}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Actual Inst."
                                name="actualInst"
                                type="number"
                                value={formValues.actualInst}
                                onChange={handleChangeNumber}
                                error={formErrors.actualInst}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Simulated Inst."
                                name="simulatedInst"
                                type="number"
                                value={formValues.simulatedInst}
                                onChange={handleChangeNumber}
                                error={formErrors.simulatedInst}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Night"
                                name="night"
                                type="number"
                                value={formValues.night}
                                onChange={handleChangeNumber}
                                error={formErrors.night}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Instructor/Dual Given"
                                name="instructor"
                                type="number"
                                value={formValues.instructor}
                                onChange={handleChangeNumber}
                                error={formErrors.instructor}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Dual Received"
                                name="dualReceived"
                                type="number"
                                value={formValues.dualReceived}
                                onChange={handleChangeNumber}
                                error={formErrors.dualReceived}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Solo"
                                name="solo"
                                type="number"
                                value={formValues.solo}
                                onChange={handleChangeNumber}
                                error={formErrors.solo}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Remarks"
                                name="remarks"
                                value={formValues.remarks}
                                onChange={handleChange}
                                error={formErrors.remarks}
                                className="w-full custom-textarea"
                                rows={5}
                                multiline
                            />
                        </Grid>

                    </Grid>

                    <div className="mt-5 flex gap-3">
                        <CustomButtonFill text='Submit' onClick={() => { }} />

                    </div>



                </Box>
            </div>
            {showSquawks && <SquawksModal aircraftId={selectedAircraft?.id} onClose={() => { setShowSquawks(false) }} />}
        </Card>


    </Modal>)

}