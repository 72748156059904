import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { AuthProvider } from './auth/useAuth';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { LoadingOverlayProvider } from './loading';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontSize: '0.75rem', // Adjust the font size for smaller text
          },
          '& .MuiInputLabel-root': {
            fontSize: '0.75rem', // Adjust the font size for the label
          },
          '& .MuiFormHelperText-root': {
            fontSize: '0.75rem', // Adjust the font size for helper text
          },
          '& .MuiInputBase-root': {
            padding: 0, // Remove padding from the input base
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // height: '40px'
        },
      },
    }
  }
});

root.render(
  <React.StrictMode>
    <Router>
      <LoadingOverlayProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App />

          </ThemeProvider>
        </AuthProvider>
      </LoadingOverlayProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
