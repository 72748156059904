import { useEffect, useState } from "react";
import { cn } from "../../../userData/userData";
import { CustomButtonFill } from "../../../components/buttonfill";
import { CustomButton } from "../../../components/button";
import PilotImg from '../../../assets/images/pilotimg.svg'
import { Card, Modal, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FirebaseImage } from "../../../components/firebaseImage";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { PilotLogbookBase, PilotLogbookCollection, UsersCollection } from "../../../keys";
import { addDate, formatDateToYYYYMMDDToday, getDateDiff } from "../../../utils/dateutils";
import { UserDataFormDialog } from "../../../userData/userDataformDialog";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewPilotLogbookIcon from '../../../assets/icons/ViewPilotLogbookIcon.svg';

export const PilotItem = ({ pilot, onChange, rerenderKey }: {
    pilot: any,
    rerenderKey: string,
    onChange: () => void
}) => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [logs, setLogs] = useState<any[]>([]);
    const [pilotBaseData, setPilotBaseData] = useState<any>();
    const [totals, setTotals] = useState({
        last7Days: { pic: 0, sic: 0, night: 0, solo: 0, dayLanding: 0, nightLanding: 0 },
        last30Days: { pic: 0, sic: 0, night: 0, solo: 0, dayLanding: 0, nightLanding: 0 },
        last90Days: { pic: 0, sic: 0, night: 0, solo: 0, dayLanding: 0, nightLanding: 0 },
        last6Months: { pic: 0, sic: 0, night: 0, solo: 0, dayLanding: 0, nightLanding: 0 },
        last12Months: { pic: 0, sic: 0, night: 0, solo: 0, dayLanding: 0, nightLanding: 0 },
    });
    const [faaDueDate, setFaaDueDate] = useState<string>();
    const [mergedcolor, setMergedcolor] = useState<string>();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        getUserBase();
        getAllLogs();
    }, [])

    useEffect(() => {
        getAllLogs()
    }, [rerenderKey])

    useEffect(() => {
        setTotals({
            last7Days: calculateTotals(logs, 7),
            last30Days: calculateTotals(logs, 30),
            last90Days: calculateTotals(logs, 90),
            last6Months: calculateTotals(logs, 180),
            last12Months: calculateTotals(logs, 365),
        });


        // console.log("##########",pilot.email ,totals, logs)
    }, [logs]);

    useEffect(() => {
        getMergedColor();

    }, [logs, pilotBaseData])

    const getUserBase = async () => {
        const db = getFirestore()

        const docRef = doc(db, PilotLogbookBase, pilot.email);
        const docSnap = await getDoc(docRef);

        // console.log("Document data:", docSnap);

        if (docSnap.exists()) {
            setPilotBaseData(docSnap.data())
        } else {
        }
    }

    const getAllLogs = async () => {
        const db = getFirestore()

        const data: any[] = [];

        const q = query(collection(db, PilotLogbookCollection), where("pilotId", "==", pilot.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        setLogs(data);
    }

    const getTotalTime = () => {
        var totaltime = 0;
        if (pilotBaseData) {
            totaltime = pilotBaseData.totalTime
        }

        if (logs) {
            logs.forEach(l => totaltime += l.totalTime)
        }

        return totaltime.toFixed(1)
    }

    const calculateTotals = (logs: any[], days: number) => {
        const now = new Date();
        const targetDate = new Date(now);
        targetDate.setDate(now.getDate() - days);

        const filteredLogs = logs.filter(log => new Date(log.date) >= targetDate);

        const totals = filteredLogs.reduce((acc, log) => {
            if (log?.pic)
                acc.pic += log?.pic;
            if (log?.sic)
                acc.sic += log?.sic;
            if (log?.night)
                acc.night += log?.night;
            if (log?.solo)
                acc.solo += log?.solo;
            if (log?.dayLanding)
                acc.dayLanding += log?.dayLanding;
            if (log?.nightLanding)
                acc.nightLanding += log?.nightLanding;
            return acc;
        }, { pic: 0, sic: 0, night: 0, solo: 0, dayLanding: 0, nightLanding: 0 });

        return totals;
    };

    const getMergedColor = () => {
        const bfrcolor = getBfrColor();
        const faaMedColor = getFaaMedicalCertificateColors();

        if (bfrcolor == 'bg-[red]' || faaMedColor == 'bg-[red]') {
            setMergedcolor('bg-[red]')
        } else if (bfrcolor == 'bg-amber-400' || faaMedColor == 'bg-amber-400') {
            setMergedcolor('bg-amber-400')
        } else {
            setMergedcolor('bg-[green]')
        }
    }

    const bfrDate = addDate(pilot?.pilotInfo.biennialFlightReviewDate, 0, 0, 2);

    const getBfrColor = () => {
        var dateDiff = getDateDiff(bfrDate, formatDateToYYYYMMDDToday())

        if (dateDiff > -90 && dateDiff < 0) {
            return "bg-amber-400"
        } else if (dateDiff < -90) {
            return "bg-[green]"
        }

        return "bg-[red]"
    }

    const getFaaMedicalCertificateColors = () => {
        var faaMedicalClass = pilot?.pilotInfo.fAAMedicalClass
        var dob = pilot?.pilotInfo.dob

        var age = getDateDiff(dob, formatDateToYYYYMMDDToday())

        var color = "bg-[green]"
        if (age / 365 < 40) {
            if (faaMedicalClass === "First" || faaMedicalClass === "Second") {
                color = getFaaMedColor(getFaaDatediff(0, 1))

            }
            if (faaMedicalClass === "Third" || faaMedicalClass === "Basic") {
                color = getFaaMedColor(getFaaDatediff(0, 5))
            }
        }
        else {
            if (faaMedicalClass === "First") {
                color = getFaaMedColor(getFaaDatediff(6, 0))
            }
            if (faaMedicalClass === "Second") {
                color = getFaaMedColor(getFaaDatediff(0, 1))
            }
            if (faaMedicalClass === "Third" || faaMedicalClass === "Basic") {
                color = getFaaMedColor(getFaaDatediff(0, 2))
            }
        }
        return color;
    }

    const getFaaDatediff = (mn: number, yr: number) => {
        const faaMedicalDate = addDate(pilot?.pilotInfo.fAAMedicalDate, 0, mn, yr);
        setFaaDueDate(faaMedicalDate)
        return getDateDiff(faaMedicalDate, formatDateToYYYYMMDDToday())
    }

    const getFaaMedColor = (medicalDateDiff: number) => {
        if (medicalDateDiff > -30 && medicalDateDiff < 0) {
            return "bg-amber-400"
        } else if (medicalDateDiff < -30) {
            return "bg-[green]"
        }

        return "bg-[red]"
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <>
        <div className="">
            <div className={cn("p-2 border border-black rounded-[15px] cursor-pointer", mergedcolor)} onClick={() => setIsOpen(!isOpen)}>

                <div className="flex justify-between">
                    <div className="flex w-[400px]">
                        {pilot.image ?
                            <FirebaseImage img={pilot.image} classes="w-[51px] h-[51px] ml-0" /> :
                            <img src={PilotImg} alt="Air Loggbooks" className="w-[51px] h-[51px] ml-0 " style={{ fill: 'blue' }} />
                        }
                        <div className="flex flex-col align-center">
                            <span className={cn("font-[500] text-[20px] text-[#ffffff] ml-6 mt-2")}>{pilot.firstName} {pilot.lastName}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`mt-4 transition-all duration-500 transform ${isOpen ? "max-h-96 opacity-100 scale-100" : "max-h-0 opacity-0 scale-95"
                    } overflow-hidden`}
            >
                <div className="p-3 bg-gray-100 rounded mb-3">
                    <span className="text-[#4995CF]">{"Total Flight Time"}: </span> <span className="text-[14px]">{getTotalTime()}</span>
                    <table className="col-span-2">
                        <tr>
                            <th className="w-[10%]"></th>
                            <th className="w-[10%]"><span className="text-[#4995CF] text-[11px]">PIC</span></th>
                            <th className="w-[10%]"><span className="text-[#4995CF] text-[11px]">SIC</span></th>
                            <th className="w-[10%]"><span className="text-[#4995CF] text-[11px]">Night</span></th>
                            <th className="w-[10%]"><span className="text-[#4995CF] text-[11px]">Solo</span></th>
                            <th className="w-[10%]"><span className="text-[#4995CF] text-[11px]">Day Landings</span></th>
                            <th className="w-[10%]"><span className="text-[#4995CF] text-[11px]">Night Landings</span></th>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF] text-[11px]">Last 7 Days</span></td>
                            <td className="text-center text-[13px]">{totals.last7Days.pic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last7Days.sic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last7Days.night.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last7Days.solo.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last7Days.dayLanding}</td>
                            <td className="text-center text-[13px]">{totals.last7Days.nightLanding}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF] text-[11px]">Last 30 Days</span></td>
                            <td className="text-center text-[13px]">{totals.last30Days.pic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last30Days.sic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last30Days.night.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last30Days.solo.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last30Days.dayLanding}</td>
                            <td className="text-center text-[13px]">{totals.last30Days.nightLanding}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF] text-[11px]">Last 90 Days</span></td>
                            <td className="text-center text-[13px]">{totals.last90Days.pic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last90Days.sic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last90Days.night.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last90Days.solo.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last90Days.dayLanding}</td>
                            <td className="text-center text-[13px]">{totals.last90Days.nightLanding}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF] text-[11px]">Last 6 Months</span></td>
                            <td className="text-center text-[13px]">{totals.last6Months.pic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last6Months.sic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last6Months.night.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last6Months.solo.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last6Months.dayLanding}</td>
                            <td className="text-center text-[13px]">{totals.last6Months.nightLanding}</td>
                        </tr>
                        <tr>
                            <td><span className="text-[#4995CF] text-[11px]">Last 12 Months</span></td>
                            <td className="text-center text-[13px]">{totals.last12Months.pic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last12Months.sic.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last12Months.night.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last12Months.solo.toFixed(1)}</td>
                            <td className="text-center text-[13px]">{totals.last12Months.dayLanding}</td>
                            <td className="text-center text-[13px]">{totals.last12Months.nightLanding}</td>
                        </tr>
                    </table>
                    <div className="mt-2"><span className="text-[#4995CF]">{"FAA Medical Due Date"}: </span> <span className="text-[13px]">
                        {
                            faaDueDate
                        }</span></div>
                    <div><span className="text-[#4995CF]">{"BFR Date"}: </span> <span className="text-[13px]"> {bfrDate}</span></div>

                    <div className="flex flex-row gap-3 pt-3">
                        <Tooltip title="View Pilot Logbook" arrow>
                            <div className="w-[40px] cursor-pointer" onClick={() => { navigate("/pilot-logbook/" + pilot.email) }}><img src={ViewPilotLogbookIcon} /></div>
                        </Tooltip>
                        {/* <CustomButtonFill text="View Pilot Logbook" onClick={() => { navigate("/pilot-logbook/" + pilot.email) }} /> */}
                        {/* <CustomButton text="Add New Flight" onClick={() => setOpenModal(true)} /> */}
                        {/* <CustomButton text="Edit Pilot" onClick={() => setOpenEditModal(true)} /> */}
                        <DeleteIcon className="mt-1 text-blue-500 cursor-pointer" onClick={() => { setOpenDeleteModal(true) }} />
                    </div>
                </div>
            </div>

            {/* {openModal && <AddPilotLog onClose={(val: boolean) => {
                setOpenModal(val)
                onOtherChange()
                getAllLogs()
                }} openAddAircraft={() => openAddAircraft(true)} />} */}
            {openEditModal && <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            ><div className=""><Card className='' sx={style}><UserDataFormDialog userData={pilot} onClose={(val) => {
                setOpenEditModal(val)
                onChange()
            }} /></Card></div></Modal>}
            {openDeleteModal && <DeletePilot onClose={() => {
                setOpenDeleteModal(false)
                onChange()
            }} id={pilot.email} />}
        </div>
    </>
}


const DeletePilot = ({ id, onClose }: { id: string, onClose: () => void }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Delete Pilot</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <h3>
                This can not be undone. You will lose all data! Are you sure?
            </h3>
            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Yes' onClick={async () => {
                    const db = getFirestore()
                    await deleteDoc(doc(db, UsersCollection, id));
                    onClose()
                }} />
                <CustomButton text="No" onClick={() => onClose()} />
            </div>

        </Card>
    </Modal>
}

