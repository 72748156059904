export const CustomButtonFill = ({ text, onClick }: { text: string, onClick: () => void }) => {
    return <button
        className="border border-[#4995CF] border-[1px] rounded-full h-[36px] w-[180px] align-center bg-[#3A8CC9] text-[#ffffff]"
        onClick={() => onClick()}>{text}</button>
}

export const CustomButtonSmallFill = ({ text, onClick }: { text: string, onClick: () => void }) => {
    return <button
        className="border border-[#4995CF] border-[1px] rounded-full h-[26px] w-[60px] align-center bg-[#3A8CC9] text-[#ffffff]"
        onClick={() => onClick()}>
        <span className="">{text}</span>
    </button>
}