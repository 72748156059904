export const medClass = [
    {
        value: "First",
        config: {}
    },
    {
        value: "Second",
        config: {}
    },
    {
        value: "Third",
        config: {}
    },
    {
        value: "Basic",
        config: {}
    }
];

export const licenseType = [
    {
        value: "ATP",
        config: {}
    },
    {
        value: "Commercial",
        config: {}
    },
    {
        value: "Private",
        config: {}
    },
    {
        value: "Student",
        config: {}
    },
    {
        value: "Sport",
        config: {}
    },
    {
        value: "Recreational",
        config: {}
    }
];

export const logBookCategory = [
    {
        value: "Airframe",
        config: {}
    },
    {
        value: "Propeller",
        config: {}
    },
    {
        value: "Engine",
        config: {}
    },
];

export const aircraftCategory = [
    {
        value: "Airplane SEL",
        config: {}
    },
    {
        value: "Airplane MEL",
        config: {}
    },
    {
        value: "Helicopter",
        config: {}
    },
    {
        value: "Single Engine Sea",
        config: {}
    }
];

export const typeOfPilotingTime = [
    {
        value: "Dual Received",
        config: {}
    },
    {
        value: "PIC",
        config: {}
    },
    {
        value: "Instructor",
        config: {}
    }
];

export const consitionsOfFlight = [
    {
        value: "Day",
        config: {}
    },
    {
        value: "Night",
        config: {}
    },
    {
        value: "X-CTRY",
        config: {}
    },
    {
        value: "Actual IMC",
        config: {}
    },
    {
        value: "Simulated IMC",
        config: {}
    }
];

export const squawkSeverity = [
    {
        value: "Low",
        config: {}
    },
    {
        value: "Medium",
        config: {}
    },
    {
        value: "High",
        config: {}
    }
];
