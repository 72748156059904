// LoadingOverlay.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface LoadingOverlayContextProps {
    showOverlay: () => void;
    hideOverlay: () => void;
}

const LoadingOverlayContext = createContext<LoadingOverlayContextProps | undefined>(undefined);

export const useLoadingOverlay = () => {
    const context = useContext(LoadingOverlayContext);
    if (!context) {
        throw new Error('useLoadingOverlay must be used within a LoadingOverlayProvider');
    }
    return context;
};

interface LoadingOverlayProviderProps {
    children: ReactNode;
}

export const LoadingOverlayProvider: React.FC<LoadingOverlayProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const showOverlay = () => setLoading(true);
    const hideOverlay = () => setLoading(false);

    return (
        <LoadingOverlayContext.Provider value={{ showOverlay, hideOverlay }}>
            {children}
            {loading && (
                <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) =>
                      Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                  }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </LoadingOverlayContext.Provider>
    );
};
