export const Collapsible = ({ isOpen, children }: any) => {
    return (
        <div
            className={`transition-all duration-500 ease-in-out overflow-hidden ${isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
                }`}
        >
            <div className="p-4rounded-md">
                {children}
            </div>
        </div>
    );
};